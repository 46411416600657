import { useState } from "react";
/**DatePicker */
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect/index.js";
import "flatpickr/dist/plugins/monthSelect/style.css";
/**Components */
import HeaderPage from "../../../components/page/header";
/**Moment */
import moment from "moment";

export const BookSale = () => {
  const state = {
    page: {
      title: "Ventas 14.1",
      icon: "ri-book-2-line",
      model: "electronic-books-sales",
      header: {
        menu: ["Libros Electrónicos", "Ventas 14.1"],
      },
    },
  };
  const [selectedDate, setSelectedDate] = useState<any>(
    moment().format("YYYY-MM"),
  );

  const handleDateChange = (selectedDates: Date[]) => {
    const fecha = moment(selectedDates[0]).format("YYYY-MM");
    setSelectedDate(fecha);
  };

  const onClickAddResource = () => {};

  const generate = () => {
    window.open(
      `${process.env.REACT_APP_LINK_BACKEND}/electronic-books/sales?fecha=${selectedDate}&version=14.1`,
      "_blank",
    );
  };

  return (
    <div className="main-body p-0">
      <div className="inner-wrapper">
        <div className="inner-main">
          <HeaderPage page={state.page} onModalResource={onClickAddResource} />
          <div className="inner-main-body">
            <div className="card card-featured card-featured-primary mb-3 mt-3">
              <div className="card-body">
                <div className="mb-3 row">
                  <div className="col-md-3">
                    <label htmlFor="name">Periodo</label>
                    <div role="group" className="btn-group">
                      <Flatpickr
                        name="fecha"
                        id="fecha"
                        value={selectedDate}
                        onChange={handleDateChange}
                        className="form-control form-control-sm"
                        options={{
                          dateFormat: "Y-m",
                          locale: "es",
                          plugins: [
                            new (monthSelectPlugin as any)({
                              shorthand: true,
                              dateFormat: "Y-m",
                              altFormat: "Y-m",
                            }),
                          ],
                        }}
                      />
                      <button
                        onClick={generate}
                        className="btn btn-sm btn-primary"
                      >
                        Generar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookSale;
