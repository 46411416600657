import { useState } from "react";
/**Utilities */
import { ErrorBackendApi } from "../../../utilities";
/**Select */
import Select from "react-select";
/**DatePicker */
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/l10n/es";
/**Moment */
import moment from "moment";

interface Props {
  title: string;
  name: string;
  formik: any;
  clientName?: string;
  handleSelectChange: any;
  getRequirements: any;
  clientSearch?: any;
  itsCliente: boolean;
  handleSelectChangeInHeaderSale: (value: string, fieldName: string) => void;
  handleSelectCliente: (clienteId: string, personalMail: string) => void;
  errors: any;
}

interface OptionSelect {
  label: string;
  value: string;
  personal_mail: string;
}

const HeaderSale = (props: Props) => {
  //Flatpickr
  const minDate = moment().subtract(2, "days").format("YYYY-MM-DD");
  const recurrentDate = moment().format("YYYY-MM-DD");
  const [selectedDate, setSelectedDate] = useState<Date | string>(props.formik.values.fechaEmision);
  //ReactSelect - Clientes
  const [optionsClients, setOptionsClients] = useState<OptionSelect[]>([]);
  const [isLoadingClients, setIsLoadingClients] = useState(false);

  const handleDateChange = (selectedDates: Date[]) => {
    setSelectedDate(selectedDates[0]);
    const fecha = moment(selectedDates[0]).format("YYYY-MM-DD");
    props.handleSelectChangeInHeaderSale("fechaEmision", fecha);
  };

  const handleClientSearchChange = (value: string) => {
    let type = "";
    if (props.formik.values.type_document_id === "2") {
      type = "JURIDICA";
    } else {
      type = "";
    }
    if (value.length >= 3) {
      props
        .clientSearch(value, type)
        .then((response: any) => {
          setIsLoadingClients(true);
          setOptionsClients(response.data);
        })
        .finally(() => {
          setIsLoadingClients(false);
        });
    }
  };

  const handleClientSelectChange = (selectedOption: OptionSelect) => {
    props.formik.setFieldValue("selectedClient", selectedOption);
    props.handleSelectCliente(selectedOption.value, selectedOption.personal_mail ?? "");
  };

  const hasError = (propertyName: string) => {
    return props.errors && props.errors[propertyName];
  };

  return (
    <div className="row">
      <div className="col-md-12">
        {props.title === "credit" && (
          <h4 className="text-center">Generar Nota de Crédito para el documento {props.name}</h4>
        )}
        {props.title === "debit" && (
          <h4 className="text-center">Generar Nota de Débito para el documento {props.name}</h4>
        )}
        {props.title === "" && <h4 className="text-center">Generar nueva venta</h4>}
      </div>
      {/* Tipo de nota de credito */}
      {props.title === "credit" && (
        <div className="form-group col-md-3">
          <label htmlFor="type_note_credit_id" className="form-label">
            Tipo Nota de Crédito
          </label>
          <select
            className={
              "form-control form-control-sm form-select" +
              (hasError("sale.type_note_credit_id") &&
              props.formik.values.type_note_credit_id === ""
                ? " is-invalid"
                : "")
            }
            onChange={props.handleSelectChange}
            value={props.formik.values.type_note_credit_id}
            name="type_note_credit_id"
            id="type_note_credit_id"
          >
            <option disabled value="">
              Seleccionar
            </option>
            {props.getRequirements?.type_note_credits.map((item: any) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
          <ErrorBackendApi errorsBackend={props.errors} name="sale.type_note_credit_id" />
        </div>
      )}

      {/* Tipo de nota de debito */}
      {props.title === "debit" && (
        <div className="form-group col-md-3">
          <label htmlFor="type_note_debit_id" className="form-label">
            Tipo Nota de Débito
          </label>
          <select
            className={
              "form-control form-control-sm form-select" +
              (hasError("sale.type_note_debit_id") && props.formik.values.type_note_debit_id === ""
                ? " is-invalid"
                : "")
            }
            onChange={props.handleSelectChange}
            value={props.formik.values.type_note_debit_id}
            name="type_note_debit_id"
            id="type_note_debit_id"
          >
            <option disabled value="">
              Seleccionar
            </option>
            {props.getRequirements?.type_note_debits.map((item: any) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
          <ErrorBackendApi errorsBackend={props.errors} name="sale.type_note_debit_id" />
        </div>
      )}

      {/* Tipo de operación*/}
      {props.title === "" && (
        <div className="form-group col-md-2">
          <label htmlFor="operation_type_id" className="form-label">
            Tipo operación
          </label>
          <select
            className={
              "form-control form-control-sm form-select" +
              (hasError("sale.operation_type_id") && props.formik.values.operation_type_id === ""
                ? " is-invalid"
                : "")
            }
            onChange={props.handleSelectChange}
            value={props.formik.values.operation_type_id}
            name="operation_type_id"
            id="operation_type_id"
          >
            <option disabled value="">
              Seleccionar
            </option>
            {props.getRequirements?.operation_types.map((item: any) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
          <ErrorBackendApi errorsBackend={props.errors} name="sale.operation_type_id" />
        </div>
      )}
      {/* Tipo documento*/}
      {props.title === "" && (
        <div className="form-group col-md-2">
          <label htmlFor="type_document_id" className="form-label">
            Tipo documento
          </label>
          <select
            className={
              "form-control form-control-sm form-select" +
              (hasError("sale.type_document_id") && props.formik.values.type_document_id === ""
                ? " is-invalid"
                : "")
            }
            onChange={props.handleSelectChange}
            value={props.formik.values.type_document_id}
            name="type_document_id"
            id="type_document_id"
          >
            <option disabled value="">
              Seleccionar
            </option>
            {props.getRequirements?.type_documents.map((item: any) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
          <ErrorBackendApi errorsBackend={props.errors} name="sale.type_document_id" />
        </div>
      )}
      {/* Cliente*/}
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="client_id" className="form-label">
            Cliente
          </label>
          {props.itsCliente ? (
            <Select
              isDisabled={props.formik.values.type_document_id === ""}
              isLoading={isLoadingClients}
              placeholder={"Buscar cliente..."}
              options={optionsClients}
              onInputChange={handleClientSearchChange}
              noOptionsMessage={() => "No se encontraron resultados"}
              value={props.formik.values.selectedClient}
              onChange={(selectedOption) => handleClientSelectChange(selectedOption)}
            />
          ) : (
            <input
              disabled
              readOnly
              value={props.clientName}
              type="text"
              className="form-control form-control-sm"
            />
          )}
        </div>
      </div>
      {/* Fecha emisión*/}
      <div className="col-md-2">
        <div className="form-group">
          <label htmlFor="date_issue" className="form-label">
            Fecha emisión
          </label>
          <Flatpickr
            value={selectedDate}
            onChange={handleDateChange}
            className="form-control form-control-sm"
            options={{
              dateFormat: "Y-m-d",
              locale: "es",
              minDate: minDate,
              maxDate: recurrentDate,
            }}
          />
        </div>
      </div>
      {/* Moneda*/}
      <div className="form-group col-md-2">
        <label htmlFor="currency_id" className="form-label">
          Moneda
        </label>
        <select
          className="form-control form-control-sm form-select"
          name="currency_id"
          id="currency_id"
          onChange={props.handleSelectChange}
          value={props.formik.values.currency_id}
        >
          <option value="" disabled>
            Seleccionar
          </option>
          <option value="PEN">SOLES</option>
          <option value="USD">DOLARES</option>
        </select>
      </div>
    </div>
  );
};

export default HeaderSale;
