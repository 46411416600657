import { useEffect, useState } from "react";
/**Components */
import HeaderPage from "../../../components/page/header";
import BodyPage from "../../../components/page/body";
import ModalComponent from "../../../components/modal";
/** Componentes propios */
import Filter from "./filter";
import FormTariff from "./form";
/**Hooks */
import { useTariffs, usePurchases, useSales } from "../../../hooks";
/**Models */
import { Tariff } from "../../../models";
/**Utilities */
import { SweetAlert } from "../../../utilities";

/** Modal Resource */
interface DataModalState {
  type: string;
  buttonSubmit: string | null;
  row: any | null;
  title: string | null;
  requirements: any[];
  onCloseModalForm: () => void;
  errosBackend: any;
}

export const TariffIndex = () => {
  const { getTariffs, storeTariff, updateTariff, getTariffClients } =
    useTariffs();
  const { productSearch } = usePurchases();
  const { clientSearch } = useSales();
  const [dataModalResourceState, setDataModalResourceState] =
    useState<DataModalState>({
      type: "",
      buttonSubmit: null,
      row: null,
      title: null,
      requirements: [],
      onCloseModalForm: () => {},
      errosBackend: {},
    });
  const [errors, setErrors] = useState({});
  const [filterState, setFilterState] = useState({
    page: 1,
    text: "",
    model_type: "",
    model_id: "",
    selectedClient: {
      label: "Todos los clientes",
      value: "",
    },
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stateModal, setStateModal] = useState<boolean>(false);
  const [tariffClients, setTariffClients] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [stateView, setStateView] = useState<boolean>(false);

  const state = {
    page: {
      title: "Tarifarios",
      icon: "ri-archive-2-line",
      model: "products-tariffs",
      header: {
        menu: ["Productos", "Tarifarios"],
      },
      buttons: {
        create: true,
        edit: true,
        destroy: false,
        import: false,
        export: false,
      },
    },
    dataTable: {
      body: {
        widthAccion: "160px",
        cols: [
          {
            name: "model_name",
            alias: "Cliente",
          },
          {
            name: "product_name",
            alias: "Producto",
          },
          {
            name: "brand_name",
            alias: "Marca",
          },
          {
            name: "presentation_name",
            alias: "Presentación",
            width: "20%",
          },
          {
            name: "sale_price",
            alias: "Precio de venta",
            width: "10%",
          },
          {
            name: "state",
            alias: "Estado",
            width: "7%",
            play: {
              type: "states",
              values: {
                0: "bg-danger-subtle text-danger",
                1: "bg-success-subtle text-success",
              },
              names: {
                0: "Inactivo",
                1: "Activo",
              },
            },
          },
        ],
        buttons: [
          {
            name: "updateState",
            tooltip: "Desactivar",
            text: "",
            css: "me-3 text-info",
            icon: "fa-regular fa-circle",
            play: {
              type: "states",
              name: "state",
              values: {
                "0": false,
                "1": true,
              },
            },
          },
          {
            name: "updateState",
            tooltip: "Activar",
            text: "",
            css: "me-3 text-info",
            icon: "fa-solid fa-circle",
            play: {
              type: "states",
              name: "state",
              values: {
                "0": true,
                "1": false,
              },
            },
          },
        ],
      },
    },
  };

  /** METODOS DEL FILTER */
  const onChangePage = (page: number, type: string) => {
    switch (type) {
      case "prev":
        setFilterState({ ...filterState, page: filterState.page - 1 });
        getTariffs(
          filterState.page - 1,
          filterState.text,
          filterState.model_type,
          filterState.model_id,
          true,
        );
        break;

      case "current":
        setFilterState({ ...filterState, page: page });
        getTariffs(
          page,
          filterState.text,
          filterState.model_type,
          filterState.model_id,
          true,
        );
        break;
      case "next":
        setFilterState({ ...filterState, page: filterState.page + 1 });
        getTariffs(
          filterState.page + 1,
          filterState.text,
          filterState.model_type,
          filterState.model_id,
          true,
        );
        break;
      default:
        break;
    }
  };
  const onClearFilters = () => {
    setFilterState({
      ...filterState,
      page: 1,
      text: "",
      model_type: "",
      model_id: "",
      selectedClient: {
        label: "Todos los clientes",
        value: "",
      },
    });
    getTariffs(1, "", "", "", true);
  };

  const handleFilterChange = (newFilters: any, loader: boolean) => {
    setFilterState(newFilters);
    getTariffs(
      1,
      newFilters.text,
      newFilters.model_type,
      newFilters.model_id,
      loader,
    );
  };

  /** METODOS DE MODAL */
  const onCloseModalForm = () => {
    setStateModal(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setErrors({});
  };

  const onClickAddResource = (type: string) => {
    setDataModalResourceState({
      type: type,
      buttonSubmit: "Registrar",
      row: null,
      title: "Nuevo tarifario",
      requirements: [],
      onCloseModalForm: onCloseModalForm,
      errosBackend: {},
    });
    setStateModal(true);
    setIsModalOpen(true);
  };

  const onClickEditResource = (row: any) => {
    setDataModalResourceState({
      type: "edit",
      buttonSubmit: "Editar",
      row: row,
      title: "Editar tarifario",
      requirements: [],
      onCloseModalForm: onCloseModalForm,
      errosBackend: {},
    });
    setStateModal(true);
    setIsModalOpen(true);
  };

  /** METODOS DEL FORM RESOURCE */
  const onSubmitForm = async (tariff: Tariff, type: string) => {
    switch (type) {
      case "store":
        storeTariff(tariff)
          .then(() => {
            onCloseModalForm();
            SweetAlert.success(
              "Mensaje",
              "Tarifario registrado correctamente.",
            );
          })
          .catch((error) => {
            setErrors(error.response.data.errors);
          });
        break;
      case "edit":
        updateTariff(tariff).then(() => {
          onCloseModalForm();
          SweetAlert.success("Mensaje", "Tarifario actualizado correctamente.");
        });
        break;
      default:
        break;
    }
  };

  const onClickButtonPersonalizado = (row: any, name: any) => {
    switch (name) {
      case "updateState":
        const state = row.state === 1 ? 0 : 1;
        const data = { ...row, state: state };
        updateTariff(data).then(() => {
          SweetAlert.success(
            "Mensaje",
            row.state === 1
              ? "Tarifario desactivado correctamente."
              : "Tarifario activado correctamente.",
          );
        });
        break;
      default:
        break;
    }
  };
  const onClickDeleteResource = (row: any) => {};

  const onVerTarifario = (client: any) => () => {
    setFilterState({
      ...filterState,
      page: 1,
      text: "",
      model_type: "CLIENTE",
      model_id: client.client_id,
      selectedClient: {
        label: `${client.client_document_number} - ${client.client_name}`,
        value: client.client_id,
      },
    });
    getTariffs(1, "", "CLIENTE", client.client_id, true);
    setStateView(true);
  };

  useEffect(() => {
    const getData = () => {
      getTariffClients().then((response) => {
        setTariffClients(response.clients);
        setLoader(true);
      });
    };
    if (!loader) {
      getData();
    }
  }, [getTariffClients, loader]);

  return (
    <div className="main-body p-0">
      <div className="inner-wrapper">
        <div className="inner-main">
          <HeaderPage page={state.page} onModalResource={onClickAddResource} />
          <div className="inner-main-body">
            <div className="card card-featured card-featured-primary mb-3 mt-3">
              <div className="card-body">
                {stateView && (
                  <Filter
                    onClearFilters={onClearFilters}
                    filterState={filterState}
                    onFilterChange={handleFilterChange}
                    clientSearch={clientSearch}
                  />
                )}
                {stateView ? (
                  <BodyPage
                    onChangeDelete={onClickDeleteResource}
                    onClickButtonPersonalizado={onClickButtonPersonalizado}
                    onChangeEdit={onClickEditResource}
                    onChangePage={onChangePage}
                    page={state.page}
                    table={state.dataTable}
                  />
                ) : (
                  <div className="table-responsive">
                    <table
                      role="table"
                      className="table table-bordered table-hover"
                    >
                      <thead className="table-light table-nowrap">
                        <tr role="row">
                          <th role="button">Clientes</th>
                          <th>Acción</th>
                        </tr>
                      </thead>
                      <tbody className="font-size-11">
                        {tariffClients?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              {item.client_document_number} - {item.client_name}
                            </td>
                            <td>
                              <button
                                onClick={onVerTarifario(item)}
                                className="btn btn-primary btn-xs"
                              >
                                Ver tarifario
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Resource */}
      {isModalOpen && (
        <ModalComponent
          stateModal={stateModal}
          typeModal={"static"}
          size={"modal-lg"}
          title={dataModalResourceState.title}
          content={
            <FormTariff
              errors={errors}
              productSearch={productSearch}
              clientSearch={clientSearch}
              data={dataModalResourceState}
              onSubmitForm={onSubmitForm}
            />
          }
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default TariffIndex;
