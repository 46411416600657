import { QuoteResponse } from '../../../models/responses';

interface Props {
  response: QuoteResponse;
  onCloseModalForm: () => void;
}

const FinishEditQuote = (props: Props) => {
  const openPdf = () => {
    window.open(`${process.env.REACT_APP_LINK_BACKEND}/cotizacion/${props.response.uuid}.pdf`, '_blank');
  };

  return (
    <div className="form form-horizontal form-scrollable">
      <div className="modal-body">
        <div className="form-row row justify-content-md-center">
          <div className="col-md-12">
            <h5 className="text-center">
              <i className="fas fa-check-circle text-success"></i> {props.response.name}
            </h5>
          </div>
          <div className="col-md-3 text-center mt-3">
            <button onClick={openPdf} type="button" className="btn btn-sm btn-primary">
              <i className="fas fa-print"></i>
            </button>
            <p>
              <b>Imprimir</b>
            </p>
          </div>
          <div className="col-md-3 text-center mt-3">
            <button onClick={openPdf} type="button" className="btn btn-sm btn-primary">
              <i className="fas fa-print"></i>
            </button>
            <p>
              <b>Decargar</b>
            </p>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" onClick={() => props.onCloseModalForm()} className="btn btn-sm btn-secondary">
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default FinishEditQuote;
