import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
/**Models */
import { DataTable, Product } from '../models';
/**Services */
import * as productsService from '../services/products.service';
/**Hooks */
import { setLoading } from '../redux/states/loading.slice';
import { dataTable_setAlls, dataTable_clearAll, dataTable_addResource, dataTable_updateResource, dataTable_deleteResource } from '../redux/states/dataTable.slice';

export function useProducts() {
  const dispatch = useDispatch();
  const [products, setProducts] = useState<DataTable | null>(null);
  const [requirements, setRequirements] = useState<any | null>(null);

  //GET
  const fetchProducts = useCallback(
    (page: number, category: string, brand: string, presentation: string, text: string, loading: boolean) => {
      dispatch(setLoading(loading));
      productsService
        .getProducts(page, category, brand, presentation, text)
        .then((response) => {
          dispatch(dataTable_setAlls(response));
          setProducts(response); // Actualiza el estado local si es necesario
        })
        .catch((error) => error)
        .finally(() => {
          dispatch(setLoading(false));
        });
    },
    [dispatch],
  );

  const getRequirements = useCallback(() => {
    productsService
      .getRequirements()
      .then((response) => {
        setRequirements(response);
      })
      .catch((error) => error)
      .finally(() => {});
  }, []);

  useEffect(() => {
    const resetData = () => {
      dispatch(dataTable_clearAll());
      setProducts(null); // Borra el estado local si es necesario
    };
    fetchProducts(1, '', '', '', '', true); // Llamada inicial al cargar el componente

    return () => {
      resetData(); // Esto se ejecutará cuando el componente se desmonte
    };
  }, [fetchProducts, dispatch]);

  //STORE
  const storeProduct = async (product: Product) => {
    dispatch(setLoading(true));
    try {
      const response = await productsService.storeProduct(product);
      dispatch(dataTable_addResource(response.product));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  //UPDATE
  const updateProduct = async (product: Product) => {
    dispatch(setLoading(true));
    try {
      const response = await productsService.updateProduct(product);
      dispatch(dataTable_updateResource(response.product));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  //DELETE
  const deleteProduct = async (id: string) => {
    dispatch(setLoading(true));
    try {
      const response = await productsService.deleteProduct(id);
      dispatch(dataTable_deleteResource({ id }));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getRequirements();
  }, [getRequirements]);

  return { products, fetchProducts, storeProduct, updateProduct, getRequirements: requirements, deleteProduct };
}
