/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { GuidesRequest } from '../../../../models/requests';

interface FormProps {
  onCloseModalForm: () => void;
  onAddResourceItem: (guides: GuidesRequest[]) => void;
  guides: GuidesRequest[];
}

const GuiasRemision = (props: FormProps) => {
  const [guides, setGuides] = useState<GuidesRequest[]>(props.guides);

  const onAddResourceItem = () => {
    const updatedItems = [...guides];
    updatedItems.push({ tipoDocumento: '09', numeroDocumento: '' });
    setGuides(updatedItems);
    props.onAddResourceItem(updatedItems);
  };

  const onChange = (index: number, field: string, value: string) => {
    const updatedItems = [...guides];
    updatedItems[index][field] = value;
    setGuides(updatedItems);
    props.onAddResourceItem(updatedItems);
  };

  const onClickDelete = (index: number) => {
    const updatedItems = [...guides];
    updatedItems.splice(index, 1);
    setGuides(updatedItems);
    props.onAddResourceItem(updatedItems);
  }

  return (
    <div className="form form-horizontal form-scrollable">
      <div className="modal-body">
        <div className="form-row row border-sales">
          <div className="title-items">
            Guías de Remisión{' '}
            <a onClick={() => onAddResourceItem()} className="add-item-sales">
              <i className="ri-add-line"></i>
            </a>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-3">
                <p className="mb-0">Serie - Correlativo</p>
              </div>
              <div className="col-md-5">
                <p className="mb-0">Tipo de Guía</p>
              </div>
              <div className="col-md-4">
                <p className="mb-0">Acción</p>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            {guides.map((item: any, index: number) => (
              <div key={index} className="row mt-2">
                <div className="col-md-3">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    value={item.numeroDocumento}
                    onChange={(e) => onChange(index, 'numeroDocumento', e.target.value)}
                  />
                </div>
                <div className="col-md-5">
                  <select
                    className="form-control form-control-sm form-select"
                    name="tipoDocumento"
                    value={item.tipoDocumento}
                    onChange={(e) => onChange(index, 'tipoDocumento', e.target.value)}
                  >
                    <option value="09">GUÍA DE REMISIÓN REMITENTE</option>
                    <option value="31">GUÍA DE REMISIÓN TRANSPORTISTA</option>
                  </select>
                </div>
                <div className="col-md-4">
                <a onClick={() => onClickDelete(index)} className="text-danger" role="button">
              <i className="ri-delete-bin-line font-size-18"></i>
            </a>
                  </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" onClick={() => props.onCloseModalForm()} className="btn btn-sm btn-secondary">
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default GuiasRemision;
