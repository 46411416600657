import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
/**Models */
import { DataTable } from '../models';
import { ClientRequest } from '../models/requests';
/**Services */
import * as clientsService from '../services/clients.service';
/**Hooks */
import { setLoading } from '../redux/states/loading.slice';
import { dataTable_setAlls, dataTable_clearAll, dataTable_addResource, dataTable_updateResource } from '../redux/states/dataTable.slice';

export function useClients() {
  const dispatch = useDispatch();
  const [clients, setClients] = useState<DataTable | null>(null);

  //GET
  const fetchClients = useCallback(
    (page: number, text: string, type: string, loading: boolean) => {
      dispatch(setLoading(loading));
      clientsService
        .getClients(page, text, type)
        .then((response) => {
          dispatch(dataTable_setAlls(response));
          setClients(response);
        })
        .catch((error) => error)
        .finally(() => {
          dispatch(setLoading(false));
        });
    },
    [dispatch],
  );

  useEffect(() => {
    const resetData = () => {
      dispatch(dataTable_clearAll());
      setClients(null);
    };
    fetchClients(1, '', '', true); // Llamada inicial al cargar el componente

    return () => {
      resetData(); // Esto se ejecutará cuando el componente se desmonte
    };
  }, [fetchClients, dispatch]);

  //STORE
  const storeClient = async (client: ClientRequest) => {
    dispatch(setLoading(true));
    try {
      const response = await clientsService.storeClient(client);
      dispatch(dataTable_addResource(response.client));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  //UPDATE
  const updateClient = async (client: ClientRequest) => {
    dispatch(setLoading(true));
    try {
      const response = await clientsService.updateClient(client);
      dispatch(dataTable_updateResource(response.client));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const personSearch = async (document: string) => {
    dispatch(setLoading(true));
    try {
      const response = await clientsService.personSearch(document);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }


  return { clients, fetchClients, storeClient, updateClient, personSearch };
}
