import { ChangeEvent, useState } from 'react';
/**Models */
import { ClientRequest } from '../../models/requests';
/**Validations */
import { useFormik } from 'formik';
import * as Yup from 'yup';
/**Utilities */
import { ErrorValidate, ErrorBackend } from '../../utilities';

interface FormProps {
  data: any;
  onSubmitForm: (client: ClientRequest, type: string) => void;
  onSearchPerson: any;
  errors: any;
}

export const FormClient = (props: FormProps) => {
  const [formData, setFormData] = useState<ClientRequest>(
    props.data.row || {
      person: {
        client_document_type_id: '',
        document_number: '',
        names: '',
        father_last_name: '',
        mother_last_name: '',
        cellphone: '',
        address: '',
        address_despatch: '',
        ubigeo: '',
        personal_mail: '',
      },
    },
  );

  const validateDocumentNumber = (value: string, client_document_type_id: any) => {
    let isValid = true;
    if ((client_document_type_id === '2' && value.length === 8) || (client_document_type_id === '4' && value.length === 11)) {
      // La validación pasó
      isValid = true;
    } else {
      // La validación falló
      isValid = false;
    }

    formik.setFieldTouched('person.document_number', true, false); // Marca el campo como tocado
    formik.setFieldError('person.document_number', isValid ? undefined : 'Número de documento inválido.');
    return isValid;
  };

  const validationSchema = props.data.type === 'store'
  ? Yup.object().shape({
    person: Yup.object().shape({
      document_number: Yup.string()
        .required("El campo 'número de documento' es obligatorio.")
        .test('documentNumberValidation', 'Número de documento inválido', (value) =>
          validateDocumentNumber(value, formData.person.client_document_type_id),
        ),
      names: Yup.string().required("El campo 'nombre' es obligatorio."),
    }),
  })
  : Yup.object();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    formik.setFieldValue(`person.${name}`, value);
    setFormData({
      ...formData,
      person: {
        ...formData.person,
        [name]: value,
      },
    });
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    formik.setFieldValue(`person.${name}`, value);
    setFormData({
      ...formData,
      person: {
        ...formData.person,
        [event.target.name]: event.target.value,
      },
    });
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: () => {
      props.onSubmitForm(formData, props.data.type);
    },
  });

  const onKeyUpSearchPerson = () => {
    if (
      (formik.values.person.client_document_type_id.toString() === '2' && formik.values.person.document_number.length === 8) ||
      (formik.values.person.client_document_type_id.toString() === '4' && formik.values.person.document_number.length === 11)
    ) {
      props.onSearchPerson(formik.values.person.document_number).then((response: any) => {
        if (response) {
          setFormData({
            ...formData,
            person: {
              ...formData.person,
              names: response.names,
              father_last_name: response.father_last_name,
              mother_last_name: response.mother_last_name,
              cellphone: response.cellphone,
              address: response.address,
              address_despatch: response.address_despatch,
              ubigeo: response.ubigeo,
              personal_mail: response.personal_mail,
            },
          });
          formik.setFieldValue('person.names', response.names);
          formik.setFieldValue('person.father_last_name', response.father_last_name);
          formik.setFieldValue('person.mother_last_name', response.mother_last_name);
          formik.setFieldValue('person.cellphone', response.cellphone);
          formik.setFieldValue('person.address', response.address);
          formik.setFieldValue('person.address_despatch', response.address_despatch);
          formik.setFieldValue('person.ubigeo', response.ubigeo);
          formik.setFieldValue('person.personal_mail', response.personal_mail);
        }
      });
    }
  };

  return (
    <form className="form form-horizontal form-scrollable" onSubmit={formik.handleSubmit}>
      <div className="modal-body">
        <div className="form-row row">
          {/****  SOLO STORE*/}
          {props.data.type === 'store' && (<>
          {/*TIPO DE DOCUMENTO */}
          <div className="form-group col-md-6">
            <label htmlFor="client_document_type_id" className="form-label">
              Tipo de documento <span className="text-danger">*</span>
            </label>
            <select
              id="client_document_type_id"
              name="client_document_type_id"
              value={formik.values.person.client_document_type_id}
              onChange={handleSelectChange}
              className={
                'form-control form-control-sm form-select' +
                (formik.errors.person?.client_document_type_id && formik.touched.person?.client_document_type_id ? ' is-invalid' : '')
              }
            >
              <option value="">Seleccionar</option>
              <option value="2">DNI</option>
              <option value="4">RUC</option>
            </select>
            <ErrorValidate state={formik.errors.person?.client_document_type_id} />
          </div>
          {/*NÚMERO DE DOCUMENTO */}
          <div className="form-group col-md-6">
            <label htmlFor="document_number" className="form-label">
              N° documento <span className="text-danger">*</span>
            </label>
            <input
              autoComplete='off'
              id="document_number"
              name="document_number"
              value={formik.values.person.document_number}
              onChange={handleInputChange}
              onKeyUp={onKeyUpSearchPerson}
              type="number"
              className={
                'form-control form-control-sm' +
                (formik.errors.person?.document_number && formik.touched.person?.document_number ? ' is-invalid' : '')
              }
            />
            <ErrorValidate state={formik.errors.person?.document_number} />
          </div>
          {/*NOMBRES [NATURAL]*/}
          <div className={`form-group ${formik.values.person.client_document_type_id.toString() === '2' ? 'col-md-4' : 'col-md-12'}`}>
            {formik.values.person.client_document_type_id.toString() === '4' ? (
              <label htmlFor="names" className="form-label">
                Razón Social <span className="text-danger">*</span>
              </label>
            ) : (
              <label htmlFor="names" className="form-label">
                Nombres <span className="text-danger">*</span>
              </label>
            )}
            <input
              autoComplete='off'
              id="names"
              name="names"
              value={formik.values.person.names}
              onChange={handleInputChange}
              type="text"
              className={
                'todo-mayuscula form-control form-control-sm' +
                (formik.errors.person?.names && formik.touched.person?.names ? ' is-invalid' : '')
              }
            />
            <ErrorValidate state={formik.errors.person?.names} />
            <ErrorBackend errorsBackend={props.errors} name="client" />
          </div>
          {formik.values.person.client_document_type_id.toString() === '2' && (
            <>
              {/*APELLIDO PATERNO [NATURAL]*/}
              <div className="form-group col-md-4">
                <label htmlFor="father_last_name" className="form-label">
                  Apellido paterno <span className="text-danger">*</span>
                </label>
                <input
                  id="father_last_name"
                  name="father_last_name"
                  value={formik.values.person.father_last_name ?? ''}
                  onChange={handleInputChange}
                  type="text"
                  className={
                    'todo-mayuscula form-control form-control-sm' +
                    (formik.errors.person?.father_last_name && formik.touched.person?.father_last_name ? ' is-invalid' : '')
                  }
                />
                <ErrorValidate state={formik.errors.person?.father_last_name} />
              </div>
              {/*APELLIDO MATERNO [NATURAL]*/}
              <div className="form-group col-md-4">
                <label htmlFor="mother_last_name" className="form-label">
                  Apellido materno <span className="text-danger">*</span>
                </label>
                <input
                  id="mother_last_name"
                  name="mother_last_name"
                  value={formik.values.person.mother_last_name ?? ''}
                  onChange={handleInputChange}
                  type="text"
                  className={
                    'todo-mayuscula form-control form-control-sm' +
                    (formik.errors.person?.mother_last_name && formik.touched.person?.mother_last_name ? ' is-invalid' : '')
                  }
                />
                <ErrorValidate state={formik.errors.person?.mother_last_name} />
              </div>
            </>
          )}
          </>)}
          {/*DIRECCION */}
          {formik.values.person.client_document_type_id.toString() === '4' && (
            <div className="form-group col-md-12">
              <label htmlFor="address" className="form-label">
                Dirección
              </label>
              <input
                autoComplete='off'
                id="address"
                name="address"
                value={formik.values.person.address ?? ''}
                onChange={handleInputChange}
                type="text"
                className={
                  'todo-mayuscula form-control form-control-sm' +
                  (formik.errors.person?.address && formik.touched.person?.address ? ' is-invalid' : '')
                }
              />
              <ErrorValidate state={formik.errors.person?.address} />
            </div>
          )}
          {/*CELULAR*/}
          <div className="form-group col-md-4">
            <label htmlFor="cellphone" className="form-label">
              Celular
            </label>
            <input
              autoComplete='off'
              id="cellphone"
              name="cellphone"
              value={formik.values.person.cellphone ?? ''}
              onChange={handleInputChange}
              type="number"
              className={
                'form-control form-control-sm' + (formik.errors.person?.cellphone && formik.touched.person?.cellphone ? ' is-invalid' : '')
              }
            />
            <ErrorValidate state={formik.errors.person?.cellphone} />
          </div>
          {/*CORREO PERSONAL*/}
          <div className="form-group col-md-4">
            <label htmlFor="personal_mail" className="form-label">
              Correo personal
            </label>
            <input
              autoComplete='off'
              id="personal_mail"
              name="personal_mail"
              value={formik.values.person.personal_mail ?? ''}
              onChange={handleInputChange}
              type="text"
              className={
                'form-control form-control-sm' +
                (formik.errors.person?.personal_mail && formik.touched.person?.personal_mail ? ' is-invalid' : '')
              }
            />
            <ErrorValidate state={formik.errors.person?.personal_mail} />
          </div>
          {/*DIRECCIÓN*/}
          {formik.values.person.client_document_type_id.toString() === '2' && (
            <div className="form-group col-md-4">
              <label htmlFor="address" className="form-label">
                Dirección
              </label>
              <input
                autoComplete='off'
                id="address"
                name="address"
                value={formik.values.person.address ?? ''}
                onChange={handleInputChange}
                type="text"
                className={
                  'todo-mayuscula form-control form-control-sm' +
                  (formik.errors.person?.address && formik.touched.person?.address ? ' is-invalid' : '')
                }
              />
              <ErrorValidate state={formik.errors.person?.address} />
            </div>
          )}
          <div className="col-md-12 mt-2" style={{ fontSize: '10px' }}>
            <span className="text-danger">*</span>
            <span>Campos obligatorios</span>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="submit" className="btn btn-sm btn-primary">
          {props.data.buttonSubmit}
        </button>
        <button type="button" onClick={() => props.data.onCloseModalForm()} className="btn btn-sm btn-secondary">
          Cancelar
        </button>
      </div>
    </form>
  );
};

export default FormClient;
