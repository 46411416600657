interface FormProps {
  data: any;
  onCloseModalForm: () => void;
}

const InfoSunatModal = (props: FormProps) => {
  return (
    <div className="form-scrollable">
      <div className="modal-body">
        <div className="form-row row justify-content-md-center">
          {props.data.sunat_descripcion === 'XML generado y firmado correctamente.' ? (
            <div className="col-md-12">
              <p>
                <b>Enviada a la SUNAT: </b> <i className="text-primary fa-solid fa-spinner"></i> Procesando...
              </p>
              <p>
                <b>Aceptada por la SUNAT: </b> <i className="text-primary fa-solid fa-spinner"></i> Procesando...
              </p>
              <p>
                <b>Código: </b> {props.data.sunat_codigo}
              </p>
              <p>
                <b>Descripción: </b> {props.data.sunat_descripcion}
              </p>
            </div>
          ) : (
            <div className="col-md-12">
              <p>
                <b>Enviada a la SUNAT: </b>{' '}
                {props.data.enviada_a_la_sunat ? (
                  <>
                    <i className=" text-primary fa-solid fa-circle-check"></i>
                  </>
                ) : (
                  <i className="text-danger fa-solid fa-circle-xmark"></i>
                )}
              </p>
              <p>
                <b>Aceptada por la SUNAT: </b>{' '}
                {props.data.aceptada_por_la_sunat ? (
                  <>
                    <i className=" text-primary fa-solid fa-circle-check"></i>
                  </>
                ) : (
                  <i className="text-danger fa-solid fa-circle-xmark"></i>
                )}
              </p>
              <p>
                <b>Código: </b> {props.data.sunat_codigo}
              </p>
              <p>
                <b>Descripción: </b> {props.data.sunat_descripcion}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" onClick={() => props.onCloseModalForm()} className="btn btn-sm btn-secondary">
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default InfoSunatModal;
