import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
/**Models */
import { DataTable } from "../models";
/**Services */
import * as purchasesService from "../services/purchases.service";
import * as suppliersService from "../services/suppliers.service";
import * as productsService from "../services/products.service";
/**Redux */
import { setLoading } from "../redux/states/loading.slice";
import {
  dataTable_setAlls,
  dataTable_clearAll,
  dataTable_deleteResource,
} from "../redux/states/dataTable.slice";
import { PurchaseRequest } from "../models/requests";

export function usePurchases() {
  const dispatch = useDispatch();
  const [purchases, setPurchases] = useState<DataTable | null>(null);

  //GET
  const fetchPurchases = useCallback(
    (
      page: number,
      text: string,
      type_document_id: string,
      date_initial: string,
      date_final: string,
      loading: boolean,
      sales: string,
    ) => {
      dispatch(setLoading(loading));
      purchasesService
        .getPurchases(page, text, type_document_id, date_initial, date_final, sales)
        .then((response) => {
          dispatch(dataTable_setAlls(response));
          setPurchases(response); // Actualiza el estado local si es necesario
        })
        .catch((error) => error)
        .finally(() => {
          dispatch(setLoading(false));
        });
    },
    [dispatch],
  );

  useEffect(() => {
    const resetData = () => {
      dispatch(dataTable_clearAll());
      setPurchases(null); // Borra el estado local si es necesario
    };

    return () => {
      resetData(); // Esto se ejecutará cuando el componente se desmonte
    };
  }, [dispatch]);

  const getRequirements = async (type: string) => {
    dispatch(setLoading(true));
    try {
      const response = await purchasesService.getRequirements(type);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const supplierSearch = async (text: string, type: string) => {
    try {
      const response = await suppliersService.searchSupplier(text, type);
      return response;
    } catch (error) {
      throw error;
    } finally {
    }
  };

  const productSearch = async (text: string, model_id: string, products_not: string, filter: string) => {
    try {
      const response = await productsService.searchProduct(text, model_id, products_not, filter);
      return response;
    } catch (error) {
      throw error;
    } finally {
    }
  };

  //STORE
  const storePurchase = async (purchase: PurchaseRequest) => {
    dispatch(setLoading(true));
    try {
      const response = await purchasesService.storePurchase(purchase);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  //DELETE
  const deletePurchase = async (id: string) => {
    dispatch(setLoading(true));
    try {
      const response = await purchasesService.deletePurchase(id);
      dispatch(dataTable_deleteResource({ id }));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  //DETAIL
  const getPurchase = async (uuid: string) => {
    dispatch(setLoading(true));
    try {
      const response = await purchasesService.getPurchase(uuid);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }

  //UPDATE
  const updatePurchase = async (purchase: PurchaseRequest) => {
    dispatch(setLoading(true));
    try {
      const response = await purchasesService.updatePurchase(purchase);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  return {
    getRequirements: getRequirements,
    supplierSearch,
    productSearch,
    storePurchase,
    purchases,
    fetchPurchases,
    deletePurchase,
    getPurchase,
    updatePurchase,
  };
}
