import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
/**Models */
import { Login } from '../models';
/**Validations */
import { useFormik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
/**Services */
import * as authService from '../services/auth.service';
/**Redux */
import { setLoading } from '../redux/states/loading.slice';

export const useLogin = () => {
  const dispatch = useDispatch();
  const [formData] = useState<Login>({
    user: '',
    password: '',
  });
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    user: Yup.string().required('Campo obligatorio.'),
    password: Yup.string().required('Campo obligatorio.'),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: async (values) => {
      dispatch(setLoading(true));
      authService
        .login(values)
        .then((response) => {
          localStorage.setItem('token', response.access_token);
          localStorage.setItem('permissions', response.user.permissions.join(','));
          localStorage.setItem('stateToggle', 'false');
          navigate('/');
        })
        .catch((error) => error)
        .finally(() => {
          dispatch(setLoading(false));
        });
    },
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event);
  };

  const handleLogout = () => {
    dispatch(setLoading(true));
    authService
      .logout()
      .then(() => {
        localStorage.clear();
        navigate('/login');
      })
      .catch((error) => error)
      .finally(() => dispatch(setLoading(false)));
  };

  return { handleInputChange, handleLogout, formik, ErrorMessage };
};
