/**Services */
import { useDispatch } from "react-redux";
import * as inventoriesService from "../services/inventories.service";
import { setLoading } from "../redux/states/loading.slice";
import { InventorySettingRequest } from "../models/requests";
/**Redux */

export function useInventories() {
  const dispatch = useDispatch();

  const productSearch = async (
    text: string,
    warehouse_id: string,
    products_not: string,
  ) => {
    try {
      const response = await inventoriesService.searchProduct(
        text,
        warehouse_id,
        products_not,
      );
      return response;
    } catch (error) {
      throw error;
    } finally {
    }
  };

  const getRequirements = async () => {
    dispatch(setLoading(true));
    try {
      const response = await inventoriesService.getRequirements();
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const storeInventory = async (inventory: InventorySettingRequest) => {
    dispatch(setLoading(true));
    try {
      const response = await inventoriesService.storeInventory(inventory);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getInventories = async (type: string, warehouse_id: string) => {
    dispatch(setLoading(true));
    try {
      const response = await inventoriesService.getInventories(type, warehouse_id);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }

  return {
    productSearch,
    getRequirements,
    storeInventory,
    getInventories,
  };
}
