import { ChangeEvent, useState } from 'react';
/**Models */
import { Product } from '../../../models';
/**Validations */
import { useFormik } from 'formik';
/**Utilities */
import { ErrorValidate, ErrorBackend } from '../../../utilities';
import * as Yup from 'yup';

interface FormProps {
  data: any;
  onSubmitForm: (product: Product, type: string) => void;
  errors: any;
}

export const FormProduct = (props: FormProps) => {
  const [formData, setFormData] = useState<Product>(
    props.data.row || {
      type_product_id: "",
      category_id: '',
      presentation_id: '',
      brand_id: '',
      igv_type_id: '',
      name: '',
      code_internal: '',
      barcode: '',
      code_sunat: '',
      purchase_price: '',
      sale_price: '',
    },
  );

  const validationSchema = Yup.object().shape({
    type_product_id: Yup.number().required("El campo 'tipo de producto' es obligatorio."),
    name: Yup.string().required("El campo 'nombre' es obligatorio."),
    igv_type_id: Yup.number().required("El campo 'tipo de IGV' es obligatorio."),
    purchase_price: Yup.number().required("El campo 'precio de compra' es obligatorio."),
    sale_price: Yup.number().required("El campo 'precio de venta' es obligatorio."),
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    formik.handleChange(event);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: () => {
      props.onSubmitForm(formData, props.data.type);
    },
  });

  return (
    <form className="form form-horizontal form-scrollable" onSubmit={formik.handleSubmit}>
      <div className="modal-body">
        <div className="form-row row">
          {/*CODIGO INTERNO */}
          <div className="form-group col-md-4">
            <label htmlFor="code_internal" className="form-label">
              Código interno
            </label>
            <input
              id="code_internal"
              name="code_internal"
              value={formik.values.code_internal || '######'}
              disabled
              type="text"
              className="todo-mayuscula form-control form-control-sm"
            />
          </div>
          {/*CODIGO DE BARRAS */}
          <div className="form-group col-md-4">
            <label htmlFor="barcode" className="form-label">
              Código de barras
            </label>
            <input
              autoComplete='off'
              id="barcode"
              name="barcode"
              value={formik.values.barcode || ''}
              onChange={handleInputChange}
              type="text"
              className='todo-mayuscula form-control form-control-sm'
            />
            <ErrorBackend errorsBackend={props.errors} name="product.barcode" />
          </div>
          {/*CODIGO SUNAT */}
          <div className="form-group col-md-4">
            <label htmlFor="code_sunat" className="form-label">
              Código Sunat
            </label>
            <input
              id="code_sunat"
              autoComplete='off'
              name="code_sunat"
              value={formik.values.code_sunat || ''}
              onChange={handleInputChange}
              type="text"
              className='todo-mayuscula form-control form-control-sm'
            />
            <ErrorBackend errorsBackend={props.errors} name="product.code_sunat" />
          </div>
          {/*TIPO PRODUCTO */}
          <div className="form-group col-md-4">
            <label htmlFor="type_product_id" className="form-label">
              Tipo(Unidad de medida)<span className="text-danger">*</span>
            </label>
            <select
              onChange={handleSelectChange}
              id="type_product_id"
              className={
                'form-control form-control-sm form-select' +
                (formik.errors.type_product_id && formik.touched.type_product_id ? ' is-invalid' : '')
              }
              name="type_product_id"
              value={formik.values.type_product_id || ''}
            >
              <option value="">Seleccionar</option>
              {props.data.requirements.type_products.map((type: any) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </select>
            <ErrorValidate state={formik.errors.type_product_id} />
            <ErrorBackend errorsBackend={props.errors} name="product.type_product_id" />
          </div>
          {/*NOMBRE PRODUCTO */}
          <div className="form-group col-md-8">
            <label htmlFor="name" className="form-label">
              Nombre <span className="text-danger">*</span>
            </label>
            <input
              autoComplete='off'
              id="name"
              name="name"
              value={formik.values.name}
              onChange={handleInputChange}
              type="text"
              className={'todo-mayuscula form-control form-control-sm' + (formik.errors.name && formik.touched.name ? ' is-invalid' : '')}
            />
            <ErrorValidate state={formik.errors.name} />
            <ErrorBackend errorsBackend={props.errors} name="product" />
          </div>
          {/*CATEGORIA */}
          <div className="form-group col-md-4">
            <label htmlFor="category_id" className="form-label">
              Categoría
            </label>
            <select value={formik.values.category_id || ''} onChange={handleSelectChange} id="category_id" className="form-select form-control form-control-sm" name="category_id">
              <option value="">Seleccionar</option>
              {props.data.requirements.categories.map((category: any) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
            <ErrorBackend errorsBackend={props.errors} name="product.category_id" />
          </div>
          {/*PRESENTACION */}
          <div className="form-group col-md-4">
            <label htmlFor="presentation_id" className="form-label">
              Presentación
            </label>
            <select value={formik.values.presentation_id || ''} onChange={handleSelectChange} id="presentation_id" className="form-select form-control form-control-sm" name="presentation_id">
              <option value="">Seleccionar</option>
              {props.data.requirements.presentations.map((presentation: any) => (
                <option key={presentation.id} value={presentation.id}>
                  {presentation.name}
                </option>
              ))}
            </select>
            <ErrorBackend errorsBackend={props.errors} name="product.presentation_id" />
          </div>
          {/*MARCA */}
          <div className="form-group col-md-4">
            <label htmlFor="brand_id" className="form-label">
              Marca
            </label>
            <select value={formik.values.brand_id || ''} onChange={handleSelectChange} id="brand_id" className="form-select form-control form-control-sm" name="brand_id">
              <option value="">Seleccionar</option>
              {props.data.requirements.brands.map((brand: any) => (
                <option key={brand.id} value={brand.id}>
                  {brand.name}
                </option>
              ))}
            </select>
            <ErrorBackend errorsBackend={props.errors} name="product.brand_id" />
          </div>
          {/*TIPO DE IGV */}
          <div className="form-group col-md-4">
            <label htmlFor="igv_type_id" className="form-label">
              Tipo de IGV <span className="text-danger">*</span>
            </label>
            <select
              value={formik.values.igv_type_id || ''}
              onChange={handleSelectChange}
              id="igv_type_id"
              className={
                'form-select form-control form-control-sm' + (formik.errors.igv_type_id && formik.touched.igv_type_id ? ' is-invalid' : '')
              }
              name="igv_type_id"
            >
              <option value="">Seleccionar</option>
              {props.data.requirements.igv_types.map((igv: any) => (
                <option key={igv.id} value={igv.id}>
                  {igv.name}
                </option>
              ))}
            </select>
            <ErrorValidate state={formik.errors.igv_type_id} />
            <ErrorBackend errorsBackend={props.errors} name="product.igv_type_id" />
          </div>
          {/*PRECIO COMPRA */}
          <div className="form-group col-md-4">
            <label htmlFor="purchase_price" className="form-label">
              Precio de compra <span className="text-danger">*</span>
            </label>
            <input
              autoComplete='off'
              id="purchase_price"
              name="purchase_price"
              value={formik.values.purchase_price}
              onChange={handleInputChange}
              type="number"
              className={
                'form-control form-control-sm' + (formik.errors.purchase_price && formik.touched.purchase_price ? ' is-invalid' : '')
              }
            />
            <ErrorValidate state={formik.errors.purchase_price} />
            <ErrorBackend errorsBackend={props.errors} name="product.purchase_price" />
          </div>
          {/*PRECIO VENTA */}
          <div className="form-group col-md-4">
            <label htmlFor="sale_price" className="form-label">
              Precio de venta <span className="text-danger">*</span>
            </label>
            <input
              autoComplete='off'
              id="sale_price"
              name="sale_price"
              value={formik.values.sale_price}
              onChange={handleInputChange}
              type="number"
              className={'form-control form-control-sm' + (formik.errors.sale_price && formik.touched.sale_price ? ' is-invalid' : '')}
            />
            <ErrorValidate state={formik.errors.sale_price} />
            <ErrorBackend errorsBackend={props.errors} name="product.sale_price" />
          </div>
          <div className="col-md-12 mt-2" style={{ fontSize: '10px' }}>
            <span className="text-danger">*</span>
            <span>Campos obligatorios</span>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="submit" className="btn btn-sm btn-primary">
          {props.data.buttonSubmit}
        </button>
        <button type="button" onClick={() => props.data.onCloseModalForm()} className="btn btn-sm btn-secondary">
          Cancelar
        </button>
      </div>
    </form>
  );
};

export default FormProduct;
