import { createSlice } from '@reduxjs/toolkit';
import { User } from '../../models';


export const UserEmptyState: User = {
  id: 0,
  photo: '',
  document_number: '',
  email: '',
  names: '',
  father_last_name: '',
  mother_last_name: '',
  roles: [],
  permissions: [],
  type: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState: UserEmptyState,
  reducers: {
    setUser: (state, action) => {
        state = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;