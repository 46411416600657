import api from "./api";
/**Models */
import { DataTable, Warehouse } from "../models";
import { WarehouseResponse } from "../models/responses";

export const getWarehouses = async (page: number, text: string) => {
  try {
    const response = await api.get<DataTable>(
      `/warehouses?page=${page}&text=${text}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const storeWarehouse = async (warehouse: Warehouse) => {
  try {
    const response = await api.post<WarehouseResponse>("/warehouses", {
      warehouse: warehouse,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateWarehouse = async (warehouse: Warehouse) => {
  try {
    const response = await api.update<WarehouseResponse>(
      `/warehouses/${warehouse.id}`,
      { warehouse: warehouse },
    );
    return response;
  } catch (error) {
    throw error;
  }
};
