const PurchaseHeaderDetail = () => {
  return (
    <div className="row">
      {/* <div className="col-md-1">
        <p className="mb-0">Inventario</p>
      </div> */}
      <div className="col-md-4">
        <p className="mb-0">Producto o servicio</p>
      </div>
      <div className="col-md-2">
        <p className="mb-0">Detalle adicional</p>
      </div>
      <div className="col-md-1">
        <p className="mb-0">Cantidad</p>
      </div>
      <div className="col-md-1">
        <p className="mb-0">Tipo IGV</p>
      </div>
      <div className="col-md-1">
        <p className="mb-0">Valor Unit.</p>
      </div>
      <div className="col-md-1">
        <p className="mb-0">Subtotal</p>
      </div>
      <div className="col-md-1">
        <p className="mb-0">Total</p>
      </div>
      <div className="col-md-1">
        <p className="mb-0">Acción</p>
      </div>
    </div>
  );
};
export default PurchaseHeaderDetail;
