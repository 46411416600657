import api from "./api";
/**Models */
import { SelectResponse, SuccessResponse } from "../models/responses";
import { InventorySettingRequest } from "../models/requests";

export const searchProduct = async (
  text: string,
  warehouse_id: string,
  products_not: string,
) => {
  try {
    const response = await api.get<SelectResponse>(
      `/inventories/search-products?warehouse_id=${warehouse_id}text=${text}&products_not=${products_not}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getRequirements = async () => {
  try {
    const response = await api.get("/inventories/requirements");
    return response;
  } catch (error) {
    throw error;
  }
};

export const storeInventory = async (inventory: InventorySettingRequest) => {
  try {
    const response = await api.post<SuccessResponse>("/inventories", {
      inventory: inventory,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getInventories = async (type: string, warehouse_id: string) => {
  try {
    const response = await api.get("/inventories?type=" + type + "&warehouse_id=" + warehouse_id);
    return response;
  } catch (error) {
    throw error;
  }
};
