import { ChangeEvent, useState } from 'react';

interface FormProps {
  data: any;
  onCloseModalForm: () => void;
  onSubmitFormEmail: (saleId: string, email: string) => void;
}

const InfoSunatModal = (props: FormProps) => {
  const [email, setEmail] = useState<string>(props.data.client_email);

  const changeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const sendEmailSale = () => {
    props.onSubmitFormEmail(props.data.id, email);
  };

  return (
    <div className="form-scrollable">
      <div className="modal-body">
        <div className="form-row row justify-content-md-center">
          <div className="col-lg-12">
            <div className="input-group mb-3">
              <input onChange={changeEmail} value={email} type="text" className="form-control form-control-sm" />
              <span className="input-group-append">
                <button onClick={sendEmailSale} disabled={email === ''} className="btn btn-sm btn-primary" type="button">
                  <i className="far fa-envelope"></i> Enviar
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" onClick={() => props.onCloseModalForm()} className="btn btn-sm btn-secondary">
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default InfoSunatModal;
