/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChangeEvent } from 'react';
/**Utilities */
import { ErrorBackend } from '../../../utilities';

interface Props {
  datosTransportista: any;
  handleInputChangeValue: (key: any, fieldName: string, value: string) => void;
  getPlacas: any;
  errors: any;
  tipoTransporte: string;
  personSearch: any;
}
const DatosTransportista = (props: Props) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.handleInputChangeValue('datosTransportista', event.target.name, event.target.value);
  };

  const onKeyUpSearchPlaca = () => {
    if (props.datosTransportista.numeroPlaca.length === 6) {
      props.getPlacas(props.datosTransportista.numeroPlaca).then((response: any) => {
        props.handleInputChangeValue('datosTransportista', 'validatePlaca', response.success);
      });
    }
  };

  const hasError = (propertyName: string) => {
    return props.errors && props.errors[propertyName];
  };

  const onChangeSelect = (event: ChangeEvent<HTMLSelectElement>) => {};

  const onKeyUpSearchRuc = () => {
    if (props.datosTransportista.numeroDocumento.length === 11) {
      props.personSearch(props.datosTransportista.numeroDocumento).then((response: any) => {
        props.handleInputChangeValue('datosTransportista', 'denominacion', response.names);
      });
    }
  };

  return (
    <>
      {props.tipoTransporte === '01' && (
        <div className="row">
          {/*Tipo de documento*/}
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="tipoDocumento" className="form-label">
                Tipo de documento<span className="text-danger">*</span>
              </label>
              <select
                onChange={onChangeSelect}
                value={props.datosTransportista.tipoDocumento}
                className={`form-control form-control-sm form-select${
                  hasError('despatch.datosTransportista.tipoDocumento') ? ' is-invalid' : ''
                }`}
                name="tipoDocumento"
                id="tipoDocumento"
              >
                <option value="6">RUC</option>
              </select>
              <ErrorBackend errorsBackend={props.errors} name="despatch.datosTransportista.tipoDocumento" />
            </div>
          </div>
          {/*Número de documento*/}
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="numeroDocumento" className="form-label">
                Número de documento<span className="text-danger">*</span>
              </label>
              <input
                value={props.datosTransportista.numeroDocumento}
                onChange={handleInputChange}
                onKeyUp={onKeyUpSearchRuc}
                autoComplete="off"
                name="numeroDocumento"
                id="numeroDocumento"
                type="text"
                className={`todo-mayuscula form-control form-control-sm${
                  hasError('despatch.datosTransportista.numeroDocumento') ? ' is-invalid' : ''
                }`}
              />
              <ErrorBackend errorsBackend={props.errors} name="despatch.datosTransportista.numeroDocumento" />
            </div>
          </div>
          {/*Denominacion*/}
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="denominacion" className="form-label">
                Denominacion<span className="text-danger">*</span>
              </label>
              <input
                value={props.datosTransportista.denominacion}
                onChange={handleInputChange}
                autoComplete="off"
                name="denominacion"
                id="denominacion"
                type="text"
                className={`todo-mayuscula form-control form-control-sm${
                  hasError('despatch.datosTransportista.denominacion') ? ' is-invalid' : ''
                }`}
              />
              <ErrorBackend errorsBackend={props.errors} name="despatch.datosTransportista.denominacion" />
            </div>
          </div>
          {/*Número de registro MTC */}
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="numeroMTC" className="form-label">
                Número de registro MTC
              </label>
              <input
                value={props.datosTransportista.numeroMTC}
                onChange={handleInputChange}
                autoComplete="off"
                name="numeroMTC"
                id="numeroMTC"
                type="text"
                className={`todo-mayuscula form-control form-control-sm${
                  hasError('despatch.datosTransportista.denominacion') ? ' is-invalid' : ''
                }`}
              />
              <ErrorBackend errorsBackend={props.errors} name="despatch.datosTransportista.numeroMTC" />
            </div>
          </div>
        </div>
      )}
      {props.tipoTransporte === '02' && (
        <div className="row">
          <div className="col-md-4">
            <div className="row border-sales">
              <div className="title-items">Datos del Vehículo Principal</div>
              {/* Placa del vehículo*/}
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="numeroPlaca" className="form-label">
                    Placa del vehículo<span className="text-danger">*</span>
                  </label>
                  <input
                    value={props.datosTransportista.numeroPlaca}
                    name="numeroPlaca"
                    id="numeroPlaca"
                    autoComplete="off"
                    onChange={handleInputChange}
                    onKeyUp={onKeyUpSearchPlaca}
                    placeholder="Ejemplo: ABC123"
                    type="text"
                    className={
                      'todo-mayuscula form-control form-control-sm ' +
                      (props.datosTransportista.validatePlaca === true ? ' is-valid' : '') +
                      (props.datosTransportista.validatePlaca === false ? ' is-invalid' : '') +
                      (hasError('despatch.datosTransportista.numeroPlaca') ? ' is-invalid' : '')
                    }
                  />
                  <ErrorBackend errorsBackend={props.errors} name="despatch.datosTransportista.numeroPlaca" />
                  <div className="valid-feedback">Placa validada correctamente.</div>
                  <div className="invalid-feedback">Placa no existe.</div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-5">
        <div className="row border-sales">
          <div className="title-items">
            Datos del Vehículo Secundario (Máximo 2 Vehículos){' '}
            <a className="add-item-sales">
              <i className="ri-add-line"></i>
            </a>
          </div>
          {/* Placa del vehículo
          <div className="col-md-8">
            <p className="form-label">Placa del vehículo</p>
          </div>
          <div className="col-md-4">
            <p className="mb-0">Acción</p>
          </div>
          <div className="col-md-8">
            <input type="text" className="form-control form-control-sm" />
          </div>
          <div className="col-md-4">
            <a className="text-danger" role="button">
              <i className="ri-delete-bin-line font-size-18"></i>
            </a>
          </div>
        </div>
      </div> */}
        </div>
      )}
    </>
  );
};

export default DatosTransportista;
