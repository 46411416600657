import api from './api';
/**Models */
import { DataTable, Category } from '../models';
import { CategoryResponse } from '../models/responses';

export const getCategories = async (page: number, text: string) => {
  try {
    const response = await api.get<DataTable>(`/categories?page=${page}&text=${text}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const storeCategory = async (category: Category) => {
  try {
    const response = await api.post<CategoryResponse>('/categories', { category: category });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateCategory = async (category: Category) => {
  try {
    const response = await api.update<CategoryResponse>(`/categories/${category.id}`, { category: category });
    return response;
  } catch (error) {
    throw error;
  }
};
