/**Models */
import { SaleTotalsRequest } from '../../../models/requests';

interface Props {
  totals: SaleTotalsRequest;
}

export const SalesTotals = (props: Props) => {
  return (
    <div className="col-md-4 mt-3">
      <div className="row border-sales d-flex align-items-center ">
        {/* <div className="col-md-8">
          <p className="text-end m-0">% Descuento Global</p>
        </div>
        <div className="col-md-4">
          <input type="text" className="form-control form-control-sm" />
        </div> */}
        {/*Descuento global*/}
        <div className="col-md-8">
          <p className="text-end m-0">Descuento Global (-)</p>
        </div>
        <div className="col-md-4">
          <input value={props.totals.descuento_global.toFixed(2)} readOnly disabled type="text" className="form-control form-control-sm" />
        </div>

        {/*Descuento por item*/}
        <div className="col-md-8">
          <p className="text-end m-0">Descuento por Item (-)</p>
        </div>
        <div className="col-md-4">
          <input value={props.totals.descuento_por_item.toFixed(2)} readOnly disabled type="text" className="form-control form-control-sm" />
        </div>

        {/*Descuento total*/}
        <div className="col-md-8">
          <p className="text-end m-0">Descuento Total (-)</p>
        </div>
        <div className="col-md-4">
          <input value={props.totals.descuento_total.toFixed(2)} readOnly disabled type="text" className="form-control form-control-sm" />
        </div>

        {/*Anticipo*/}
        <div className="col-md-8">
          <p className="text-end m-0">Anticipo (-)</p>
        </div>
        <div className="col-md-4">
          <input value={props.totals.anticipo.toFixed(2)} readOnly disabled type="text" className="form-control form-control-sm" />
        </div>

        {/*Exonerada*/}
        <div className="col-md-8">
          <p className="text-end m-0">Exonerada</p>
        </div>
        <div className="col-md-4">
          <input value={props.totals.exonerada.toFixed(2)} readOnly disabled type="text" className="form-control form-control-sm" />
        </div>

        {/*Inafecta*/}
        <div className="col-md-8">
          <p className="text-end m-0">Inafecta</p>
        </div>
        <div className="col-md-4">
          <input value={props.totals.inafecta.toFixed(2)} readOnly disabled type="text" className="form-control form-control-sm" />
        </div>

        {/*Gravada*/}
        <div className="col-md-8">
          <p className="text-end m-0">Gravada</p>
        </div>
        <div className="col-md-4">
          <input value={props.totals.gravada.toFixed(2)} readOnly disabled type="text" className="form-control form-control-sm" />
        </div>

        {/*IGV*/}
        <div className="col-md-8">
          <p className="text-end m-0">IGV</p>
        </div>
        <div className="col-md-4">
          <input value={props.totals.igv.toFixed(2)} readOnly type="text" className="form-control form-control-sm" />
        </div>

        {/*Gratuita*/}
        <div className="col-md-8">
          <p className="text-end m-0">Gratuita</p>
        </div>
        <div className="col-md-4">
          <input value={props.totals.gratuita.toFixed(2)} readOnly disabled type="text" className="form-control form-control-sm" />
        </div>

        {/*ICBPER*/}
        <div className="col-md-8">
          <p className="text-end m-0">ICBPER</p>
        </div>
        <div className="col-md-4">
          <input value={props.totals.icbper.toFixed(2)} readOnly disabled type="text" className="form-control form-control-sm" />
        </div>

        {/*Total*/}
        <div className="col-md-8">
          <p className="text-end m-0">Total</p>
        </div>
        <div className="col-md-4">
          <input value={props.totals.total.toFixed(2)} readOnly disabled type="text" className="form-control form-control-sm" />
        </div>
      </div>
    </div>
  );
};

export default SalesTotals;
