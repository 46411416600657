import { ChangeEvent, useState } from 'react';

interface FormProps {
  formData: any;
  onChange: any;
  onCloseModalForm: () => void;
}

const PlacaVehiculo = (props: FormProps) => {

  const [placa, setPlaca] = useState<string>(props.formData.placa_vehicle);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPlaca(value);
    props.onChange(name, value);
  };


  return (
    <div className='form form-horizontal form-scrollable'>
    <div className="modal-body">
      <div className="form-row row">
        {/*PLACA */}
        <div className="form-group col-md-12">
          <label htmlFor="placa_vehicle" className="form-label">
            Número de placa del vehiculo
          </label>
          <input
            id="placa_vehicle"
            name="placa_vehicle"
            value={placa}
            onChange={handleInputChange}
            type="text"
            className="todo-mayuscula form-control form-control-sm"
          />
        </div>
      </div>
    </div>
    <div className="modal-footer">
        <button type="button" onClick={() => props.onCloseModalForm()} className="btn btn-sm btn-secondary">
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default PlacaVehiculo;
