/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, ChangeEvent } from 'react';
import { Tooltip } from 'react-tooltip';
/**DatePicker */
import Flatpickr from 'react-flatpickr';
/**Moment */
import moment from 'moment';
/**Utilities */
import { ErrorBackend } from '../../../utilities';
/**Models */
import { DocumentoRelacionadoRequest } from '../../../models/requests';
/**Select */
import Select from 'react-select';

interface OptionSelect {
  label: string;
  value: string;
}

interface Props {
  datosTraslado: any;
  handleSelectChangeValue: (key: any, fieldName: string, value: string) => void;
  handleInputChangeValue: (key: any, fieldName: string, value: string) => void;
  errors: any;
  documentosRelacionados: DocumentoRelacionadoRequest[];
  requirements: any;
  onClickAddDocumento: () => void;
  searchDocuments: any;
  handleDocumentSelected: (index: number, fieldName: string, value: string) => void;
  clientId: string | number;
  onClickDeleteDocument: (index: number) => void;
  handleTextAreaChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  observaciones: string;
}

const DatosTraslado = (props: Props) => {
  const recurrentDate = moment().format('YYYY-MM-DD');
  const [selectedDate, setSelectedDate] = useState<any>(props.datosTraslado.fecha);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [optionsDocuments, setOptionsDocuments] = useState<OptionSelect[]>([]);

  const handleDateChange = (selectedDates: Date[]) => {
    setSelectedDate(selectedDates[0]);
    const fecha = moment(selectedDates[0]).format('YYYY-MM-DD');
    props.handleSelectChangeValue('datosTraslado', 'fecha', fecha);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.handleInputChangeValue('datosTraslado', event.target.name, event.target.value);
  };

  const onChangeSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    props.handleSelectChangeValue('datosTraslado', event.target.name, event.target.value);
  };

  const onChangeSelectTypeDocument = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
    props.handleDocumentSelected(index, event.target.name, event.target.value);
  };

  const hasError = (propertyName: string) => {
    return props.errors && props.errors[propertyName];
  };

  const handlSearchDocuments = (value: string, typeDocument: string) => {
    if (value.length >= 3) {
      props
        .searchDocuments(value, props.clientId, typeDocument)
        .then((response: any) => {
          setIsLoadingDocuments(true);
          setOptionsDocuments(response.data);
        })
        .finally(() => {
          setIsLoadingDocuments(false);
        });
    }
  };

  const handleDocumentsSelectChange = (selectedOption: OptionSelect, index: number) => {
    props.handleDocumentSelected(index, 'numeroDocumento', selectedOption.label);
  };

  return (
    <>
      <Tooltip id="tooltip-datos-traslado" place="right-start" />
      <div className="row">
        {/* Fecha de inicio de traslado*/}
        <div className="col-md-2">
          <div className="form-group">
            <label htmlFor="fecha" className="form-label">
              Fecha de inicio de traslado<span className="text-danger">*</span>
            </label>
            <Flatpickr
              name="fecha"
              id="fecha"
              value={selectedDate}
              onChange={handleDateChange}
              className="form-control form-control-sm"
              options={{
                dateFormat: 'Y-m-d',
                locale: 'es',
                minDate: recurrentDate,
              }}
            />
          </div>
        </div>
        {/* Peso - unidad de medida*/}
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="pesoUnidadMedida" className="form-label">
              Peso - unidad de medida<span className="text-danger">*</span>
            </label>
            <select
              onChange={onChangeSelect}
              //className="form-control form-control-sm form-select"
              name="pesoUnidadMedida"
              id="pesoUnidadMedida"
              value={props.datosTraslado.pesoUnidadMedida}
              className={`form-control form-control-sm form-select${
                hasError('despatch.datosTraslado.pesoUnidadMedida') ? ' is-invalid' : ''
              }`}
            >
              <option value="" disabled>
                Seleccionar
              </option>
              <option value="KGM">KILOGRAMO</option>
              <option value="TNE">TONELADA</option>
            </select>
            <ErrorBackend errorsBackend={props.errors} name="despatch.datosTraslado.pesoUnidadMedida" />
          </div>
        </div>
        {/* Peso bruto total*/}
        <div className="col-md-2">
          <div className="form-group">
            <label htmlFor="pesoBrutoTotal" className="form-label">
              Peso bruto total<span className="text-danger">*</span>
            </label>
            <input
              autoComplete="off"
              onChange={handleInputChange}
              value={props.datosTraslado.pesoBrutoTotal}
              id="pesoBrutoTotal"
              name="pesoBrutoTotal"
              type="text"
              className={`form-control form-control-sm${hasError('despatch.datosTraslado.pesoBrutoTotal') ? ' is-invalid' : ''}`}
            />
            <ErrorBackend errorsBackend={props.errors} name="despatch.datosTraslado.pesoBrutoTotal" />
          </div>
        </div>
        {/* Número de bultos*/}
        <div className="col-md-2">
          <div className="form-group">
            <label htmlFor="numeroBultos" className="form-label">
              Número de bultos<span className="text-danger">*</span>
            </label>
            <input
              autoComplete="off"
              onChange={handleInputChange}
              name="numeroBultos"
              id="numeroBultos"
              type="text"
              className={`form-control form-control-sm${hasError('despatch.datosTraslado.numeroBultos') ? ' is-invalid' : ''}`}
              value={props.datosTraslado.numeroBultos}
            />
            <ErrorBackend errorsBackend={props.errors} name="despatch.datosTraslado.numeroBultos" />
          </div>
        </div>
        {/* Indicador de envío SUNAT*/}
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="indicadorDeEnvio" className="form-label">
              Indicador de envío SUNAT
            </label>
            <select
              value={props.datosTraslado.indicadorDeEnvio}
              onChange={onChangeSelect}
              className="form-control form-control-sm form-select"
              name="indicadorDeEnvio"
              id="indicadorDeEnvio"
            >
              <option value="">Seleccionar</option>
              <option value="IndicadorRetornoVehiculoEnvaseVacio">IndicadorRetornoVehiculoEnvaseVacio</option>
              <option value="IndicadorRetornoVehiculoVacio">IndicadorRetornoVehiculoVacio</option>
            </select>
          </div>
        </div>
        <div className="col-md-5 border-sales mt-3">
          <div className="row">
            <div className="title-items">
              Documentos relacionados{' '}
              <a
                onClick={props.onClickAddDocumento}
                data-tooltip-id="tooltip-datos-traslado"
                data-tooltip-content="Agregar documento relacionado"
                className="add-item-sales"
              >
                <i className="ri-add-line"></i>
              </a>
            </div>
            <div className="col-md-5">
              <label className="form-label">Tipo documento</label>
            </div>
            <div className="col-md-5">
              <label className="form-label">Documento</label>
            </div>
            <div className="col-md-2">
              <label className="form-label">Acción</label>
            </div>
          </div>
          {props.documentosRelacionados.map((item: DocumentoRelacionadoRequest, index: number) => (
            <div key={index} className="row mt-1">
              {/*Tipo de documento*/}
              <div className="col-md-5">
                <select
                  onChange={(e) => onChangeSelectTypeDocument(e, index)}
                  value={item.tipoDocumento}
                  className={`form-control form-control-sm form-select${
                    hasError(`despatch.documentosRelacionados.${index}.tipoDocumento`) ? ' is-invalid' : ''
                  }`}
                  name="tipoDocumento"
                  id="tipoDocumento"
                >
                  <option value="">Seleccionar</option>
                  {props.requirements.tipo_documentos.map((item: any, index: number) => (
                    <option key={index} value={item.code}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <ErrorBackend errorsBackend={props.errors} name={`despatch.documentosRelacionados.${index}.tipoDocumento`} />
              </div>
              {/*Documento*/}
              <div className="col-md-5">
                <Select
                  isLoading={isLoadingDocuments}
                  placeholder={'Buscar documento...'}
                  options={optionsDocuments}
                  onInputChange={(value) => handlSearchDocuments(value, item.tipoDocumento)}
                  noOptionsMessage={() => 'No se encontraron resultados'}
                  onChange={(selectedOption) => handleDocumentsSelectChange(selectedOption, index)}
                  value={item.selectedDocument}
                />
                <ErrorBackend errorsBackend={props.errors} name={`despatch.documentosRelacionados.${index}.numeroDocumento`} />
              </div>
              {/*ACCIONES*/}
              <div className="col-md-1">
                <a
                  data-tooltip-id="tooltip-products-body"
                  data-tooltip-content="Eliminar producto"
                  onClick={() => props.onClickDeleteDocument(index)}
                  className="text-danger"
                  role="button"
                >
                  <i className="ri-delete-bin-line font-size-18"></i>
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className="col-md-5 border-sales mt-3 ms-3">
          <label htmlFor="observaciones">Observaciones</label>
          <textarea
            value={props.observaciones ?? ''}
            onChange={props.handleTextAreaChange}
            rows={2}
            className="form-control todo-mayuscula"
            name="observaciones"
            id="observaciones"
          ></textarea>
        </div>
      </div>
    </>
  );
};

export default DatosTraslado;
