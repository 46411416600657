import { useState } from "react";
/**Components */
import HeaderPage from "../../../components/page/header";
import BodyPage from "../../../components/page/body";
import ModalComponent from "../../../components/modal";
/** Componentes propios */
import Filter from "./filter";
import FormWarehouse from "./form";
/**Hooks */
import { useWarehouses } from "../../../hooks";
/**Models */
import { Warehouse } from "../../../models";
/**Utilities */
import { SweetAlert } from "../../../utilities";

/** Modal Resource */
interface DataModalState {
  type: string;
  buttonSubmit: string | null;
  row: any | null;
  title: string | null;
  requirements: any[];
  onCloseModalForm: () => void;
  errosBackend: any;
}

export const WarehouseIndex = () => {
  const { fetchWarehouses, storeWarehouse, updateWarehouse } = useWarehouses();
  const [dataModalResourceState, setDataModalResourceState] =
    useState<DataModalState>({
      type: "",
      buttonSubmit: null,
      row: null,
      title: null,
      requirements: [],
      onCloseModalForm: () => {},
      errosBackend: {},
    });
  const [errors, setErrors] = useState({});
  const [filterState, setFilterState] = useState({
    page: 1,
    text: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stateModal, setStateModal] = useState<boolean>(false);

  //let errorsBakend = {};
  const state = {
    page: {
      title: "Almacenes",
      icon: "ri-box-1-line",
      model: "inventory-warehouses",
      header: {
        menu: ["Inventario", "Almacenes"],
      },
      buttons: {
        create: true,
        edit: true,
        destroy: false,
        import: false,
        export: false,
      },
    },
    dataTable: {
      body: {
        cols: [
          {
            name: "name",
            alias: "Nombre",
          },
          {
            name: "state",
            alias: "Estado",
            play: {
              type: "states",
              values: {
                0: "bg-danger-subtle text-danger",
                1: "bg-success-subtle text-success",
              },
              names: {
                0: "Inactivo",
                1: "Activo",
              },
            },
          },
        ],
      },
    },
  };

  /** METODOS DEL FILTER */
  const onChangePage = (page: number, type: string) => {
    switch (type) {
      case "prev":
        setFilterState({ ...filterState, page: filterState.page - 1 });
        fetchWarehouses(filterState.page - 1, filterState.text, true);
        break;

      case "current":
        setFilterState({ ...filterState, page: page });
        fetchWarehouses(page, filterState.text, true);
        break;
      case "next":
        setFilterState({ ...filterState, page: filterState.page + 1 });
        fetchWarehouses(filterState.page + 1, filterState.text, true);
        break;
      default:
        break;
    }
  };
  const onClearFilters = () => {
    setFilterState({ ...filterState, text: "" });
    fetchWarehouses(1, "", true);
  };

  const handleFilterChange = (newFilters: any) => {
    setFilterState(newFilters);
    fetchWarehouses(1, newFilters.text, false);
  };

  /** METODOS DE MODAL */
  const onCloseModalForm = () => {
    setStateModal(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setErrors({});
  };

  const onClickAddResource = (type: string) => {
    setDataModalResourceState({
      type: type,
      buttonSubmit: "Registrar",
      row: null,
      title: "Nuevo almacén",
      requirements: [],
      onCloseModalForm: onCloseModalForm,
      errosBackend: {},
    });
    setStateModal(true);
    setIsModalOpen(true);
  };

  const onClickEditResource = (row: any) => {
    setDataModalResourceState({
      type: "edit",
      buttonSubmit: "Editar",
      row: row,
      title: "Editar almacén",
      requirements: [],
      onCloseModalForm: onCloseModalForm,
      errosBackend: {},
    });
    setStateModal(true);
    setIsModalOpen(true);
  };

  /** METODOS DEL FORM RESOURCE */
  const onSubmitForm = async (warehouse: Warehouse, type: string) => {
    switch (type) {
      case "store":
        storeWarehouse(warehouse)
          .then(() => {
            onCloseModalForm();
            SweetAlert.success("Mensaje", "Almacen registrado correctamente.");
          })
          .catch((error) => {
            setErrors(error.response.data.errors);
          });
        break;
      case "edit":
        updateWarehouse(warehouse)
          .then(() => {
            onCloseModalForm();
            SweetAlert.success("Mensaje", "Almacen actualizado correctamente.");
          })
          .catch((error) => {
            setErrors(error.response.data.errors);
          });
        break;
      default:
        break;
    }
  };

  const onClickButtonPersonalizado = () => {};
  const onClickDeleteResource = () => {};

  return (
    <div className="main-body p-0">
      <div className="inner-wrapper">
        <div className="inner-main">
          <HeaderPage page={state.page} onModalResource={onClickAddResource} />
          <div className="inner-main-body">
            <div className="card card-featured card-featured-primary mb-3 mt-3">
              <div className="card-body">
                <Filter
                  onClearFilters={onClearFilters}
                  filterState={filterState}
                  onFilterChange={handleFilterChange}
                />
                <BodyPage
                  onChangeDelete={onClickDeleteResource}
                  onClickButtonPersonalizado={onClickButtonPersonalizado}
                  onChangeEdit={onClickEditResource}
                  onChangePage={onChangePage}
                  page={state.page}
                  table={state.dataTable}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Resource */}
      {isModalOpen && (
        <ModalComponent
          stateModal={stateModal}
          typeModal={"static"}
          title={dataModalResourceState.title}
          content={
            <FormWarehouse
              errors={errors}
              data={dataModalResourceState}
              onSubmitForm={onSubmitForm}
            />
          }
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default WarehouseIndex;
