/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
/**Select */
import Select from "react-select";
/**Models */
import { SaleItemRequest } from "../../../models/requests";
import { ErrorBackend } from "../../../utilities";
interface Props {
  items: SaleItemRequest[];
  onClickDeleteItem: (index: number) => void;
  handleInputChange: (index: number, field: string, value: string) => void;
  productSearch: any;
  getRequirements: any;
  clientId: string;
  products_not: any;
  setProductsNot: any;
  backendErrors: any;
}

interface OptionSelect {
  label: string;
  value: string;
  unit_value: string;
}

const SaleBodyDetail = (props: Props) => {
  //ReactSelect - Clientes
  const [optionsProducts, setOptionsProducts] = useState<OptionSelect[]>([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);

  const handleProductSearchChange = (value: string) => {
    if (value.length >= 3) {
      let productsNot = props.products_not.join(",");
      props
        .productSearch(value, props.clientId, productsNot)
        .then((response: any) => {
          setIsLoadingProducts(true);
          setOptionsProducts(response.data);
        })
        .finally(() => {
          setIsLoadingProducts(false);
        });
    }
  };

  const handleProductSelectChange = (
    selectedOption: OptionSelect,
    index: number,
  ) => {
    const updatedItems = [...props.items];
    updatedItems[index].selectedProduct = selectedOption;
    props.handleInputChange(index, "product_id", selectedOption.value);
    props.handleInputChange(index, "unit_value", selectedOption.unit_value);
    props.setProductsNot(props.products_not.concat(selectedOption.value));
    setOptionsProducts([]);
  };

  return (
    <div className="col-md-12">
      {props.items.map((item: SaleItemRequest, index: number) => (
        <div key={index} className="row mt-2">
          {/*PRODUCTO*/}
          {item.view ? (
            <div className="col-md-4">
              <Select
                isLoading={isLoadingProducts}
                placeholder={"Buscar producto..."}
                options={optionsProducts}
                onInputChange={handleProductSearchChange}
                noOptionsMessage={() => "No se encontraron resultados"}
                onChange={(selectedOption) =>
                  handleProductSelectChange(selectedOption, index)
                } // Handle Select change
                value={item.selectedProduct}
              />
              <ErrorBackend
                errorsBackend={props.backendErrors}
                name={`sale.items.${index}.product_id`}
              />
            </div>
          ) : (
            <div className="col-md-4">
              <input
                type="text"
                disabled
                readOnly
                value={item.product_name}
                className="form-control form-control-sm"
              />
            </div>
          )}
          {/*DETALLE ADICIONAL*/}
          <div className="col-md-2">
            <input
              value={item.additional_detail}
              type="text"
              className="form-control form-control-sm todo-mayuscula"
              onChange={(e) =>
                props.handleInputChange(
                  index,
                  "additional_detail",
                  e.target.value,
                )
              }
            />
          </div>

          {/*CANTIDAD*/}
          <div className="col-md-1">
            <input
              value={item.quantity}
              type="number"
              className="form-control form-control-sm"
              onChange={(e) =>
                props.handleInputChange(index, "quantity", e.target.value)
              }
            />
          </div>

          {/*TIPO DE IGV*/}
          <div className="col-md-1">
            <select
              onChange={(e) =>
                props.handleInputChange(index, "igv_type_id", e.target.value)
              }
              value={item.igv_type_id}
              className="form-control form-control-sm form-select"
            >
              {props.getRequirements?.igv_types.map((item: any) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          {/*VALOR UNITARIO*/}
          <div className="col-md-1">
            <input
              value={item.unit_value.toFixed(2)}
              type="text"
              className="form-control form-control-sm"
              readOnly
              disabled
            />
          </div>

          {/*SUB TOTAL*/}
          <div className="col-md-1">
            <input
              value={item.sub_total.toFixed(2)}
              readOnly
              disabled
              type="text"
              className="form-control form-control-sm"
            />
          </div>

          {/*TOTAL*/}
          <div className="col-md-1">
            <input
              value={item.total.toFixed(2)}
              readOnly
              disabled
              type="text"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-md-1">
            {/* <a className="text-primary me-2" role="button">
              <i className="ri-menu-line font-size-18"></i>
            </a> */}
            <a
              onClick={() => props.onClickDeleteItem(index)}
              className="text-danger"
              role="button"
            >
              <i className="ri-delete-bin-line font-size-18"></i>
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};
export default SaleBodyDetail;
