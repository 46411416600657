/* eslint-disable jsx-a11y/anchor-is-valid */
interface NavTabsProps {
  activeTab: string;
  handleTabChange: (tab: string) => void;
  tipoTransporte: string;
  errors: any;
  itemsCount: number;
}

const NavTabs = (props: NavTabsProps) => {
  const validateBackend = (keysToCheck: string[]) => {
    return keysToCheck.some((key) => props.errors && props.errors[key]);
  };

  const validateItems = () => {
    const hasErrors = Array.from({ length: props.itemsCount }).some((_, index) => {
      return props.errors && (props.errors[`despatch.items.${index}.product_id`] || props.errors[`despatch.items.${index}.cantidad`]);
    });
    return hasErrors;
  };

  return (
    <ul className="nav nav-tabs nav-justified nav-guia-remision">
      <li className="nav-item">
        <a
          role="button"
          className={`nav-link ${props.activeTab === 'tab1' ? 'active' : ''} ${
            validateBackend([
              'despatch.datosTraslado.numeroBultos',
              'despatch.datosTraslado.pesoBrutoTotal',
              'despatch.datosTraslado.pesoUnidadMedida',
            ])
              ? 'tab-error'
              : ''
          }`}
          onClick={() => props.handleTabChange('tab1')}
        >
          <span className="d-none d-sm-block">Datos del traslado</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          role="button"
          className={`nav-link ${props.activeTab === 'tab2' ? 'active' : ''} ${
            validateBackend([
              'despatch.datosTransportista.tipoDocumento',
              'despatch.datosTransportista.numeroDocumento',
              'despatch.datosTransportista.denominacion',
              'despatch.datosTransportista.numeroPlaca',
            ]) ? 'tab-error' : ''
          }`}
          onClick={() => props.handleTabChange('tab2')}
        >
          <span className="d-none d-sm-block">Datos del transportista</span>
        </a>
      </li>
      {props.tipoTransporte === '02' && (
        <li className="nav-item">
          <a
            role="button"
            className={`nav-link ${props.activeTab === 'tab3' ? 'active' : ''} ${
              validateBackend([
                'despatch.datosChofer.tipoDocumento',
                'despatch.datosChofer.numeroDocumento',
                'despatch.datosChofer.nombres',
                'despatch.datosChofer.apellidos',
                'despatch.datosChofer.numeroLicencia',
              ])
                ? 'tab-error'
                : ''
            }`}
            onClick={() => props.handleTabChange('tab3')}
          >
            <span className="d-none d-sm-block">Datos del conductor</span>
          </a>
        </li>
      )}
      <li className="nav-item">
        <a
          role="button"
          className={`nav-link ${props.activeTab === 'tab4' ? 'active' : ''} ${
            validateBackend([
              'despatch.puntoPartida.ubigeo',
              'despatch.puntoPartida.direccion',
              'despatch.puntoLlegada.ubigeo',
              'despatch.puntoLlegada.direccion',
            ])
              ? 'tab-error'
              : ''
          }`}
          onClick={() => props.handleTabChange('tab4')}
        >
          <span className="d-none d-sm-block">Datos del destino</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          role="button"
          className={`nav-link ${props.activeTab === 'tab5' ? 'active' : ''} ${validateItems() ? ' tab-error' : ''}
         ${validateBackend(['despatch.items']) ? ' tab-error' : ''} }`}
          onClick={() => props.handleTabChange('tab5')}
        >
          <span className="d-none d-sm-block">Productos</span>
        </a>
      </li>
    </ul>
  );
};

export default NavTabs;
