import { useState, useEffect } from "react";
/**Services */
import * as authService from "../services/auth.service";
/**Models */
import { User } from "../models";

export function useCheckAuth() {

  const [userAuth, setUserAuth] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<object | null>(null);

  useEffect(() => {
    authService
      .checkAuth()
      .then((response) => setUserAuth(response))
      .catch((error) => setError(error.response))
      .finally(() => setLoading(false));
  }, []);

  return { userAuth, loading, error};
}
