import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
/**Models */
import { DataTable, Presentation } from '../models';
/**Services */
import * as presentationsService from '../services/presentations.service';
/**Hooks */
import { setLoading } from '../redux/states/loading.slice';
import { dataTable_setAlls, dataTable_clearAll, dataTable_addResource, dataTable_updateResource } from '../redux/states/dataTable.slice';

export function usePresentations() {
  const dispatch = useDispatch();
  const [presentations, setPresentations] = useState<DataTable | null>(null);

  //GET
  const fetchPresentations = useCallback(
    (page: number, text: string, loading: boolean) => {
      dispatch(setLoading(loading));
      presentationsService
        .getPresentations(page, text)
        .then((response) => {
          dispatch(dataTable_setAlls(response));
          setPresentations(response); // Actualiza el estado local si es necesario
        })
        .catch((error) => error)
        .finally(() => {
          dispatch(setLoading(false));
        });
    },
    [dispatch],
  );

  useEffect(() => {
    const resetData = () => {
      dispatch(dataTable_clearAll());
      setPresentations(null); // Borra el estado local si es necesario
    };
    fetchPresentations(1, '', true); // Llamada inicial al cargar el componente

    return () => {
      resetData(); // Esto se ejecutará cuando el componente se desmonte
    };
  }, [fetchPresentations, dispatch]);

  //STORE
  const storePresentation = async (presentation: Presentation) => {
    dispatch(setLoading(true));
    try {
      const response = await presentationsService.storePresentation(presentation);
      dispatch(dataTable_addResource(response.presentation));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }

  //UPDATE
  const updatePresentation = async (presentation: Presentation) => {
    dispatch(setLoading(true));
    try {
      const response = await presentationsService.updatePresentation(presentation);
      dispatch(dataTable_updateResource(response.presentation));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }

  return { presentations, fetchPresentations, storePresentation, updatePresentation };
}
