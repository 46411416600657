import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
/**Models */
import { DataTable, Brand } from '../models';
/**Services */
import * as brandsService from '../services/brands.service';
/**Redux */
import { setLoading } from '../redux/states/loading.slice';
import { dataTable_setAlls, dataTable_clearAll, dataTable_addResource, dataTable_updateResource } from '../redux/states/dataTable.slice';

export function useBrands() {
  const dispatch = useDispatch();
  const [brands, setBrands] = useState<DataTable | null>(null);

  //GET
  const fetchBrands = useCallback(
    (page: number, text: string, loading: boolean) => {
      dispatch(setLoading(loading));
      brandsService
        .getBrands(page, text)
        .then((response) => {
          dispatch(dataTable_setAlls(response));
          setBrands(response); // Actualiza el estado local si es necesario
        })
        .catch((error) => error)
        .finally(() => {
          dispatch(setLoading(false));
        });
    },
    [dispatch],
  );

  useEffect(() => {
    const resetData = () => {
      dispatch(dataTable_clearAll());
      setBrands(null); // Borra el estado local si es necesario
    };
    fetchBrands(1, '', true); // Llamada inicial al cargar el componente

    return () => {
      resetData(); // Esto se ejecutará cuando el componente se desmonte
    };
  }, [fetchBrands, dispatch]);

  //STORE
  const storeBrand = async (brand: Brand) => {
    dispatch(setLoading(true));
    try {
      const response = await brandsService.storeBrand(brand);
      dispatch(dataTable_addResource(response.brand));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }

  //UPDATE
  const updateBrand = async (brand: Brand) => {
    dispatch(setLoading(true));
    try {
      const response = await brandsService.updateBrand(brand);
      dispatch(dataTable_updateResource(response.brand));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }

  return { brands, fetchBrands, storeBrand, updateBrand };
}
