import api from './api';
/**Models */
import { Login } from '../models';
import { LoginResponse, SuccessResponse } from '../models/responses';

export const checkAuth = async () => {
  try {
    const response = await api.get<SuccessResponse>('/auth/check-auth');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const login = async (login: Login) => {
  try {
    const response = await api.post<LoginResponse>('/auth/login', login);
    return response;
  } catch (error) {
    throw error;
  }
};

export const logout = async () => {
  try {
    const response = await api.get('/auth/logout');
    return response;
  } catch (error) {
    throw error;
  }
};
