import SimpleBarReact from 'simplebar-react';
import { NavLink, useLocation } from 'react-router-dom';
/**Resources */
import Menu from '../resources/menu';
import CanCheck from '../resources/can';
import messages from '../resources/messages';

interface Props {
  onValidateCollapse: () => void;
}

const showSection = (permissions: any[]) => {
  let rpta = false;
  permissions.forEach((permission) => {
    if (CanCheck(permission)) {
      rpta = true;
    }
  });
  return rpta;
};

export const Sidebar = (props: Props) => {
  const location = useLocation();
  const collapse: { [key: string]: boolean } = {};

  const buildCollapseList = () => {
    Menu.forEach(({ name, permission, permissions, submenu }) => {
      let count = 0;

      !submenu && CanCheck(permission) && (collapse[name] = false);

      if (submenu && showSection(permissions ?? [])) {
        submenu.forEach(({ path }) => {
          count = path === location.pathname ? count + 1 : count;
        });
        collapse[name] = count === 0 ? false : true;
      }
    });
  };

  buildCollapseList();

  const validateCollapseName = (name: string) => {
    return collapse[name];
  };

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <NavLink to="/home" className="logo-auth">
          <img src="/images/icon.png" alt="Logo" id="main-logo" />
          {messages.NAME_APP}
        </NavLink>
        <button onClick={() => props.onValidateCollapse()} className="nav-link nav-icon rounded-circle ml-auto" data-toggle="sidebar">
          X
        </button>
      </div>

      <SimpleBarReact className="sidebar-body" id="sidebar-menu">
        <ul className="metismenu list-unstyled">
          <li className="nav-label">MENU</li>
          {Menu.map((menuItem, index) => (
            <li className="nav-item" key={index}>
              {!menuItem.submenu && CanCheck(menuItem.permission) && (
                <NavLink to={menuItem.path} className="nav-link has-icon">
                  <i className={menuItem.icon}></i>
                  <span className="ml-1">{menuItem.name}</span>
                </NavLink>
              )}
              {menuItem.submenu && menuItem.submenu.length > 0 && showSection(menuItem.permissions ?? []) && (
                <>
                  <a
                    className={
                      validateCollapseName(menuItem.name ?? '')
                        ? 'nav-link has-icon treeview-toggle has-arrow active'
                        : 'nav-link has-icon treeview-toggle has-arrow collapsed'
                    }
                    data-bs-toggle="collapse"
                    href={'#collapseExample_' + index}
                    role="button"
                    aria-expanded={validateCollapseName(menuItem.name ?? '')}
                    aria-controls="collapseExample"
                  >
                    <i className={menuItem.icon}></i>
                    <span className="ml-1">{menuItem.name}</span>
                  </a>
                  <ul
                    className={validateCollapseName(menuItem.name ?? '') ? 'nav sub-menu collapse show' : 'nav sub-menu nav sub-menu collapse'}
                    id={'collapseExample_' + index}
                  >
                    {menuItem.submenu.map(
                      (subMenuItem, subIndex) =>
                        CanCheck(subMenuItem.permission) && (
                          <li className="nav-item" key={subIndex}>
                            <NavLink className="nav-link" to={subMenuItem.path}>
                              <span className="ml-1">{subMenuItem.name}</span>
                            </NavLink>
                          </li>
                        ),
                    )}
                  </ul>
                </>
              )}
            </li>
          ))}
        </ul>
      </SimpleBarReact>
    </div>
  );
};

export default Sidebar;
