const ItemsHeaderComponent = () => {
  return (
    <div className="row">
      <div className="col-md-3">
        <p className="mb-0">Producto</p>
      </div>
      <div className="col-md-1">
        <p className="mb-0">Datos</p>
      </div>
      <div className="col-md-1">
        <p className="mb-0">Stock actual</p>
      </div>
      <div className="col-md-2">
        <p className="mb-0">Tipo de ajuste</p>
      </div>
      <div className="col-md-1">
        <p className="mb-0">Cantidad</p>
      </div>
      <div className="col-md-1">
        <p className="mb-0">Precio inicial</p>
      </div>
      <div className="col-md-1">
        <p className="mb-0">Cantidad final</p>
      </div>
      <div className="col-md-1">
        <p className="mb-0">Total ajustado</p>
      </div>
      <div className="col-md-1">
        <p className="mb-0">Acción</p>
      </div>
    </div>
  );
};
export default ItemsHeaderComponent;
