export const ErrorBackendApi = (props: { errorsBackend: any; name: string }) => {
  return props.errorsBackend && props.errorsBackend[props.name] ? (
    <div className="invalid-feedback">
      {props.errorsBackend[props.name].map((error: string, index: number) => (
        <p key={index}>{error}</p>
      ))}
    </div>
  ) : null;
};

export default ErrorBackendApi;
