/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
/**Select */
import Select from "react-select";
/**Models */
import { InventorySettingItemRequest } from "../../../../models/requests";
import { ErrorBackend } from "../../../../utilities";
interface Props {
  items: InventorySettingItemRequest[];
  productSearch: any;
  handleInputChange: (index: number, field: string, value: string) => void;
  requirements: any;
  onClickDeleteItem: (index: number) => void;
  warehouseId: string;
  products_not: any;
  setProductsNot: any;
  backendErrors: any;
}
interface OptionSelect {
  label: string;
  value: string;
  unit_value: string;
  purchase_price: string;
  stock: string;
}
const ItemsBodyComponent = (props: Props) => {
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [optionsProducts, setOptionsProducts] = useState<OptionSelect[]>([]);

  const handleProductSearchChange = (value: string) => {
    if (value.length >= 3) {
      let productsNot = props.products_not.join(",");
      props
        .productSearch(value, props.warehouseId, productsNot)
        .then((response: any) => {
          setIsLoadingProducts(true);
          setOptionsProducts(response.data);
        })
        .finally(() => {
          setIsLoadingProducts(false);
        });
    }
  };

  const handleProductSelectChange = (
    selectedOption: OptionSelect,
    index: number,
  ) => {
    const updatedItems = [...props.items];
    updatedItems[index].selectedProduct = selectedOption;
    props.handleInputChange(index, "stock", selectedOption.stock);
    props.handleInputChange(
      index,
      "warehouse_product_id",
      selectedOption.value,
    );
    props.handleInputChange(
      index,
      "purchase_price",
      selectedOption.purchase_price,
    );
    props.setProductsNot(props.products_not.concat(selectedOption.value));
    setOptionsProducts([]);
  };

  return (
    <div className="col-md-12">
      {props.items.map((item: InventorySettingItemRequest, index: number) => (
        <div key={index} className="row mt-2">
          {/*PRODUCTO*/}
          <div className="col-md-3">
            <Select
              isLoading={isLoadingProducts}
              placeholder={"Buscar producto..."}
              options={optionsProducts}
              onInputChange={handleProductSearchChange}
              noOptionsMessage={() => "No se encontraron resultados"}
              onChange={(selectedOption) =>
                handleProductSelectChange(selectedOption, index)
              } // Handle Select change
              value={item.selectedProduct}
            />
            <ErrorBackend
              errorsBackend={props.backendErrors}
              name={`inventory.items.${index}`}
            />
            <ErrorBackend
              errorsBackend={props.backendErrors}
              name={`inventory.items.${index}.warehouse_product_id`}
            />
          </div>
          {/*DATOS*/}
          <div className="col-md-1">
            <input
              type="text"
              className="form-control form-control-sm todo-mayuscula"
              value={item.data}
              onChange={(e) =>
                props.handleInputChange(index, "data", e.target.value)
              }
            />
          </div>
          {/*STOCK ACTUAL*/}
          <div className="col-md-1">
            <input
              value={item.stock}
              type="text"
              className="form-control form-control-sm"
              readOnly
              disabled
            />
          </div>
          {/*TIPO DE AJUSTE*/}
          <div className="col-md-2">
            <select
              className="form-control form-control-sm form-select"
              value={item.type_setting_id}
              name="type_setting_id"
              id="type_setting_id"
              onChange={(e) =>
                props.handleInputChange(
                  index,
                  "type_setting_id",
                  e.target.value,
                )
              }
            >
              <option value="">Seleccionar...</option>
              {props.requirements.type_settings.map((item: any) => (
                <option key={item.code} value={item.code}>
                  {item.name}
                </option>
              ))}
            </select>
            <ErrorBackend
              errorsBackend={props.backendErrors}
              name={`inventory.items.${index}.type_setting_id`}
            />
          </div>
          {/*CANTIDAD*/}
          <div className="col-md-1">
            <input
              type="number"
              name="quantity"
              id="quantity"
              className="form-control form-control-sm"
              disabled={item.type_setting_id === "" ? true : false}
              value={item.quantity}
              onChange={(e) =>
                props.handleInputChange(index, "quantity", e.target.value)
              }
            />
            <ErrorBackend
              errorsBackend={props.backendErrors}
              name={`inventory.items.${index}.quantity`}
            />
          </div>

          {/*COSTO INICIAL*/}
          <div className="col-md-1">
            <input
              type="number"
              name="purchase_price"
              id="purchase_price"
              className="form-control form-control-sm"
              value={item.purchase_price}
              onChange={(e) =>
                props.handleInputChange(index, "purchase_price", e.target.value)
              }
            />
            <ErrorBackend
              errorsBackend={props.backendErrors}
              name={`inventory.items.${index}.purchase_price`}
            />
          </div>
          {/*CANTIDAD FINAL*/}
          <div className="col-md-1">
            <input
              type="text"
              className="form-control form-control-sm"
              value={item.final_quantity}
              readOnly
              disabled
            />
          </div>
          {/*TOTAL AJUSTADO*/}
          <div className="col-md-1">
            <input
              type="text"
              className="form-control form-control-sm"
              value={item.adjusted_total.toFixed(2)}
              readOnly
              disabled
            />
          </div>
          {/*ACCIONES*/}
          <div className="col-md-1">
            <a
              onClick={() => props.onClickDeleteItem(index)}
              className="text-danger"
              role="button"
            >
              <i className="ri-delete-bin-line font-size-18"></i>
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ItemsBodyComponent;
