import { ChangeEvent, useState } from 'react';

interface FormProps {
  formData: any;
  onChange: any;
  onCloseModalForm: () => void;
}

const OrdenCompra = (props: FormProps) => {
  const [placa, setPlaca] = useState<string>(props.formData.orden_purchase);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPlaca(value);
    props.onChange(name, value);
  };

  return (
    <div className="form form-horizontal form-scrollable">
      <div className="modal-body">
        <div className="form-row row">
          {/*ORDEN DE COMPRA */}
          <div className="form-group col-md-12">
            <label htmlFor="orden_purchase" className="form-label">
              Número de orden de compra/servicio
            </label>
            <input
              id="orden_purchase"
              name="orden_purchase"
              value={placa}
              onChange={handleInputChange}
              type="text"
              className="todo-mayuscula form-control form-control-sm"
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" onClick={() => props.onCloseModalForm()} className="btn btn-sm btn-secondary">
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default OrdenCompra;
