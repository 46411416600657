import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
/**Models */
import { DataTable } from "../models";
/**Services */
import * as salesService from "../services/sales.service";
import * as clientsService from "../services/clients.service";
import * as productsService from "../services/products.service";
/**Redux */
import { setLoading } from "../redux/states/loading.slice";
import {
  dataTable_setAlls,
  dataTable_clearAll,
  dataTable_updateResource,
} from "../redux/states/dataTable.slice";
import { SaleRequest, CancelRequest } from "../models/requests";

export function useSales() {
  const dispatch = useDispatch();
  const [sales, setSales] = useState<DataTable | null>(null);

  //GET
  const fetchSales = useCallback(
    (
      page: number,
      text: string,
      type_document_id: string,
      date_initial: string,
      date_final: string,
      loading: boolean,
      sales: string,
    ) => {
      dispatch(setLoading(loading));
      salesService
        .getSales(page, text, type_document_id, date_initial, date_final, sales)
        .then((response) => {
          dispatch(dataTable_setAlls(response));
          setSales(response); // Actualiza el estado local si es necesario
        })
        .catch((error) => error)
        .finally(() => {
          dispatch(setLoading(false));
        });
    },
    [dispatch],
  );

  useEffect(() => {
    const resetData = () => {
      dispatch(dataTable_clearAll());
      setSales(null); // Borra el estado local si es necesario
    };

    return () => {
      resetData(); // Esto se ejecutará cuando el componente se desmonte
    };
  }, [dispatch]);

  const getRequirements = async (type: string) => {
    dispatch(setLoading(true));
    try {
      const response = await salesService.getRequirements(type);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const clientSearch = async (text: string, type: string) => {
    try {
      const response = await clientsService.searchClient(text, type);
      return response;
    } catch (error) {
      throw error;
    } finally {
    }
  };

  const productSearch = async (text: string, model_id: string, products_not: string, filter: string) => {
    try {
      const response = await productsService.searchProduct(text, model_id, products_not, filter);
      return response;
    } catch (error) {
      throw error;
    } finally {
    }
  };

  //STORE
  const storeSale = async (sale: SaleRequest) => {
    dispatch(setLoading(true));
    try {
      const response = await salesService.storeSale(sale);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  //CANCEL
  const cancelSale = async (sale_id: string, cancel: CancelRequest) => {
    dispatch(setLoading(true));
    try {
      const response = await salesService.cancelSale(sale_id, cancel);
      dispatch(dataTable_updateResource(response.sale));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  //SEND EMAIL
  const sendEmail = async (sale_id: string, email: string) => {
    dispatch(setLoading(true));
    try {
      const response = await salesService.sendEmail(sale_id, email);
      dispatch(dataTable_updateResource(response.data));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getSale = async (uuid: string, mode: string) => {
    dispatch(setLoading(true));
    try {
      const response = await salesService.getSale(uuid, mode);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  return {
    getRequirements: getRequirements,
    clientSearch,
    productSearch,
    storeSale,
    sales,
    fetchSales,
    cancelSale,
    sendEmail,
    getSale,
  };
}
