import { useEffect, useState } from "react";
/**Hooks */
import { useInventories } from "../../../hooks";
import React from "react";

/* eslint-disable jsx-a11y/anchor-is-valid */
export const KardexPage = () => {
  const { getRequirements, getInventories } = useInventories();
  const [loaded, setLoaded] = useState(false);
  const [requirements, setRequirements] = useState<any>([]);
  const [warehouseId, setWarehouseId] = useState<any>("");
  const [data, setData] = useState<any>([]);

  const onChangeSelect = (e: any) => {
    setWarehouseId(e.target.value);
    getInventories("get", e.target.value).then((res: any) => {
      setData(res.data);
    });
  };

  const onClickKardex = () => {
    window.open(
      `${process.env.REACT_APP_LINK_BACKEND}/reports/inventories/kardex?warehouse_id=${warehouseId}`,
      "_blank",
    );
  };

  const onClickStock = () => {
    window.open(
      `${process.env.REACT_APP_LINK_BACKEND}/reports/inventories/stock`,
      "_blank",
    );
  }

  //DATA INICIAL
  useEffect(() => {
    const dataInicial = () => {
      getRequirements().then((res: any) => {
        setRequirements(res);
        setLoaded(true);
      });
    };

    if (!loaded) {
      dataInicial();
    }
  }, [loaded, getRequirements]);

  return (
    <div className="main-body p-0">
      <div className="inner-wrapper">
        <div className="inner-main">
          <div
            className="inner-main-header"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <h5 style={{ fontWeight: "bold" }} className="me-3">
              KARDEX
            </h5>
            <div className="ms-auto">
              <ol className="breadcrumbs me-3">
                <li>
                  <a role="button">
                    <i className="ri-box-1-line"></i>
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a role="button">KARDEX</a>
                </li>
              </ol>
            </div>
          </div>

          <div className="inner-main-body">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="row justify-content-center">
                  <label
                    htmlFor="almacen"
                    style={{ marginTop: "0.2rem" }}
                    className="col-md-1 form-label text-end"
                  >
                    Almacen
                  </label>
                  <div className="col-md-3">
                    <select
                      onChange={onChangeSelect}
                      name="almacen"
                      id="almacen"
                      value={warehouseId}
                      className="form-control form-control-sm form-select"
                    >
                      <option value="" disabled>
                        Seleccionar
                      </option>
                      {requirements?.warehouses?.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-2">
                    <button
                      disabled={warehouseId === ""}
                      className="btn btn-primary btn-sm btn-block"
                      onClick={onClickKardex}
                    >
                      Kardex
                    </button>
                  </div>
                  <div className="col-md-2">
                    <button
                      disabled={warehouseId === ""}
                      className="btn btn-success btn-sm btn-block"
                      onClick={onClickStock}
                    >
                      Stock productos
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <table
                  role="table"
                  className="table table-bordered table-kardex"
                >
                  <thead>
                    <tr>
                      <th rowSpan={2}>FECHA</th>
                      <th rowSpan={2}>DOCUMENTO</th>
                      <th rowSpan={2}>TIPO OPE.</th>
                      <th colSpan={3}>INGRESOS</th>
                      <th colSpan={3}>SALIDAS</th>
                      <th colSpan={3}>SALDO</th>
                    </tr>
                    <tr>
                      <th>Cantidad</th>
                      <th>Costo Unit.</th>
                      <th>Costo Total</th>
                      <th>Cantidad</th>
                      <th>Costo Unit.</th>
                      <th>Costo Total</th>
                      <th>Cantidad</th>
                      <th>Costo Unit.</th>
                      <th>Costo Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item: any) => (
                      <React.Fragment key={item.id}>
                        <tr className="body-product-kardex">
                          <td colSpan={12} align="center">
                            <strong>{item.product_name}</strong>
                          </td>
                        </tr>
                        {item.inventories.map((kardex: any) => (
                          <tr key={kardex.id}>
                            <td>{kardex.date}</td>
                            <td>{kardex.document}</td>
                            <td>{kardex.operation_name}</td>
                            {kardex.operation_name === "ENTRADA" && (
                              <>
                                <td>{kardex.quantity}</td>
                                <td>S/{kardex.price}</td>
                                <td>
                                  S/
                                  {(kardex.quantity * kardex.price).toFixed(2)}
                                </td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>{kardex.stock_current}</td>
                                <td>S/{kardex.price}</td>
                                <td>
                                  S/
                                  {(
                                    kardex.stock_current * kardex.price
                                  ).toFixed(2)}
                                </td>
                              </>
                            )}
                            {kardex.operation_name === "SALIDA" && (
                              <>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>{kardex.quantity}</td>
                                <td>S/{kardex.price}</td>
                                <td>
                                  S/
                                  {(kardex.quantity * kardex.price).toFixed(2)}
                                </td>
                                <td>{kardex.stock_current}</td>
                                <td>S/{kardex.price}</td>
                                <td>
                                  S/{(
                                    kardex.stock_current * kardex.price
                                  ).toFixed(2)}
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                        <tr className="footer-kardex">
                          <td colSpan={3}>***TOTAL:</td>
                          <td colSpan={3}>
                            {item.inventories
                              .filter(
                                (kardex: { operation_name: string }) =>
                                  kardex.operation_name === "ENTRADA",
                              )
                              .reduce(
                                (total: any, kardex: { quantity: any }) =>
                                  total + kardex.quantity,
                                0,
                              )}
                          </td>
                          <td colSpan={3}>
                            {item.inventories
                              .filter(
                                (kardex: { operation_name: string }) =>
                                  kardex.operation_name === "SALIDA",
                              )
                              .reduce(
                                (total: any, kardex: { quantity: any }) =>
                                  total + kardex.quantity,
                                0,
                              )}
                          </td>
                          <td colSpan={3}>
                            {item.inventories
                              .filter(
                                (kardex: { operation_name: string }) =>
                                  kardex.operation_name === "ENTRADA",
                              )
                              .reduce(
                                (total: any, kardex: { quantity: any }) =>
                                  total + kardex.quantity,
                                0,
                              ) -
                              item.inventories
                                .filter(
                                  (kardex: { operation_name: string }) =>
                                    kardex.operation_name === "SALIDA",
                                )
                                .reduce(
                                  (total: any, kardex: { quantity: any }) =>
                                    total + kardex.quantity,
                                  0,
                                )}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KardexPage;
