import api from './api';
/**Models */
import { QuoteResponse, DetailQuoteResponse } from '../models/responses';
import { QuoteRequest } from '../models/requests';
import { DataTable } from '../models';

export const storeQuote = async (quote: QuoteRequest) => {
  try {
    const response = await api.post<QuoteResponse>('/quotes', { quote: quote });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateQuote = async (quote: QuoteRequest) => {
  try {
    const response = await api.update<QuoteResponse>(`/quotes/${quote.id}`, { quote: quote });
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteQuote = async (id: string) => {
  try {
    const response = await api.destroy(`/quotes/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getQuotes = async (page: number, text: string) => {
  try {
    const response = await api.get<DataTable>(
      `/quotes?page=${page}&text=${text}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getQuote = async (uuid: string) => {
  try {
    const response = await api.get<DetailQuoteResponse>(`/quotes/${uuid}`);
    return response;
  } catch (error) {
    throw error;
  }
}
