import { ChangeEvent } from 'react';

interface Props {
  onFilterChange: (filters: any) => void;
  onClearFilters: () => void;
  filterState: any;
  requirements: any;
}

export const Filter = (props: Props) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedFilters = { ...props.filterState, text: event.target.value };
    props.onFilterChange(updatedFilters);
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const updatedFilters = { ...props.filterState, [event.target.name]: event.target.value };
    props.onFilterChange(updatedFilters);
  };

  return (
    <div className="mb-3 row">
      <div className="col-md-2">
        <label htmlFor="category">Categoría</label>
        <select
          name="category"
          value={props.filterState.category}
          onChange={handleSelectChange}
          className="form-control form-control-sm form-select"
          id="category"
        >
          <option value="">Todas</option>
          {props.requirements && props.requirements.categories.map((category: any) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
      </div>
      <div className="col-md-2">
        <label htmlFor="brand">Marca</label>
        <select
          name="brand"
          value={props.filterState.brand}
          onChange={handleSelectChange}
          className="form-control form-control-sm form-select"
          id="brand"
        >
          <option value="">Todas</option>
          {props.requirements && props.requirements.brands.map((brand: any) => (
            <option key={brand.id} value={brand.id}>
              {brand.name}
            </option>
          ))}
        </select>
      </div>
      <div className="col-md-2">
        <label htmlFor="presentation">Presentación</label>
        <select
          name="presentation"
          value={props.filterState.presentation}
          onChange={handleSelectChange}
          className="form-control form-control-sm form-select"
          id="presentation"
        >
          <option value="">Todas</option>
          {props.requirements && props.requirements.presentations.map((presentation: any) => (
            <option key={presentation.id} value={presentation.id}>
              {presentation.name}
            </option>
          ))}
        </select>
      </div>
      <div className="col-md-3">
        <label htmlFor="text">Nombre</label>
        <div role="group" className="btn-group">
          <input
            autoComplete='off'
            name="text"
            value={props.filterState.text}
            onChange={handleInputChange}
            type="text"
            className="form-control form-control-sm"
            id="text"
          />
          <button onClick={() => props.onClearFilters()} className="btn btn-sm btn-primary">
            Limpiar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
