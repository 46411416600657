import { DespatchRequest } from '../models/requests';
import { DespatchResponse } from '../models/responses';
import api from './api';
import { DataTable } from '../models';

export const getRequirements = async () => {
  try {
    const response = await api.get('/despatches/requirements');
    return response;
  } catch (error) {
    throw error;
  }
};

export const getUbigeos = async (text: string) => {
  try {
    const response = await api.get('/despatches/ubigeos?text=' + text);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getDocuments = async (text: string, clientId:string, type: string) => {
  try {
    const response = await api.get('/despatches/documents?text=' + text + '&client_id=' + clientId + '&type=' + type);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getPlacas = async (text: string) => {
  try {
    const response = await api.get('/despatches/placas?text=' + text);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getLicencias = async (text: string) => {
  try {
    const response = await api.get('/despatches/licencias?text=' + text);
    return response;
  } catch (error) {
    throw error;
  }
}

export const storeDespatch = async (despatch: DespatchRequest) => {
  try {
    const response = await api.post<DespatchResponse>('/despatches', {despatch : despatch});
    return response;
  } catch (error) {
    throw error;
  }
}

export const getDespatches = async (page: number, text: string) => {
  try {
    const response = await api.get<DataTable>(
      `/despatches?page=${page}&text=${text}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};
