import { useState, ChangeEvent } from 'react';
/**Select */
import Select from 'react-select';
/**Utilities */
import { ErrorBackend } from '../../../utilities';

interface Props {
  ubigeos: any;
  puntoPartida: any;
  puntoLlegada: any;
  handleSelectChangeValue: (key: any, fieldName: string, value: any) => void;
  handleInputChangeValue: (key: any, fieldName: string, value: string) => void;
  handleSearchUbigeo: (key: string, ubigeo: string, selectedUbigeo: any) => void;
  errors: any;
}
interface OptionSelect {
  label: string;
  value: string;
}


const DatosDestino = (props: Props) => {
  const [isLoadingPartida, setIsLoadingPartida] = useState(false);
  const [optionsPartida, setOptionsPartida] = useState<OptionSelect[]>([]);

  const handleSearchUbigeo = (value: string) => {
    if (value.length >= 3) {
      props
        .ubigeos(value)
        .then((response: any) => {
          setIsLoadingPartida(true);
          setOptionsPartida(response.data);
        })
        .finally(() => {
          setIsLoadingPartida(false);
        });
    }
  };

  const handleUbigeoSelectChange = (selectedOption: OptionSelect, key: string) => {
    setOptionsPartida([]);
    props.handleSearchUbigeo(key, selectedOption.value, selectedOption);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    props.handleInputChangeValue(key, event.target.name, event.target.value);
  };

  const hasError = (propertyName: string) => {
    return props.errors && props.errors[propertyName];
  };

  return (
    <>
      <div className="row border-sales">
        <div className="title-items">Punto de partida</div>
        {/*Ubigeo*/}
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="puntoPartida.ubigeo" className="form-label">
              Ubigeo<span className="text-danger">*</span>
            </label>
            <Select
              id="puntoPartida.ubigeo"
              isLoading={isLoadingPartida}
              placeholder={'Buscar punto de partida...'}
              options={optionsPartida}
              onInputChange={handleSearchUbigeo}
              noOptionsMessage={() => 'No se encontraron resultados'}
              value={props.puntoPartida.selectedUbigeo}
              onChange={(selectedOption) => handleUbigeoSelectChange(selectedOption, 'puntoPartida')}
            />
            <ErrorBackend errorsBackend={props.errors} name="despatch.puntoPartida.ubigeo" />
          </div>
        </div>
        {/*Dirección completa*/}
        <div className="col-md-8">
          <div className="form-group">
            <label htmlFor="puntoPartida.direccion" className="form-label">
              Dirección del punto de partida<span className="text-danger">*</span>
            </label>
            <input
              autoComplete="off"
              onChange={(e) => handleInputChange(e, 'puntoPartida')}
              name="direccion"
              id="puntoPartida.direccion"
              value={props.puntoPartida.direccion}
              type="text"
              className={`todo-mayuscula form-control form-control-sm${
                hasError('despatch.puntoPartida.direccion') ? ' is-invalid' : ''
              }`}
            />
            <ErrorBackend errorsBackend={props.errors} name="despatch.puntoPartida.direccion" />
          </div>
        </div>
      </div>
      <div className="row border-sales mt-4">
        <div className="title-items">Punto de llegada</div>
        {/*Ubigeo*/}
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="puntoLlegada.ubigeo" className="form-label">
              Ubigeo<span className="text-danger">*</span>
            </label>
            <Select
              id="puntoLlegada.ubigeo"
              isLoading={isLoadingPartida}
              placeholder={'Buscar punto de llegada...'}
              options={optionsPartida}
              onInputChange={handleSearchUbigeo}
              noOptionsMessage={() => 'No se encontraron resultados'}
              value={props.puntoLlegada.selectedUbigeo}
              onChange={(selectedOption) => handleUbigeoSelectChange(selectedOption, 'puntoLlegada')}
            />
            <ErrorBackend errorsBackend={props.errors} name="despatch.puntoLlegada.ubigeo" />
          </div>
        </div>
        {/*Dirección completa*/}
        <div className="col-md-8">
          <div className="form-group">
            <label htmlFor="puntoLlegada.direccion" className="form-label">
              Dirección del punto de llegada<span className="text-danger">*</span>
            </label>
            <input
              autoComplete="off"
              onChange={(e) => handleInputChange(e, 'puntoLlegada')}
              name="direccion"
              id="puntoLlegada.direccion"
              value={props.puntoLlegada.direccion}
              type="text"
              className={`todo-mayuscula form-control form-control-sm${
                hasError('despatch.puntoLlegada.direccion') ? ' is-invalid' : ''
              }`}
            />
            <ErrorBackend errorsBackend={props.errors} name="despatch.puntoLlegada.direccion" />
          </div>
        </div>
      </div>
    </>
  );
};

export default DatosDestino;
