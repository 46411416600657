import api from './api';
/**Models */
import { DataTable, Presentation } from '../models';
import { PresentationResponse } from '../models/responses';

export const getPresentations = async (page: number, text: string) => {
  try {
    const response = await api.get<DataTable>(`/presentations?page=${page}&text=${text}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const storePresentation = async (presentation: Presentation) => {
  try {
    const response = await api.post<PresentationResponse>('/presentations', { presentation: presentation });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updatePresentation = async (presentation: Presentation) => {
  try {
    const response = await api.update<PresentationResponse>(`/presentations/${presentation.id}`, { presentation: presentation });
    return response;
  } catch (error) {
    throw error;
  }
};
