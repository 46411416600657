import api from "./api";
/**Models */
import {
  PurchaseResponse,
} from "../models/responses";
import { PurchaseRequest } from "../models/requests";
import { DataTable } from "../models";
import { DetailPurchaseResponse } from "../models/responses/detailPurchase.response";

export const getRequirements = async (type: string) => {
  try {
    const response = await api.get(`/purchases/requirements?type=${type}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const storePurchase = async (purchase: PurchaseRequest) => {
  try {
    const response = await api.post<PurchaseResponse>("/purchases", { purchase: purchase });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getPurchases = async (
  page: number,
  text: string,
  type_document_id: string,
  date_initial: string,
  date_final: string,
  sales: string,
) => {
  try {
    const response = await api.get<DataTable>(
      `/purchases?page=${page}&text=${text}&type_document_id=${type_document_id}&date_initial=${date_initial}&date_final=${date_final}&sales=${sales}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deletePurchase = async (id: string) => {
  try {
    const response = await api.destroy(`/purchases/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getPurchase = async (uuid: string) => {
  try {
    const response = await api.get<DetailPurchaseResponse>(`/purchases/${uuid}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updatePurchase = async (purchase: PurchaseRequest) => {
  try {
    const response = await api.update<PurchaseResponse>(`/purchases/${purchase.id}`, { purchase: purchase });
    return response;
  } catch (error) {
    throw error;
  }
};