import { ChangeEvent } from 'react';

interface Props {
  onFilterChange: (filters: any, type: string) => void;
  onClearFilters: () => void;
  filterState: any;
}

export const Filter = (props: Props) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedFilters = { ...props.filterState, text: event.target.value };
    props.onFilterChange(updatedFilters, 'TEXT');
  };

  return (
    <div className="mb-3 row">
      <div className="col-md-5">
        <label htmlFor="text">Buscar por cliente o número de cotización</label>
        <div role="group" className="btn-group">
          <input
            autoComplete='off'
            name="text"
            value={props.filterState.text}
            onChange={handleInputChange}
            type="text"
            className="todo-mayuscula form-control form-control-sm"
            id="text"
          />
          <button onClick={() => props.onClearFilters()} className="btn btn-sm btn-primary">
            Limpiar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
