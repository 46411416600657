import api from "./api";
/**Models */
import { DataTable, Product } from "../models";
import { ProductResponse, SelectResponse } from "../models/responses";

export const getProducts = async (
  page: number,
  category: string,
  brand: string,
  presentation: string,
  text: string,
) => {
  try {
    const response = await api.get<DataTable>(
      `/products?page=${page}&text=${text}&category_id=${category}&brand_id=${brand}&presentation_id=${presentation}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const searchProduct = async (
  text: string,
  model_id: string,
  products_not: string,
  filter: string,
) => {
  try {
    const response = await api.get<SelectResponse>(
      `/products?text=${text}&type=get&model_id=${model_id}&products_not=${products_not}&filter=${filter}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const storeProduct = async (product: Product) => {
  try {
    const response = await api.post<ProductResponse>("/products", { product: product });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateProduct = async (product: Product) => {
  try {
    const response = await api.update<ProductResponse>(`/products/${product.id}`, {
      product: product,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getRequirements = async () => {
  try {
    const response = await api.get("/products/requirements");
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteProduct = async (id: string) => {
  try {
    const response = await api.destroy(`/products/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
