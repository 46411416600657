import React, { useRef, useEffect, useState } from 'react';
//@ts-ignore
import { Modal } from 'bootstrap';

interface Props {
  title: string | null;
  content: React.ReactNode;
  onClose: () => void;
  typeModal: any;
  stateModal: boolean;
  size?: string;
}

const ModalComponent = (props: Props) => {
  const modalRef = useRef(null);
  const [modalInstance, setModalInstance] = useState<Modal | null>(null);

  useEffect(() => {
    if (!props.stateModal) {
      modalInstance.hide();
      props.onClose();
    }
  }, [modalInstance, props]);

  useEffect(() => {
    setModalInstance(new Modal(modalRef.current, { keyboard: false, backdrop: props.typeModal }));
  }, [props.typeModal]);

  useEffect(() => {
    if (modalInstance) {
      if (modalInstance._isShown) {
        modalInstance.hide();
      }
      if (modalInstance._isShown === false) {
        modalInstance.show();
      }
    }
  }, [modalInstance]);

  const handleModalClick = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
  };

  const handleClose = () => {
    modalInstance.hide();
    props.onClose();
  };

  const handleCloseOut = () => {
    if (props.typeModal === 'static') return;
    handleClose();
  };

  return (
    <div onClick={handleCloseOut} className="modal fade" ref={modalRef} tabIndex={-1} aria-labelledby="modalResource" aria-hidden="true">
      <div className={`modal-dialog modal-dialog-scrollable ${props.size ? props.size : ''}`} role="document" onClick={handleModalClick}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.title}</h5>
            <button type="button" className="btn-close" onClick={handleClose}></button>
          </div>
          {props.content}
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;
