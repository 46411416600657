import { ChangeEvent } from 'react';

interface Props {
  onFilterChange: (filters: any) => void;
  onClearFilters: () => void;
  filterState: any;
}

export const Filter = (props: Props) => {

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedFilters = { ...props.filterState, text: event.target.value };
    props.onFilterChange(updatedFilters);
  };

  return (
    <div className="mb-3 row">
      <div className="col-md-3">
        <label htmlFor="name">Nombre</label>
        <div role="group" className="btn-group">
          <input autoComplete='off' value={props.filterState.text} onChange={handleInputChange} type="text" className="form-control form-control-sm" id="name" />
          <button onClick={() => props.onClearFilters()} className="btn btn-sm btn-primary">Limpiar</button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
