/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useState } from "react";
import HeaderPage from "../../components/page/header";
import { ErrorBackendApi, SweetAlert } from "../../utilities";
import { useAccess, useLogin } from "../../hooks";

export const ReportsIncomesPage = () => {
  //STATE
  const state = {
    page: {
      title: "Cambiar contraseña",
      icon: "ri-lock-line",
      model: "change-password",
      header: {
        menu: ["Cambiar contraseña"],
      },
      gridMenu: [],
      body: [],
      buttons: {
        create: false,
        create_header: false,
        edit: false,
        destroy: false,
        import: false,
        export: false,
      },
    },
  };

  const [errors, setErrors] = useState<any>([]);
  const [formData, setFormData] = useState({
    password_current: "",
    password: "",
    password_confirmation: "",
  });
  const { changePassword } = useAccess();
  const { handleLogout } = useLogin();

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const updatePassword = async () => {
    changePassword(formData)
      .then(() => {
        SweetAlert.success(
          "Contraseña actualizada correctamente.",
        );
        handleLogout();
      })
      .catch((error: any) => {
        setErrors(error.response.data.errors);
      });
  };

  const hasError = (propertyName: string) => {
    return errors && errors[propertyName];
  };

  //METODOS DEL RECURSO
  const onClickAddResource = () => {};

  return (
    <div className="main-body p-0">
      <div className="inner-wrapper">
        <div className="inner-main">
          <HeaderPage page={state.page} onModalResource={onClickAddResource} />
          <div className="inner-main-body">
            <div className="row">
              <div className="col-sm-12 col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label
                            htmlFor="password_current"
                            className="form-label"
                          >
                            Contraseña actual
                          </label>
                          <input
                            id="password_current"
                            name="password_current"
                            value={formData.password_current}
                            onChange={onChangeInput}
                            autoComplete="off"
                            type="password"
                            className={`form-control form-control-sm${
                              hasError(`password_current`) ? " is-invalid" : ""
                            }`}
                          />
                          <ErrorBackendApi
                            errorsBackend={errors}
                            name={`password_current`}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="password" className="form-label">
                            Contraseña nueva
                          </label>
                          <input
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={onChangeInput}
                            autoComplete="off"
                            type="password"
                            className={`form-control form-control-sm${
                              hasError(`password`) ? " is-invalid" : ""
                            }`}
                          />
                          <ErrorBackendApi
                            errorsBackend={errors}
                            name="password"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label
                            htmlFor="password_confirmation"
                            className="form-label"
                          >
                            Confirmar contraseña nueva
                          </label>
                          <input
                            id="password_confirmation"
                            name="password_confirmation"
                            value={formData.password_confirmation}
                            onChange={onChangeInput}
                            autoComplete="off"
                            type="password"
                            className={`form-control form-control-sm${
                              hasError(`password_confirmation`)
                                ? " is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorBackendApi
                            errorsBackend={errors}
                            name="password_confirmation"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer text-center">
                    <button
                      onClick={updatePassword}
                      className="btn btn-primary"
                    >
                      Cambiar contraseña
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsIncomesPage;
