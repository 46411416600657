import { useState, ChangeEvent } from 'react';
/**Models */
import { DespatchItemRequest } from '../../../models/requests';
/**Select */
import Select from 'react-select';

interface Props {
  searchDocuments: any;
  handleImportItems: (items: DespatchItemRequest[]) => void;
  onCloseModalForm: () => void;
  clientId: string | number;
}

interface OptionSelect {
  label: string;
  value: string;
  items: DespatchItemRequest[];
}

const ImportarItems = (props: Props) => {
  const [optionsDocuments, setOptionsDocuments] = useState<OptionSelect[]>([]);
  const [isLoadingDocumento, setIsLoadingDocumento] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const [type, setType] = useState<string>('');
  const [items, setItems] = useState<DespatchItemRequest[]>([]);

  const handleSearchDocument = (value: string) => {
    if (value.length >= 3) {
      props
        .searchDocuments(value, props.clientId, type)
        .then((response: any) => {
          setIsLoadingDocumento(true);
          setOptionsDocuments(response.data);
        })
        .finally(() => {
          setIsLoadingDocumento(false);
        });
    }
  };

  const handleDocumentoSelectChange = (selectedOption: OptionSelect) => {
    setItems([]);
    setSelectedDocument(selectedOption);
    setItems(selectedOption.items);
  };

  const onChangeSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    setType(event.target.value);
  };

  const onClickImport = () => {
    props.handleImportItems(items);
  }

  return (
    <div className="form form-horizontal form-scrollable" style={{height:500}}>
      <div className="modal-body">
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="tipoDocumento" className="form-label">
              Tipo de documento
            </label>
            <select
              onChange={onChangeSelect}
              value={type}
              name="tipoDocumento"
              id="tipoDocumento"
              className="form-control form-control-sm form-select"
            >
              <option value="" disabled>
                Seleccionar
              </option>
              <option value="C">COTIZACIÓN</option>
              <option value="01">FACTURA ELECTRÓNICA</option>
              <option value="03">BOLETA ELECTRÓNICA</option>
              <option value="00">NOTA DE VENTA</option>
            </select>
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="documento" className="form-label">
              Documento
            </label>
            <Select
              id="documento"
              isDisabled={type === ''}
              isLoading={isLoadingDocumento}
              placeholder={'Buscar documento: Ejemplo F001-27...'}
              options={optionsDocuments}
              onInputChange={handleSearchDocument}
              noOptionsMessage={() => 'No se encontraron resultados'}
              value={selectedDocument}
              onChange={(selectedOption) => handleDocumentoSelectChange(selectedOption)}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button onClick={onClickImport} type="button" className="btn btn-sm btn-primary">
          Importar
        </button>
        <button type="button" onClick={() => props.onCloseModalForm()} className="btn btn-sm btn-secondary">
          Cancelar
        </button>
      </div>
    </div>
  );
};

export default ImportarItems;
