/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
/**Reduxs */
import { AppStore } from '../../redux/store';
import { setearIsStateToggle } from '../../redux/states/loading.slice';
/**Components */
import Sidebar from '../../components/sidebar';
import Header from '../../components/header';
import { LoadingState } from '../../components/shared';

const DefaultLayout = () => {
  const dispatch = useDispatch();
  const loadingState = useSelector((store: AppStore) => store.loading);

  const validateCollapse = () => {
    let stateToggle = localStorage.getItem('stateToggle');
    dispatch(setearIsStateToggle(stateToggle === 'true' ? 'false' : 'true'));
  };

  return (
    <section id="layout__auth-default" className={`body ${loadingState.isStateToggle === 'true' ? 'sidebar-collapse sidebar-expand' : ''}`}>
      <Sidebar onValidateCollapse={validateCollapse} />
      <div className="main">
        <Header onValidateCollapse={validateCollapse} />
        <Outlet />
      </div>
      <div onClick={() => validateCollapse()} className="sidebar-backdrop" id="sidebarBackdrop" data-toggle="sidebar"></div>
      {loadingState.isLoading ? <LoadingState /> : null}
    </section>
  );
};

export default DefaultLayout;
