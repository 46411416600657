import { useState, useEffect } from 'react';
/**Components */
import HeaderPage from '../../../components/page/header';
import BodyPage from '../../../components/page/body';
/**Hooks */
import { useDespatches } from '../../../hooks';
import Filter from './Filter';

const DespatchesHistory = () => {

    const { fetchDespatches } = useDespatches();
  const [filterState, setFilterState] = useState({
    page: 1,
    text: '',
  });
    //DATA TABLE
  const state = {
    page: {
      title: 'Historial de Guias de Remisión',
      icon: 'ri-file-list-3-line',
      model: 'quotes',
      header: {
        menu: ['Guías de Remisión', 'Historial'],
      },
      buttons: {
        create: false,
        edit: false,
        destroy: false,
        import: false,
        export: false,
      },
    },
    dataTable: {
      body: {
        widthAccion: '160px',
        cols: [
          {
            name: 'client_document_number',
            alias: 'Número documento',
            width: '170px',
          },
          {
            name: 'client_names',
            alias: 'Cliente',
          },
          {
            name: 'fecha_emision',
            alias: 'Fecha emisión',
            width: '120px',
          },
          {
            name: 'serie',
            alias: 'Serie',
            width: '70px',
          },
          {
            name: 'numeracion',
            alias: 'Num.',
            width: '70px',
          },
          {
            name: 'state_sunat',
            alias: 'Estado',
            width: '50px',
            play: {
              type: 'states',
              values: {
                'RECHAZADA': 'bg-danger-subtle text-danger',
                'ACEPTADA': 'bg-success-subtle text-success',
                'OBSERVADA' : 'bg-warning-subtle text-warning',
                'PROCESANDO' : 'bg-info-subtle text-info',
              },
              names: {
                'RECHAZADA': 'Rechazada',
                'ACEPTADA': 'Aceptada',
                'OBSERVADA' : 'Observada',
                'PROCESANDO' : 'Procesando',
              },
            },
          },
        ],
        buttons: [
          {
            name: 'print',
            tooltip: 'Ver PDF',
            text: '',
            css: 'me-3 text-danger',
            icon: 'far fa-file-pdf',
            play: {
              type: 'alls',
            },
          },
        ],
      },
    },
  };

  const onClickAddResource = () => {};
  const onClickEditResource = () => {};
  const onClickDeleteResource = () => {};

  const onClearFilters = () => {
    setFilterState({ ...filterState, page: 1, text: '' });
    fetchDespatches(1, '', true);
  };

  const handleFilterChange = (newFilters: any, type: string) => {
    let state = true;
    if (type === 'TEXT') {
      state = false;
    }
    setFilterState(newFilters);
    fetchDespatches(1, newFilters.text, state);
  };

  const onClickButtonPersonalizado = (row: any, name: any) => {
    switch (name) {
      case 'print':
        onClickPrint(row);
        break;
      default:
        break;
    }
  };
  const onClickPrint = (row: any) => {
    window.open(`${process.env.REACT_APP_LINK_BACKEND}/despatch/${row.uuid}.pdf`, '_blank');
  };

  const onChangePage = (page: number, type: string) => {
    switch (type) {
      case 'prev':
        setFilterState({ ...filterState, page: filterState.page - 1 });
        fetchDespatches(filterState.page - 1, filterState.text, true);
        break;

      case 'current':
        setFilterState({ ...filterState, page: page });
        fetchDespatches(page, filterState.text, true);
        break;
      case 'next':
        setFilterState({ ...filterState, page: filterState.page + 1 });
        fetchDespatches(filterState.page + 1, filterState.text, true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchDespatches(1, '', true);
  }, [fetchDespatches]);

  return (
    <div className="main-body p-0">
      <div className="inner-wrapper">
        <div className="inner-main">
          <HeaderPage page={state.page} onModalResource={onClickAddResource} />
          <div className="inner-main-body">
            <div className="card card-featured card-featured-primary mb-3 mt-3">
              <div className="card-body">
                <Filter onClearFilters={onClearFilters} filterState={filterState} onFilterChange={handleFilterChange} />
                <BodyPage
                  onClickButtonPersonalizado={onClickButtonPersonalizado}
                  onChangeEdit={onClickEditResource}
                  onChangeDelete={onClickDeleteResource}
                  onChangePage={onChangePage}
                  page={state.page}
                  table={state.dataTable}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DespatchesHistory;
