import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
/**Models */
import { DataTable } from '../models';
/**Services */
import * as quotesService from '../services/quotes.service';
/**Hooks */
import { setLoading } from '../redux/states/loading.slice';
import { dataTable_setAlls, dataTable_clearAll, dataTable_deleteResource } from '../redux/states/dataTable.slice';
import { QuoteRequest } from '../models/requests';

export function useQuotes() {
  const dispatch = useDispatch();
  const [quotes, setQuotes] = useState<DataTable | null>(null);

  //GET
  const fetchQuotes = useCallback(
    (page: number, text: string, loading: boolean) => {
      dispatch(setLoading(loading));
      quotesService
        .getQuotes(page, text)
        .then((response) => {
          dispatch(dataTable_setAlls(response));
          setQuotes(response); // Actualiza el estado local si es necesario
        })
        .catch((error) => error)
        .finally(() => {
          dispatch(setLoading(false));
        });
    },
    [dispatch],
  );

  useEffect(() => {
    const resetData = () => {
      dispatch(dataTable_clearAll());
      setQuotes(null); // Borra el estado local si es necesario
    };

    return () => {
      resetData(); // Esto se ejecutará cuando el componente se desmonte
    };
  }, [dispatch]);

  //STORE
  const storeQuote = async (quote: QuoteRequest) => {
    dispatch(setLoading(true));
    try {
      const response = await quotesService.storeQuote(quote);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  //UPDATE
  const updateQuote = async (quote: QuoteRequest) => {
    dispatch(setLoading(true));
    try {
      const response = await quotesService.updateQuote(quote);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  //DELETE
  const deleteQuote = async (id: string) => {
    dispatch(setLoading(true));
    try {
      const response = await quotesService.deleteQuote(id);
      dispatch(dataTable_deleteResource({ id }));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  //DETAIL
  const getQuote = async (uuid: string) => {
    dispatch(setLoading(true));
    try {
      const response = await quotesService.getQuote(uuid);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }

  return { storeQuote, quotes, fetchQuotes, getQuote, updateQuote, deleteQuote };
}
