import api from './api';
/**Models */
import { DataTable, Brand } from '../models';
import { BrandResponse } from '../models/responses';

export const getBrands = async (page: number, text: string) => {
  try {
    const response = await api.get<DataTable>(`/brands?page=${page}&text=${text}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const storeBrand = async (brand: Brand) => {
  try {
    const response = await api.post<BrandResponse>('/brands', { brand: brand });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateBrand = async (brand: Brand) => {
  try {
    const response = await api.update<BrandResponse>(`/brands/${brand.id}`, { brand: brand });
    return response;
  } catch (error) {
    throw error;
  }
};
