import api from './api';
/**Models */
import { DataTable, Supplier } from '../models';
import { SupplierRequest } from '../models/requests';
import { SupplierResponse, SelectResponse } from '../models/responses';

export const getSuppliers = async (page: number, text: string, type: string, mode? : string) => {
  try {
    const response = await api.get<DataTable>(`/suppliers?page=${page}&text=${text}&type=${type}&mode=${mode??''}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const searchSupplier = async (text: string, type: string) => {
  try {
    const response = await api.get<SelectResponse>(`/suppliers?text=${text}&type=${type}&mode=get`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const storeSupplier = async (supplier: SupplierRequest) => {
  try {
    const response = await api.post<SupplierResponse>('/suppliers', { supplier: supplier });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateSupplier = async (supplier: SupplierRequest) => {
  try {
    const response = await api.update<SupplierResponse>(`/suppliers/${supplier.id}`, { supplier: supplier });
    return response;
  } catch (error) {
    throw error;
  }
};

export const personSearch = async (document: string) => {
  try {
    const response = await api.get<Supplier>(`/persons/find?document_number=${document}`);
    return response;
  } catch (error) {
    throw error;
  }
}