import api from './api';
/**Models */
import { DataTable, Client } from '../models';
import { ClientRequest } from '../models/requests';
import { ClientResponse, SelectResponse } from '../models/responses';

export const getClients = async (page: number, text: string, type: string, mode? : string) => {
  try {
    const response = await api.get<DataTable>(`/clients?page=${page}&text=${text}&type=${type}&mode=${mode??''}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const searchClient = async (text: string, type: string) => {
  try {
    const response = await api.get<SelectResponse>(`/clients?text=${text}&type=${type}&mode=get`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const storeClient = async (client: ClientRequest) => {
  try {
    const response = await api.post<ClientResponse>('/clients', { client: client });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateClient = async (client: ClientRequest) => {
  try {
    const response = await api.update<ClientResponse>(`/clients/${client.id}`, { client: client });
    return response;
  } catch (error) {
    throw error;
  }
};

export const personSearch = async (document: string) => {
  try {
    const response = await api.get<Client>(`/persons/find?document_number=${document}`);
    return response;
  } catch (error) {
    throw error;
  }
}