import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
/**Models */
import { DataTable } from '../models';
import { SupplierRequest } from '../models/requests';
/**Services */
import * as suppliersService from '../services/suppliers.service';
/**Hooks */
import { setLoading } from '../redux/states/loading.slice';
import { dataTable_setAlls, dataTable_clearAll, dataTable_addResource, dataTable_updateResource } from '../redux/states/dataTable.slice';

export function useSuppliers() {
  const dispatch = useDispatch();
  const [suppliers, setSuppliers] = useState<DataTable | null>(null);

  //GET
  const fetchSuppliers = useCallback(
    (page: number, text: string, type: string, loading: boolean) => {
      dispatch(setLoading(loading));
      suppliersService
        .getSuppliers(page, text, type)
        .then((response) => {
          dispatch(dataTable_setAlls(response));
          setSuppliers(response);
        })
        .catch((error) => error)
        .finally(() => {
          dispatch(setLoading(false));
        });
    },
    [dispatch],
  );

  useEffect(() => {
    const resetData = () => {
      dispatch(dataTable_clearAll());
      setSuppliers(null);
    };
    fetchSuppliers(1, '', '', true); // Llamada inicial al cargar el componente

    return () => {
      resetData(); // Esto se ejecutará cuando el componente se desmonte
    };
  }, [fetchSuppliers, dispatch]);

  //STORE
  const storeSupplier = async (supplier: SupplierRequest) => {
    dispatch(setLoading(true));
    try {
      const response = await suppliersService.storeSupplier(supplier);
      dispatch(dataTable_addResource(response.supplier));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  //UPDATE
  const updateSupplier = async (supplier: SupplierRequest) => {
    dispatch(setLoading(true));
    try {
      const response = await suppliersService.updateSupplier(supplier);
      dispatch(dataTable_updateResource(response.supplier));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const personSearch = async (document: string) => {
    dispatch(setLoading(true));
    try {
      const response = await suppliersService.personSearch(document);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }


  return { suppliers, fetchSuppliers, storeSupplier, updateSupplier, personSearch };
}
