import api from "./api";
/**Models */
import {
  SaleResponse,
  CancelResponse,
  ResponseModel,
  DetailQuoteResponse,
} from "../models/responses";
import { CancelRequest, SaleRequest } from "../models/requests";
import { DataTable } from "../models";

export const getRequirements = async (type: string) => {
  try {
    const response = await api.get(`/sales/requirements?type=${type}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const storeSale = async (sale: SaleRequest) => {
  try {
    const response = await api.post<SaleResponse>("/sales", { sale: sale });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getSales = async (
  page: number,
  text: string,
  type_document_id: string,
  date_initial: string,
  date_final: string,
  sales: string,
) => {
  try {
    const response = await api.get<DataTable>(
      `/sales?page=${page}&text=${text}&type_document_id=${type_document_id}&date_initial=${date_initial}&date_final=${date_final}&sales=${sales}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getSale = async (uuid: string, note: string) => {
  try {
    const response = await api.get<DetailQuoteResponse>(
      `/sales/${uuid}?mode=${note}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const cancelSale = async (sale_id: string, cancel: CancelRequest) => {
  try {
    const response = await api.post<CancelResponse>(
      `/sales/${sale_id}/cancel`,
      { sale: cancel },
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const sendEmail = async (sale_id: string, email: string) => {
  try {
    const response = await api.post<ResponseModel>(
      `/sales/${sale_id}/send-email`,
      { sale: { email: email } },
    );
    return response;
  } catch (error) {
    throw error;
  }
};
