import { ChangeEvent, createRef } from 'react';
/**DatePicker */
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/l10n/es';
/**Moment */
import moment from 'moment';

interface Props {
  onFilterChange: (filters: any, loading: boolean) => void;
  onClearFilters: () => void;
  filterState: any;
  requirements: any;
}

export const Filter = (props: Props) => {
  
  const flatpickrRef = createRef<Flatpickr>();
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedFilters = { ...props.filterState, text: event.target.value };
    props.onFilterChange(updatedFilters, false);
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const updatedFilters = { ...props.filterState, [event.target.name]: event.target.value };
    props.onFilterChange(updatedFilters, true);
  };

  const handleDateChange = (selectedDates: Date[]) => {
    const updatedFilters = {
      ...props.filterState,
      dateInitial: moment(selectedDates[0]).format('YYYY-MM-DD'),
      dateFinal: moment(selectedDates[1]).format('YYYY-MM-DD'),
      selectedDates: selectedDates,
    };
    if(selectedDates.length === 2){
      props.onFilterChange(updatedFilters, true);
    }
    
  };

  const handleClearFilters = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }
    props.onClearFilters();
  };

  return (
    <div className="mb-3 row">
       <div className="col-md-2">
        <label htmlFor="typeDocument">Tipo de Documento</label>
        <select
          name="typeDocument"
          value={props.filterState.typeDocument}
          onChange={handleSelectChange}
          className="form-control form-control-sm form-select"
          id="typeDocument"
        >
          <option value="">Todos</option>
          {props.requirements && props.requirements.type_documents.map((document: any) => (
            <option key={document.id} value={document.id}>
              {document.name}
            </option>
          ))}
        </select>
      </div>

      <div className="col-md-2">
        <div className="form-group">
          <label htmlFor="date_issue" className="form-label">
            Rango de fechas
          </label>
          <Flatpickr
            ref={flatpickrRef}
            value={props.filterState.selectedDates}
            onChange={handleDateChange}
            className="form-control form-control-sm"
            options={{
              mode: "range",
              dateFormat: 'Y-m-d',
              locale: 'es',
            }}
          />
        </div>
      </div>

      <div className="col-md-3">
        <label htmlFor="text">Cliente</label>
        <div role="group" className="btn-group">
          <input
            autoComplete='off'
            name="text"
            value={props.filterState.text}
            onChange={handleInputChange}
            type="text"
            className="todo-mayuscula form-control form-control-sm"
            id="text"
          />
          <button onClick={handleClearFilters} className="btn btn-sm btn-primary">
            Limpiar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
