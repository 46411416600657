import { useDispatch } from "react-redux";
/**Models */
import { Tariff } from "../models";
/**Services */
import * as tariffsService from "../services/tariffs.service";
/**Hooks */
import { setLoading } from "../redux/states/loading.slice";
import {
  dataTable_setAlls,
  dataTable_addResource,
  dataTable_updateResource,
} from "../redux/states/dataTable.slice";

export function useTariffs() {
  const dispatch = useDispatch();

  //GET
  const getTariffs = async (page: number, text: string, model_type: string, model_id: string, loading: boolean) => {
    dispatch(setLoading(loading));
    try {
      const response = await tariffsService.getTariffs(page, text, model_type, model_id);
      dispatch(dataTable_setAlls(response));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }

  const getTariffClients = async () => {
    dispatch(setLoading(true));
    try {
      const response = await tariffsService.getTariffClients();
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }

  //STORE
  const storeTariff = async (tariff: Tariff) => {
    dispatch(setLoading(true));
    try {
      const response = await tariffsService.storeTariff(tariff);
      dispatch(dataTable_addResource(response.tariff));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  //UPDATE
  const updateTariff = async (tariff: Tariff) => {
    dispatch(setLoading(true));
    try {
      const response = await tariffsService.updateTariff(tariff);
      dispatch(dataTable_updateResource(response.tariff));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }

  return { getTariffs, storeTariff, updateTariff, getTariffClients };
}
