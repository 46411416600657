import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
/**Models */
import { DataTable, Category } from '../models';
/**Services */
import * as categoriesService from '../services/categories.service';
/**Hooks */
import { setLoading } from '../redux/states/loading.slice';
import { dataTable_setAlls, dataTable_clearAll, dataTable_addResource, dataTable_updateResource } from '../redux/states/dataTable.slice';

export function useCategories() {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState<DataTable | null>(null);

  //GET
  const fetchCategories = useCallback(
    (page: number, text: string, loading: boolean) => {
      dispatch(setLoading(loading));
      categoriesService
        .getCategories(page, text)
        .then((response) => {
          dispatch(dataTable_setAlls(response));
          setCategories(response); // Actualiza el estado local si es necesario
        })
        .catch((error) => error)
        .finally(() => {
          dispatch(setLoading(false));
        });
    },
    [dispatch],
  );

  useEffect(() => {
    const resetData = () => {
      dispatch(dataTable_clearAll());
      setCategories(null); // Borra el estado local si es necesario
    };
    fetchCategories(1, '', true); // Llamada inicial al cargar el componente

    return () => {
      resetData(); // Esto se ejecutará cuando el componente se desmonte
    };
  }, [fetchCategories, dispatch]);

  //STORE
  const storeCategory = async (category: Category) => {
    dispatch(setLoading(true));
    try {
      const response = await categoriesService.storeCategory(category);
      dispatch(dataTable_addResource(response.category));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }

  //UPDATE
  const updateCategory = async (category: Category) => {
    dispatch(setLoading(true));
    try {
      const response = await categoriesService.updateCategory(category);
      dispatch(dataTable_updateResource(response.category));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }

  return { categories, fetchCategories, storeCategory, updateCategory };
}
