/* eslint-disable jsx-a11y/anchor-is-valid */
import { useParams } from "react-router-dom";
import { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/**Models */
import { CuotasRequest, QuoteRequest, SaleItemRequest } from "../../../models/requests";
/**Validations */
import { useFormik } from "formik";
import * as Yup from "yup";
/**Utilities */
import { SweetAlert } from "../../../utilities";
/**Componentes locales */
import EditHeaderQuote from "./EditHeaderQuote";
import SalesHeaderDetail from "../../Sales/Store/headerDetail";
import EditBodyDetail from "./EditBodyDetail";
import SalesTotals from "../../Sales/Store/totals";
import FinishEditQuote from "./FinishEditQuote";
import ModalComponent from "../../../components/modal";
/**Hooks */
import { useQuotes, useSales } from "../../../hooks";
/**Redux */
import { setearIsStateToggle } from "../../../redux/states/loading.slice";
import FormaPago from "../../Sales/Store/DatosExtras/FormaPago";

/** Modal Resource */
interface DataModalState {
  size: string;
  type: string;
  buttonSubmit: string | null;
  row: any | null;
  title: string | null;
  requirements: any[];
  onCloseModalForm: () => void;
  errosBackend: any;
}

const QuotesEdit = () => {
  //DATA DEFAULT
  const defaultFormData: QuoteRequest = {
    serie: "",
    numeracion: "",
    currency_id: "",
    operation_type_id: 1,
    client_id: "",
    client_name: "",
    fechaEmision: "",
    fechaVencimiento: "",
    formaPago: "CONTADO",
    cuotas: [],
    items: [],
    totals: {
      descuento: "",
      descuento_global: 0.0,
      descuento_por_item: 0.0,
      descuento_total: 0.0,
      anticipo: 0.0,
      exonerada: 0.0,
      inafecta: 0.0,
      gravada: 0.0,
      igv: 0.0,
      gratuita: 0.0,
      icbper: 0.0,
      total: 0.0,
    },
    observaciones: "",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { getQuote, updateQuote } = useQuotes();
  const { productSearch } = useSales();
  const [requirements, setRequirements] = useState<any | null>(null);
  const [loaded, setLoaded] = useState(false);
  const [products_not, setProductsNot] = useState<any>([]);
  const [backendErrors, setBackendErrors] = useState(null);

  //FormData
  const [formData, setFormData] = useState<QuoteRequest>(defaultFormData);
  const validationSchema = Yup.object().shape({});
  //Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stateModal, setStateModal] = useState<boolean>(false);
  const [componentModal, setComponentModal] = useState<any>(null);
  const [dataModalResourceState, setDataModalResourceState] = useState<DataModalState>({
    size: "",
    type: "",
    buttonSubmit: null,
    row: null,
    title: null,
    requirements: [],
    onCloseModalForm: () => {},
    errosBackend: {},
  });

  const cerrarCotizacion = () => {
    setStateModal(false);
    setIsModalOpen(false);
    const modalBackdrops = document.getElementsByClassName("modal-backdrop");
    while (modalBackdrops.length > 0) {
      modalBackdrops[0]?.parentNode?.removeChild(modalBackdrops[0]);
    }
    navigate("/quotes-history");
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: async () => {
      console.log(formData);
      if (formData.client_id === "") {
        SweetAlert.warning("Alerta", "Para realizar una COTIZACIÓN debes seleccionar un cliente.");
        return;
      }
      if (formData.items.length === 0) {
        SweetAlert.warning("Alerta", "Debe agregar al menos un item.");
        return;
      }

      if (formData.formaPago === "CREDITO" && formData.cuotas.length === 0) {
        SweetAlert.warning("Alerta", "Debe agregar al menos una cuota.");
        return;
      }
      if (formData.formaPago === "CREDITO") {
        let totalCuotas = 0;
        formData.cuotas.forEach((cuota) => {
          totalCuotas += Number(cuota.importe);
        });
        if (totalCuotas !== formData.totals.total) {
          SweetAlert.warning(
            "Alerta",
            "El total de las cuotas debe ser igual al total de la cotización.",
          );
          return;
        }
      }

      //UPDATE
      updateQuote(formData)
        .then((response) => {
          if (response.success) {
            setDataModalResourceState({
              size: "modal-md",
              type: "finish-quote",
              buttonSubmit: null,
              row: formData,
              title: "Actualización de cotización",
              requirements: [],
              onCloseModalForm: onCloseModalForm,
              errosBackend: {},
            });
            setComponentModal(
              <FinishEditQuote onCloseModalForm={cerrarCotizacion} response={response} />,
            );
            setStateModal(true);
            setIsModalOpen(true);
          } else {
            console.log(response);
            SweetAlert.error(
              "Error",
              "Ocurrio un error al registrar la cotización, revise los errores y vuelva a generar la cotización.",
            );
          }
        })
        .catch((error) => {
          console.log(error);
          setBackendErrors(error.response.data.errors);
          SweetAlert.warning(
            "Error",
            "Ocurrio un error al registrar la cotización, revise los campos faltantes y vuelva a generar la cotización.",
          );
        });
    },
  });

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    formik.handleChange(event);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSelectChangeInHeaderSale = (fieldName: string, value: any) => {
    formik.setFieldValue(fieldName, value);
    setFormData((prevFormData) => ({ ...prevFormData, [fieldName]: value }));
  };

  const onClickAddItem = () => {
    const item: SaleItemRequest = {
      product_id: "",
      additional_detail: "",
      quantity: 1,
      igv_type_id: 1,
      unit_value: 0.0,
      sub_total: 0.0,
      total: 0.0,
      view: true,
    };
    setFormData({ ...formData, items: [...formData.items, item] });
  };

  const onClickDeleteItem = (index: number) => {
    const updatedItems = formData.items.filter((_, i) => i !== index);
    const { cGravada, cIgv, cTotal } = calculateTotals(updatedItems);
    setFormData({
      ...formData,
      totals: {
        ...formData.totals,
        gravada: cGravada,
        igv: cIgv,
        total: cTotal,
      },
      items: updatedItems,
    });
  };

  const handleInputChangeInSaleBody = (index: number, fieldName: string, value: string) => {
    const updatedItems = [...formData.items];
    let igv = 1;
    updatedItems[index][fieldName] = value;
    if (updatedItems[index]["unit_value"] >= 0) {
      let subtotalItem =
        parseFloat(updatedItems[index].quantity.toString()) *
        parseFloat(updatedItems[index].unit_value.toString());
      updatedItems[index].sub_total = subtotalItem;
      switch (updatedItems[index]["igv_type_id"]) {
        case 1: // GRAVADO - OPERACIÓN ONEROSA
          igv = 1.18;
          break;

        default:
          break;
      }
      updatedItems[index].total = updatedItems[index].sub_total * igv;

      const { cGravada, cIgv, cTotal } = calculateTotals(updatedItems);

      setFormData({
        ...formData,
        totals: {
          ...formData.totals,
          gravada: cGravada,
          igv: cIgv,
          total: cTotal,
        },
        items: updatedItems,
      });
    }
  };

  const calculateTotals = (items: any) => {
    let cGravada = 0;
    let cIgv = 0;
    let cTotal = 0;
    items.forEach((item: any) => {
      if (item.igv_type_id === 1) {
        cGravada += item.sub_total;
        cIgv += item.sub_total * 0.18;
      }
      cTotal += item.total;
    });

    return { cGravada, cIgv, cTotal };
  };

  useEffect(() => {
    const dataInicial = () => {
      getQuote(uuid ?? "").then((res) => {
        setFormData(res.quote);
        setProductsNot(res.quote.item_products_id);
        setRequirements(res.requirements);
        setLoaded(true);
      });
    };

    if (!loaded) {
      dataInicial();
    }
  }, [getQuote, loaded, uuid]);

  useEffect(() => {
    dispatch(setearIsStateToggle("true"));
    return () => {
      dispatch(setearIsStateToggle("false"));
    };
  }, [dispatch]);

  const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    formik.handleChange(event);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  /**Modal */
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const onCloseModalForm = () => {
    setStateModal(false);
  };

  /**Modal - Datos extras */
  const onClickOpenDatosExtras = (type: string) => {
    let component = null;
    let title = "";
    let size = "";
    switch (type) {
      case "FormaPago":
        component = (
          <FormaPago
            fechaEmision={formData.fechaEmision}
            onAddResourceItem={onClickAddCuota}
            cuotas={formData.cuotas}
            handleSelect={handleSelectChangeInHeaderSale}
            formaPago={formData.formaPago}
            mtoTotal={formData.totals.total}
            onCloseModalForm={onCloseModalForm}
            errors={backendErrors}
          />
        );
        title = "Forma de pago";
        size = "modal-lg";
        break;
      default:
        break;
    }
    setDataModalResourceState({
      size: size,
      type: "datos-extras",
      buttonSubmit: null,
      row: formData,
      title: title,
      requirements: [],
      onCloseModalForm: onCloseModalForm,
      errosBackend: {},
    });
    setComponentModal(component);
    setStateModal(true);
    setIsModalOpen(true);
  };

  const onClickAddCuota = (cuotas: CuotasRequest[], formaPago: string) => {
    setFormData({ ...formData, formaPago: formaPago, cuotas: cuotas });
  };

  return (
    <div className="main-body p-0">
      <div className="inner-wrapper">
        <div className="inner-main">
          <div className="inner-main-body">
            <div className="card card-featured card-featured-primary mb-3 mt-3">
              <div className="card-body">
                <form className="form" onSubmit={formik.handleSubmit}>
                  <EditHeaderQuote
                    fechaVencimiento={formData.fechaVencimiento}
                    formData={formData}
                    handleSelectChangeInHeaderSale={handleSelectChangeInHeaderSale}
                    handleSelectChange={handleSelectChange}
                  />
                  {/* Detalle de la venta*/}
                  <div className="row border-sales">
                    <div className="title-items">
                      Items{" "}
                      <a onClick={() => onClickAddItem()} className="add-item-sales">
                        <i className="ri-add-line"></i>
                      </a>
                    </div>
                    <div className="col-md-12">
                      <SalesHeaderDetail />
                    </div>
                    {/* Array items*/}
                    <EditBodyDetail
                      getRequirements={requirements}
                      productSearch={productSearch}
                      items={formData.items}
                      onClickDeleteItem={onClickDeleteItem}
                      handleInputChange={handleInputChangeInSaleBody}
                      clientId={formData.client_id?.toString() ?? ""}
                      setProductsNot={setProductsNot}
                      products_not={products_not}
                      backendErrors={backendErrors}
                    />
                  </div>
                  <div className="row">
                    {/* Datos extras*/}
                    <div className="col-md-8 mt-3">
                      <div className="row border-sales p-1">
                        <div className="col-md-12 text-center">
                          <button
                            onClick={() => onClickOpenDatosExtras("FormaPago")}
                            type="button"
                            className="btn btn-link text-decoration-none"
                          >
                            Forma de pago
                          </button>
                        </div>
                      </div>
                      <div className="row border-sales">
                        <div className="col-md-12">
                          <label htmlFor="observaciones">Observaciones</label>
                          <textarea
                            value={formData.observaciones ?? ""}
                            onChange={handleTextAreaChange}
                            rows={4}
                            className="form-control todo-mayuscula"
                            name="observaciones"
                            id="observaciones"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    {/* Totales*/}
                    <SalesTotals totals={formData.totals} />

                    {/* Botones*/}
                    <div className="row d-flex justify-content-center mt-3">
                      <div className="d-grid col-md-4">
                        <button type="submit" className="btn btn-primary btn-lg btn-block">
                          Actualizar Cotización
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ModalComponent
          stateModal={stateModal}
          typeModal={"true"}
          size={dataModalResourceState.size}
          title={dataModalResourceState.title}
          content={componentModal}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default QuotesEdit;
