import { useDispatch } from "react-redux";
/**Models */
/**Services */
import * as reportsService from "../services/reports.service";
/**Hooks */
import { setLoading } from "../redux/states/loading.slice";
export function useReports() {
  const dispatch = useDispatch();

  //GET
  const getDashboard = async (date: string) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsService.getDashboard(date);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  return { getDashboard };
}
