import api from "./api";
/**Models */
import { DataTable, Tariff } from "../models";
import { TariffResponse } from "../models/responses";

export const getTariffs = async (
  page: number,
  text: string,
  model_type: string,
  model_id: string,
) => {
  try {
    const response = await api.get<DataTable>(
      `/tariffs?page=${page}&text=${text}&model_type=${model_type}&model_id=${model_id}`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const storeTariff = async (tariff: Tariff) => {
  try {
    const response = await api.post<TariffResponse>("/tariffs", {
      tariff: tariff,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateTariff = async (tariff: Tariff) => {
  try {
    const response = await api.update<TariffResponse>(`/tariffs/${tariff.id}`, {
      tariff: tariff,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getTariffClients = async () => {
  try {
    const response = await api.get<any>("/tariffs/clients");
    return response;
  } catch (error) {
    throw error;
  }
};
