import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
/**Components */
import { Loading } from '../components/shared';
/**Hooks */
import { useCheckAuth } from '../hooks';
/**Layouts */
import DefaultLayout from './auth/default.layout';
/**Contexts */
import { useAuth } from '../contexts/auth.context';

const AuthLayout = () => {
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const {userAuth, loading, error} = useCheckAuth();


  useEffect(() => {
    // // Verificar si hay un error definido y navegar en consecuencia
    if (error !== null || (!loading && !userAuth)) {
      localStorage.clear();
      navigate('/login');
    }

    // Verificar si no hay un token y navegar en consecuencia
    if (!localStorage.getItem('token')) {
      navigate('/login');
      localStorage.clear();
    }
    
    if (userAuth) {
      setUser(userAuth);
    }

  }, [error, loading, navigate, setUser, userAuth]);

  const getLayoutComponent = () => {
    const permissions = userAuth ? userAuth.permissions : [];
    localStorage.setItem('permissions', permissions.join(','));
    const roles = userAuth ? userAuth.roles : [];
    if (roles.length > 1) return <DefaultLayout />;
    switch (roles[0].code) {
      case 'DEVELOPER':
        return  <DefaultLayout/>;
      default:
        return  <DefaultLayout/>;
    }
  };

  return loading ? <Loading /> : userAuth ? getLayoutComponent() : <></>;
};
export default AuthLayout;
