import { ChangeEvent, useState } from "react";
/**Select */
import Select from "react-select";

interface Props {
  onFilterChange: (filters: any, loader: boolean) => void;
  onClearFilters: () => void;
  filterState: any;
  clientSearch: any;
}

interface OptionSelect {
  label: string;
  value: string;
}

export const Filter = (props: Props) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedFilters = { ...props.filterState, text: event.target.value };
    props.onFilterChange(updatedFilters, false);
  };
  const [optionsClients, setOptionsClients] = useState<OptionSelect[]>([]);
  const [isLoadingClients, setIsLoadingClients] = useState(false);

  const handleClientSearchChange = (value: string) => {
    if (value.length >= 3) {
      props
        .clientSearch(value, "JURIDICA")
        .then((response: any) => {
          setIsLoadingClients(true);
          setOptionsClients(response.data);
        })
        .finally(() => {
          setIsLoadingClients(false);
        });
    }
  };

  const handleClientSelectChange = (selectedOption: OptionSelect) => {
    const updatedFilters = {
      ...props.filterState,
      selectedClient: selectedOption,
      model_type: "CLIENTE",
      model_id: selectedOption.value,
    };
    props.onFilterChange(updatedFilters, true);
  };

  return (
    <div className="mb-3 row">
      <div className="col-md-5">
        <div className="form-group">
          <label htmlFor="model_id" className="form-label">
            Clientes
          </label>
          <Select
            isLoading={isLoadingClients}
            placeholder={"Buscar cliente..."}
            options={optionsClients}
            onInputChange={handleClientSearchChange}
            noOptionsMessage={() => "No se encontraron resultados"}
            value={props.filterState.selectedClient}
            onChange={(selectedOption) =>
              handleClientSelectChange(selectedOption)
            }
          />
        </div>
      </div>

      <div className="col-md-3">
        <label htmlFor="text">Producto</label>
        <div role="group" className="btn-group">
          <input
            autoComplete="off"
            name="text"
            value={props.filterState.text}
            onChange={handleInputChange}
            type="text"
            className="todo-mayuscula form-control form-control-sm"
            id="text"
          />
          <button
            onClick={() => props.onClearFilters()}
            className="btn btn-sm btn-primary"
          >
            Limpiar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
