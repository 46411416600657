import { ChangeEvent, useState } from 'react';
/**Validations */
import { useFormik } from 'formik';
import * as Yup from 'yup';
/**Utilities */
import { ErrorValidate } from '../../../utilities';
/**Models */
import { CancelRequest } from '../../../models/requests';

interface FormProps {
    data: any;
    onCloseModalForm: () => void;
    onSubmitFormCancel: (cancelId:string, cancelRequest: CancelRequest) => void;
  }
  
  const Cancel = (props: FormProps) => {

    const [formData, setFormData] = useState<CancelRequest>(
        props.data.row || {
          motivo_anulacion: '',
        },
      );

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        formik.handleChange(event);
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const validationSchema = Yup.object().shape({
        motivo_anulacion: Yup.string().required("El campo 'motivo' es obligatorio."),
      });

    const formik = useFormik({
        initialValues: formData,
        validationSchema,
        onSubmit: () => {
          props.onSubmitFormCancel(props.data.id, formData);
        },
      });

    return (
        <form className="form form-horizontal form-scrollable" onSubmit={formik.handleSubmit}>
        <div className="modal-body">
          <div className="form-row row">
          <div className="form-group col-md-12">
            <label className="form-label">
              Motivo de anulación<span className="text-danger">*</span>
            </label>
            <input
              autoComplete='off'
              name="motivo_anulacion"
              value={formik.values.motivo_anulacion}
              onChange={handleInputChange}
              type="text"
              className={'todo-mayuscula form-control form-control-sm' + (formik.errors.motivo_anulacion && formik.touched.motivo_anulacion ? ' is-invalid' : '')}
            />
            <ErrorValidate state={formik.errors.motivo_anulacion} />
          </div>
          </div>
        </div>
        <div className="modal-footer">
        <button type="submit" className="btn btn-sm btn-primary">Anular nota</button>
          <button type="button" onClick={() => props.onCloseModalForm()} className="btn btn-sm btn-secondary">
            Cerrar
          </button>
        </div>
      </form>
    );
  };
  
  export default Cancel;
  