/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
/**Redux */
import { AppStore } from "../../redux/store";
/**Resources */
import CanCheck from "../../resources/can";
/**Utilities */
import { Pagination } from "../../utilities/pagination.utility";

interface Props {
  page: any;
  table: any;
  onChangePage: (page: any, type: any) => void;
  onChangeEdit: (row: any) => void;
  onChangeDelete: (row: any) => void;
  onClickButtonPersonalizado: (row: any, name: any) => void;
}

export const BodyPage = (props: Props) => {
  const dataTableState = useSelector((store: AppStore) => store.dataTable);

  const mtd_showTd = (row: any, col: any) => {
    if (col.play) {
      let html = null;
      switch (col.play.type) {
        case "states":
          html = (
            <span
              className={`${
                col.play.values[row[col.name]]
              } me-1 badge bg-secondary`}
            >
              {col.play.names[row[col.name]]}
            </span>
          );
          break;
        default:
          break;
      }
      return html;
    } else {
      return row[col.name];
    }
  };

  const mtd_showDropdown = (row: any, item: any) => {
    switch (item.mode) {
      case "states":
        return item.values[row[item.key]];
      default:
        return true;
    }
  };

  const mtd_showButton = (row: any, play: any) => {
    if (play) {
      switch (play.type) {
        case "states":
          return play.values[row[play.name]];
        default:
          return true;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      <Tooltip id="tooltip-page-body" />
      <div className="table-responsive">
        <table role="table" className="table table-bordered table-hover">
          <thead className="table-light table-nowrap">
            <tr role="row">
              {props.table.body.cols.map((item: any, index: number) => (
                <th
                  style={{ width: item.width ?? "" }}
                  role="button"
                  key={index}
                >
                  {item.alias}
                </th>
              ))}
              <th
                style={{
                  width: props.table.body.widthAccion ?? "",
                }}
                role="button"
              >
                Acción
              </th>
            </tr>
          </thead>
          <tbody className="font-size-11">
            {dataTableState.data.map((row: any, index: number) => (
              <tr key={"row_" + index}>
                {props.table.body.cols.map((col: any, index: number) => (
                  <td key={"col_" + index}>{mtd_showTd(row, col)}</td>
                ))}
                <td>
                  {props.table.body.buttons &&
                    props.table.body.buttons.map(
                      (button: any, index: number) =>
                        button.play &&
                        mtd_showButton(row, button.play) &&
                        (button.play.type === "dropdowns" ? (
                          <div
                            className="dropdown d-inline"
                            key={"button_drpdown_" + index}
                          >
                            <button
                              data-tooltip-id="tooltip-page-body"
                              data-tooltip-content={button.tooltip}
                              className={`dropdown-toggle btn btn-xs ${button.css}`}
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className={button.icon}></i>
                              {button.text ?? ""}
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              {button.play.items.map(
                                (item: any, index: number) =>
                                  mtd_showDropdown(row, item) && (
                                    <li key={"button_drpdown_item_" + index}>
                                      <a
                                        role="button"
                                        className="dropdown-item"
                                        onClick={() =>
                                          props.onClickButtonPersonalizado(
                                            row,
                                            item.name,
                                          )
                                        }
                                      >
                                        {item.text}
                                      </a>
                                    </li>
                                  ),
                              )}
                            </ul>
                          </div>
                        ) : (
                          <a
                            data-tooltip-id="tooltip-page-body"
                            data-tooltip-content={button.tooltip}
                            key={"button_" + index}
                            onClick={() =>
                              props.onClickButtonPersonalizado(row, button.name)
                            }
                            className={button.css}
                            role="button"
                          >
                            <i className={button.icon}></i>
                            {button.text ?? ""}
                          </a>
                        )),
                    )}
                  {props.page.buttons?.create &&
                    CanCheck(props.page.model + "-edit") && (
                      <a
                        data-tooltip-id="tooltip-page-body"
                        data-tooltip-content="Editar"
                        onClick={() => props.onChangeEdit(row)}
                        className="me-3 text-primary"
                        role="button"
                      >
                        <i className="ri-pencil-line font-size-18"></i>
                      </a>
                    )}
                  {props.page.buttons?.destroy &&
                    CanCheck(props.page.model + "-delete") && (
                      <a
                        onClick={() => props.onChangeDelete(row)}
                        data-tooltip-id="tooltip-page-body"
                        data-tooltip-content="Eliminar"
                        className="text-danger"
                        role="button"
                      >
                        <i className="fa-regular fa-trash-can"></i>
                      </a>
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-center align-items-center">
        <div className="me-auto"></div>
        <div className="ms-auto">
          <div className="row align-items-center">
            <div className="col-md-auto col">
              <Pagination
                onChangePage={props.onChangePage}
                links={dataTableState.links}
                currentPage={dataTableState.meta.current_page}
                lastPage={dataTableState.meta.last_page}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BodyPage;
