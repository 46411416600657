import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
/**Models */
import { DataTable, Warehouse } from "../models";
/**Services */
import * as warehousesService from "../services/warehouses.service";
/**Hooks */
import { setLoading } from "../redux/states/loading.slice";
import {
  dataTable_setAlls,
  dataTable_clearAll,
  dataTable_addResource,
  dataTable_updateResource,
} from "../redux/states/dataTable.slice";

export function useWarehouses() {
  const dispatch = useDispatch();
  const [warehouses, setWarehouses] = useState<DataTable | null>(null);

  //GET
  const fetchWarehouses = useCallback(
    (page: number, text: string, loading: boolean) => {
      dispatch(setLoading(loading));
      warehousesService
        .getWarehouses(page, text)
        .then((response) => {
          dispatch(dataTable_setAlls(response));
          setWarehouses(response); // Actualiza el estado local si es necesario
        })
        .catch((error) => error)
        .finally(() => {
          dispatch(setLoading(false));
        });
    },
    [dispatch],
  );

  useEffect(() => {
    const resetData = () => {
      dispatch(dataTable_clearAll());
      setWarehouses(null); // Borra el estado local si es necesario
    };
    fetchWarehouses(1, "", true); // Llamada inicial al cargar el componente

    return () => {
      resetData(); // Esto se ejecutará cuando el componente se desmonte
    };
  }, [fetchWarehouses, dispatch]);

  //STORE
  const storeWarehouse = async (warehouse: Warehouse) => {
    dispatch(setLoading(true));
    try {
      const response = await warehousesService.storeWarehouse(warehouse);
      dispatch(dataTable_addResource(response.warehouse));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  //UPDATE
  const updateWarehouse = async (warehouse: Warehouse) => {
    dispatch(setLoading(true));
    try {
      const response = await warehousesService.updateWarehouse(warehouse);
      dispatch(dataTable_updateResource(response.warehouse));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  return { warehouses, fetchWarehouses, storeWarehouse, updateWarehouse };
}
