/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useState } from "react";
/**Models */
import { UserRequest } from "../../../../models/requests";
import "flatpickr/dist/l10n/es";
/**Validations */
import { useFormik } from "formik";
import * as Yup from "yup";
/**Utilities */
import { ErrorValidate, ErrorBackend } from "../../../../utilities";
import { Person } from "../../../../models";

interface FormProps {
  data: any;
  onSubmitForm: (user: UserRequest, type: string) => void;
  errors: any;
  personSearch: any;
}

export const FormAccessComponent = (props: FormProps) => {
  const [formData, setFormData] = useState<UserRequest>(
    props.data.row || {
        document_number: "",
      names: "",
      father_last_name: "",
      mother_last_name: "",
      role_id: "",
    },
  );

  const validationSchema = Yup.object().shape({
    document_number: Yup.string().required("El campo 'documento' es obligatorio."),
    names: Yup.string().required("El campo 'nombres' es obligatorio."),
    father_last_name: Yup.string().required("El campo 'apellido paterno' es obligatorio."),
    mother_last_name: Yup.string().required("El campo 'apellido materno' es obligatorio."),
    role_id: Yup.string().required("El campo 'rol' es obligatorio."),
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    formik.handleChange(event);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: () => {
      props.onSubmitForm(formData, props.data.type);
    },
  });

  const onKeyUpSearchPerson = () => {
    if (formik.values.document_number.length === 8) {
      props
        .personSearch(formik.values.document_number)
        .then((response: Person) => {
          if (response) {
            formik.setFieldValue("names", response.names);
            formik.setFieldValue("father_last_name", response.father_last_name);
            formik.setFieldValue("mother_last_name", response.mother_last_name);
            setFormData({
                ...formData,
                names: response.names??'',
                father_last_name: response.father_last_name??'',
                mother_last_name: response.mother_last_name??'',
                });
          }
        });
    }
  };

  return (
    <form
      className="form form-horizontal form-scrollable"
      onSubmit={formik.handleSubmit}
    >
      <div className="modal-body">
        <div className="form-row row">
            {/*DOCUMENTO */}
            <div className="form-group col-md-12">
                <label htmlFor="document_number" className="form-label">
                    Documento <span className="text-danger">*</span>
                </label>
                <input
                    autoComplete="off"
                    id="document_number"
                    name="document_number"
                    value={formik.values.document_number}
                    onChange={handleInputChange}
                    onKeyUp={onKeyUpSearchPerson}
                    type="text"
                    className={
                    "todo-mayuscula form-control form-control-sm" +
                    (formik.errors.document_number && formik.touched.document_number
                        ? " is-invalid"
                        : "")
                    }
                />
                <ErrorValidate state={formik.errors.document_number} />
                <ErrorBackend errorsBackend={props.errors} name="user" />
            </div>
          
          {/*NOMBRES */}
          <div className="form-group col-md-12">
            <label htmlFor="name" className="form-label">
              Nombres <span className="text-danger">*</span>
            </label>
            <input
              autoComplete="off"
              id="name"
              name="name"
              value={formik.values.names}
              onChange={handleInputChange}
              type="text"
              className={
                "todo-mayuscula form-control form-control-sm" +
                (formik.errors.names && formik.touched.names ? " is-invalid" : "")
              }
            />
            <ErrorValidate state={formik.errors.names} />
          </div>
          {/*APELLIDO PATERNO */}
            <div className="form-group col-md-12">
                <label htmlFor="father_last_name" className="form-label">
                Apellido paterno <span className="text-danger">*</span>
                </label>
                <input
                autoComplete="off"
                id="father_last_name"
                name="father_last_name"
                value={formik.values.father_last_name}
                onChange={handleInputChange}
                type="text"
                className={
                    "todo-mayuscula form-control form-control-sm" +
                    (formik.errors.father_last_name && formik.touched.father_last_name
                    ? " is-invalid"
                    : "")
                }
                />
                <ErrorValidate state={formik.errors.father_last_name} />
            </div>
            {/*APELLIDO MATERNO */}
            <div className="form-group col-md-12">
                <label htmlFor="mother_last_name" className="form-label">
                Apellido materno <span className="text-danger">*</span>
                </label>
                <input
                autoComplete="off"
                id="mother_last_name"
                name="mother_last_name"
                value={formik.values.mother_last_name}
                onChange={handleInputChange}
                type="text"
                className={
                    "todo-mayuscula form-control form-control-sm" +
                    (formik.errors.mother_last_name && formik.touched.mother_last_name
                    ? " is-invalid"
                    : "")
                }
                />
                <ErrorValidate state={formik.errors.mother_last_name} />
            </div>
          {/*ROLE */}
          <div className="form-group col-md-12">
            <label htmlFor="role_id" className="form-label">
              Rol <span className="text-danger">*</span>
            </label>
            <select
              id="role_id"
              name="role_id"
              value={formik.values.role_id}
              onChange={handleSelectChange}
              className={
                "form-control form-control-sm form-select" +
                (formik.errors.role_id && formik.touched.role_id
                  ? " is-invalid"
                  : "")
              }
            >
              <option value="">Seleccionar</option>
              {props.data.requirements?.roles?.map((stage: any) => (
                <option key={stage.id} value={stage.id}>
                  {stage.name}
                </option>
              ))}
            </select>
            <ErrorValidate state={formik.errors.role_id} />
          </div>

          <div className="col-md-12 mt-2" style={{ fontSize: "10px" }}>
            <span className="text-danger">*</span>
            <span>Campos obligatorios</span>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => props.data.onCloseModalForm()}
          className="btn btn-sm btn-secondary"
        >
          Cancelar
        </button>
        <button type="submit" className="btn btn-sm btn-primary">
          {props.data.buttonSubmit}
        </button>
      </div>
    </form>
  );
};

export default FormAccessComponent;
