import api from './api';
/**Models */
import { DashboardResponse } from '../models/responses';

export const getDashboard = async (date: string) => {
  try {
    const response = await api.get<DashboardResponse>(`/reports/dashboard?date=${date}`);
    return response;
  } catch (error) {
    throw error;
  }
};
