/* eslint-disable @typescript-eslint/no-explicit-any */
import { DataTable } from "../models";
import { UserRequest } from "../models/requests";
import { UserResponse } from "../models/responses";
import api from "./api";
/**Models */

export const getFilters = async () => {
  const response = await api.get(`/access/filters`);
  return response;
};

export const getUsers = async (
  page: number,
  text: string,
  limit: string,
  role_id: string,
) => {
  const response = await api.get<DataTable>(
    `/access/users?role_id=${role_id}&page=${page}&text=${text}&limit=${limit}`,
  );
  return response;
};

export const storeUser = async (user: UserRequest) => {
  const response = await api.post<UserResponse>("/access/users", {
    user: user,
  });
  return response;
};

export const stateUser = async (user_id: string) => {
  const response = await api.update<UserResponse>("/access/users/state", {
    user: {
      id: user_id,
    },
  });
  return response;
};

export const resetPassword = async (user_id: string) => {
  const response = await api.update<UserResponse>(
    "/access/users/reset-password",
    {
      user: {
        id: user_id,
      },
    },
  );
  return response;
};

export const getRoles = async (page: number, text: string, limit: string) => {
  const response = await api.get<DataTable>(
    `/access/roles?page=${page}&text=${text}&limit=${limit}`,
  );
  return response;
};

export const permissionsNotAssign = async (role_id: string) => {
  const response = await api.get(
    `/access/roles/${role_id}/permissions-not-assign`,
  );
  return response;
};

export const permissionsAssign = async (role_id: string) => {
  const response = await api.get(`/access/roles/${role_id}/permissions`);
  return response;
};

export const updatePermissions = async (
  role_id: string,
  permissions: string[],
  type: string,
) => {
  const response = await api.update(
    `/access/roles/${role_id}/permissions`,
    {
      permissions: {
        permissions: permissions,
        type: type,
      },
    },
  );
  return response;
};

export const changePassword = async (formData: any) => {
  const response = await api.update(
    "/access/users/change-password",
    formData,
  );

  return response;
};
