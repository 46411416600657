/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
/**DatePicker */
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect/index.js";
import "flatpickr/dist/plugins/monthSelect/style.css";
/**Moment */
import moment from "moment";
/**Hooks */
import { useReports } from "../../hooks";

export const Dashboard = () => {
  const { getDashboard } = useReports();
  const [loadedComponent, setLoadedComponent] = useState(false);
  const [dateReport, setDateReport] = useState<any>(moment().format("YYYY-MM"));
  const [ventas, setVentas] = useState<any>([]);
  const [compras, setCompras] = useState<any>([]);
  const [igv, setIgv] = useState<any>([]);

  const [dataReport, setDataReport] = useState({
    series: [],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "45%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      colors: ["#1cbb8b", "#ffc107"],
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: "S/ (Soles)",
        },
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            return "S/" + val.toFixed(2) + " soles";
          },
        },
      },
    },
  });

  const [selectedDate] = useState<any>(moment().format("YYYY-MM"));

  const handleDateChange = (selectedDates: Date[]) => {
    const fecha = moment(selectedDates[0]).format("YYYY-MM");
    setDateReport(fecha);
    getDashboard(fecha).then((response) => {
      setVentas(response.ventas);
      setCompras(response.compras);
      setIgv(response.igv);
      setDataReport({
        ...dataReport,
        series: response.series,
        options: {
          ...dataReport.options,
          xaxis: {
            categories: response.categories,
          },
        },
      });
      setLoadedComponent(true);
    });
  };

  //Data inicial [GET]
  useEffect(() => {
    const dataInicial = () => {
      getDashboard(dateReport).then((response) => {
        setVentas(response.ventas);
        setCompras(response.compras);
        setIgv(response.igv);
        setDataReport({
          ...dataReport,
          series: response.series,
          options: {
            ...dataReport.options,
            xaxis: {
              categories: response.categories,
            },
          },
        });
        setLoadedComponent(true);
      });
    };

    if (!loadedComponent) {
      dataInicial();
    }
  }, [dataReport, dateReport, getDashboard, loadedComponent]);

  return (
    <div className="main-body p-0">
      <div className="inner-wrapper">
        <div className="inner-main">
          <div
            className="inner-main-header"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <h5 style={{ fontWeight: "bold" }} className="me-3">
              DASHBOARD
            </h5>
            <div className="ms-auto">
              <ol className="breadcrumbs me-3">
                <li>
                  <a role="button">
                    <i className="ri-home-8-line"></i>
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a role="button">Dashboard</a>
                </li>
              </ol>
            </div>
          </div>
          <div className="inner-main-body">
            <div className="row justify-content-center">
              <div className="col-md-12 mb-3">
                <div className="row justify-content-center">
                  <label
                    htmlFor="example-text-input"
                    style={{ marginTop: "0.2rem" }}
                    className="col-md-1 form-label text-end"
                  >
                    Periodo
                  </label>
                  <div className="col-md-2">
                    <Flatpickr
                      name="fecha"
                      id="fecha"
                      value={selectedDate}
                      onChange={handleDateChange}
                      className="form-control form-control-sm"
                      options={{
                        dateFormat: "Y-m",
                        locale: "es",
                        plugins: [
                          new (monthSelectPlugin as any)({
                            shorthand: true,
                            dateFormat: "Y-m",
                            altFormat: "Y-m",
                          }),
                        ],
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          VENTAS
                        </p>
                        <h4 className="mb-0 fw-bold">
                          S/{ventas.total?.toFixed(2)}
                        </h4>
                      </div>
                      <div className="text-primary">
                        <i className="ri-store-line font-size-24"></i>
                      </div>
                    </div>
                  </div>
                  <div className="border-top-card py-3 card-body">
                    <div className="text-truncate">
                      <span className="badge bg-success-subtle text-success font-size-11 me-1">
                        <i className="mdi mdi-menu-up"> </i> IGV en ventas
                      </span>
                      <span className="text-muted ms-2">
                        S/{ventas.igv?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          COMPRAS
                        </p>
                        <h4 className="mb-0 fw-bold">
                          S/{compras.total?.toFixed(2)}
                        </h4>
                      </div>
                      <div className="text-warning">
                        <i className="ri-shopping-cart-2-line font-size-24"></i>
                      </div>
                    </div>
                  </div>
                  <div className="border-top-card py-3 card-body">
                    <div className="text-truncate">
                      <span className="badge bg-warning-subtle text-warning font-size-11 me-1">
                        <i className="mdi mdi-menu-up"> </i> IGV en compras
                      </span>
                      <span className="text-muted ms-2">
                        S/{compras.igv?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-1 overflow-hidden">
                        <p className="text-truncate font-size-14 mb-2">
                          IGV A PAGAR
                        </p>
                        <h4 className="mb-0 fw-bold">
                          S/{igv.total?.toFixed(2)}
                        </h4>
                      </div>
                      <div className="text-info">
                        <i className="ri-money-dollar-circle-line font-size-24"></i>
                      </div>
                    </div>
                  </div>
                  <div className="border-top-card py-3 card-body">
                    <div className="text-truncate">
                      <span className="badge bg-info-subtle text-info font-size-11 me-1">
                        <i className="mdi mdi-menu-up"> </i> Renta
                      </span>
                      <span className="text-muted ms-2">
                        S/{igv.renta?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className="card">
                  <div className="card-body">
                    <h6>Ventas vs Compras - Últimos 12 meses</h6>
                    <ReactApexChart
                      options={dataReport.options}
                      series={dataReport.series}
                      type="bar"
                      height={350}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
