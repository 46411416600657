import { ChangeEvent } from "react";

interface Props {
  requirements: any;
  detraccion: any;
  formik: any;
  handleSelectDetraccion: (fieldName: string, value: string) => void;
  itsCta: boolean;
}
const Detracciones = (props: Props) => {
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    props.handleSelectDetraccion(event.target.name, event.target.value);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.handleSelectDetraccion(event.target.name, event.target.value);
  };

  return (
    <div className="row border-sales mt-3">
      <div className="col-md-12">
        <h6 className="text-center">
          Por indicaciones de la SUNAT la Detracción se envía en Soles, según el
          T/C indicado.
        </h6>
      </div>
      <div className="col-md-8">
        <label htmlFor="tipo">Tipo detracción</label>
        <select
          value={props.detraccion.tipo}
          className="form-control form-control-sm form-select"
          onChange={handleSelectChange}
          name="tipo"
          id="tipo"
        >
          <option value="" disabled={true}>
            Seleccionar
          </option>
          {props.requirements?.type_detracctions.map((item: any) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      <div className="col-md-4">
        <label htmlFor="porcentaje">Porcentaje de detracción</label>
        <input
          value={props.detraccion.porcentaje}
          onChange={handleInputChange}
          type="number"
          className="form-control form-control-sm"
          name="porcentaje"
          id="porcentaje"
        />
      </div>
      <div className="col-md-8 mt-2">
        <label htmlFor="medioPago">Medio de pago</label>
        <select
          value={props.detraccion.medioPago}
          onChange={handleSelectChange}
          className="form-control form-control-sm form-select"
          name="medioPago"
          id="medioPago"
        >
          <option value="" disabled={true}>
            Seleccionar
          </option>
          {props.requirements?.payment_methods.map((item: any) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      <div className="col-md-4 mt-2">
        <label htmlFor="monto">Total de detracción S/</label>
        <input
          value={props.detraccion.monto}
          onChange={handleInputChange}
          disabled
          readOnly
          type="number"
          className="form-control form-control-sm"
          name="monto"
          id="monto"
        />
      </div>
      {props.detraccion.itsCta && (
        <div className="col-md-12 mt-2">
          <label htmlFor="cta">Nro. Cta Banco de la Nación</label>
          <input
            value={props.detraccion.cta}
            onChange={handleInputChange}
            type="text"
            className="form-control form-control-sm"
            name="cta"
            id="cta"
          />
        </div>
      )}
    </div>
  );
};

export default Detracciones;
