import { ChangeEvent, useState } from 'react';
/**Models */
import { Category } from '../../../models';
/**Validations */
import { useFormik } from 'formik';
/**Utilities */
import { ErrorValidate, ErrorBackend } from '../../../utilities';
import * as Yup from 'yup';

interface FormProps {
  data: any;
  onSubmitForm: (category: Category, type: string) => void;
  errors: any;
}

export const FormCategory = (props: FormProps) => {
  const [formData, setFormData] = useState<Category>(
    props.data.row || {
      name: '',
    },
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("El campo 'nombre' es obligatorio."),
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: () => {
      props.onSubmitForm(formData, props.data.type);
    },
  });

  return (
    <form className="form form-horizontal form-scrollable" onSubmit={formik.handleSubmit}>
      <div className="modal-body">
        <div className="form-row">
          <div className="form-group col-md-12">
            <label className="form-label">
              Nombre <span className="text-danger">*</span>
            </label>
            <input
              autoComplete='off'
              name="name"
              value={formik.values.name}
              onChange={handleInputChange}
              type="text"
              className={'todo-mayuscula form-control form-control-sm' + (formik.errors.name && formik.touched.name ? ' is-invalid' : '')}
            />
            <ErrorValidate state={formik.errors.name} />
            <ErrorBackend errorsBackend={props.errors} name="category" />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="submit" className="btn btn-sm btn-primary">
          {props.data.buttonSubmit}
        </button>
        <button type="button" onClick={() => props.data.onCloseModalForm()} className="btn btn-sm btn-secondary">
          Cancelar
        </button>
      </div>
    </form>
  );
};

export default FormCategory;
