interface MenuItem {
  name: string;
  path: string;
  icon?: string;
  permission?: string;
  permissions?: string[];
  submenu?: MenuItem[];
}

const Menu: MenuItem[] = [
  {
    name: 'Dashboard',
    path: '/',
    icon: 'ri-home-8-line',
    permission: 'home-index',
  },
  {
    name: 'Cotizaciones',
    icon: 'ri-file-list-3-line',
    permissions: ['quotes-store', 'quotes-history'],
    path: '',
    submenu: [
      {
        name: 'Generar',
        path: '/quotes-store',
        permission: 'quotes-store',
      },
      {
        name: 'Historial',
        path: '/quotes-history',
        permission: 'quotes-history',
      },
    ],
  },
  {
    name: 'Ventas',
    icon: 'ri-store-line',
    permissions: ['sales-store', 'sales-history'],
    path: '',
    submenu: [
      {
        name: 'Generar',
        path: '/sales-store',
        permission: 'sales-store',
      },
      {
        name: 'Historial',
        path: '/sales-history',
        permission: 'sales-history',
      },
    ],
  },
  {
    name: 'Compras',
    icon: 'ri-shopping-cart-2-line',
    permissions: ['purchases-store', 'purchases-history'],
    path: '',
    submenu: [
      {
        name: 'Generar',
        path: '/purchases-store',
        permission: 'purchases-store',
      },
      {
        name: 'Historial',
        path: '/purchases-history',
        permission: 'purchases-history',
      },
    ],
  },
  {
    name: 'Inventario',
    icon: 'ri-box-1-line',
    permissions: ['inventory-kardex', 'inventory-settings', 'inventory-warehouses'],
    path: '',
    submenu: [
      {
        name: 'Kardex',
        path: '/inventory-kardex',
        permission: 'inventory-kardex',
      },
      {
        name: 'Ajustes',
        path: '/inventory-settings',
        permission: 'inventory-settings',
      },
      {
        name: 'Almacenes',
        path: '/inventory-warehouses',
        permission: 'inventory-warehouses',
      },
    ],
  },
  {
    name: 'Guías de remisión',
    icon: 'ri-file-list-2-line',
    permissions: ['despatches-store', 'despatches-history'],
    path: '',
    submenu: [
      {
        name: 'Generar',
        path: '/despatches-store',
        permission: 'despatches-store',
      },
      {
        name: 'Historial',
        path: '/despatches-history',
        permission: 'despatches-history',
      },
    ],
  },
  {
    name: 'SUNAT',
    icon: 'ri-contract-line',
    permissions: ['sunat-notes-index'],
    path: '',
    submenu: [
      {
        name: 'Notas C/D',
        path: '/sunat-notes',
        permission: 'sunat-notes-index',
      },
    ],
  },
  {
    name: 'Libros electrónicos',
    icon: 'ri-book-2-line',
    permissions: ['electronic-books-purchases-index', 'electronic-books-sales-index'],
    path: '',
    submenu: [
      {
        name: 'Ventas 14.1',
        path: '/electronic-books-sales',
        permission: 'electronic-books-sales-index',
      },
      {
        name: 'Compras 8.1',
        path: '/electronic-books-purchases',
        permission: 'electronic-books-purchases-index',
      },
    ],
  },
  {
    name: 'Mantenimientos',
    icon: 'ri-markup-line',
    permissions: ['clients-index', 'suppliers-index'],
    path: '',
    submenu: [
      {
        name: 'Clientes',
        path: '/clients',
        permission: 'clients-index',
      },
      {
        name: 'Proveedores',
        path: '/suppliers',
        permission: 'suppliers-index',
      }
    ],
  },
  {
    name: 'Productos',
    icon: 'ri-archive-2-line',
    permissions: ['products-index', 'products-categories-index', 'products-brands-index', 'products-presentations-index'],
    path: '',
    submenu: [
      {
        name: 'Tarifarios',
        path: '/products-tariffs',
        permission: 'products-tariffs-index',
      },
      {
        name: 'Productos',
        path: '/products',
        permission: 'products-index',
      },
      {
        name: 'Presentaciones',
        path: '/products-presentations',
        permission: 'products-presentations-index',
      },
      {
        name: 'Marcas',
        path: '/products-brands',
        permission: 'products-brands-index',
      },
      {
        name: 'Categorías',
        path: '/products-categories',
        permission: 'products-categories-index',
      },
    ],
  },
  {
    name: "Gestión accesos",
    icon: "ri-group-line",
    permissions: ["access-users-index", "access-roles-index"],
    path: "",
    submenu: [
      {
        name: "Usuarios",
        path: "/access-users",
        permission: "access-users-index",
      },
      {
        name: "Roles",
        path: "/access-roles",
        permission: "access-roles-index",
      },
    ],
  },
  {
    name: "Cambiar contraseña",
    path: "/change-password",
    icon: "ri-lock-line",
    permission: "change-password",
  },
];

export default Menu;
