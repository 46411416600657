import { ChangeEvent, useState } from "react";
/**Select */
import Select from "react-select";
/**Models */
import { Tariff } from "../../../models";
/**Validations */
import { useFormik } from "formik";
/**Utilities */
import { ErrorValidate, ErrorBackend } from "../../../utilities";
import * as Yup from "yup";

interface FormProps {
  data: any;
  onSubmitForm: (tariff: Tariff, type: string) => void;
  errors: any;
  productSearch: any;
  clientSearch: any;
}

interface OptionSelect {
  label: string;
  value: string;
  precio_unitario: string;
}

export const FormTariff = (props: FormProps) => {
  const [optionsProducts, setOptionsProducts] = useState<OptionSelect[]>([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [optionsClients, setOptionsClients] = useState<OptionSelect[]>([]);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [formData, setFormData] = useState<Tariff>(
    props.data.row || {
      model_type: "App\\Models\\Client",
      model_id: "",
      product_id: "",
      sale_price: "",
    },
  );

  const validationSchema = Yup.object().shape({
    model_type: props.data.type === "store" ? Yup.string().required("El campo 'Modelo' es obligatorio.") : Yup.string(),
    model_id: props.data.type === "store" ? Yup.string().required("El campo 'Cliente' es obligatorio.") : Yup.string(),
    product_id: props.data.type === "store" ? Yup.string().required("El campo 'Producto' es obligatorio.") : Yup.string(),
    sale_price: Yup.number().required(
      "El campo 'Precio de venta' es obligatorio.",
    ),
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: () => {
      props.onSubmitForm(formData, props.data.type);
    },
  });

  const handleProductSearchChange = (value: string) => {
    if (value.length >= 3) {
      props
        .productSearch(value, "")
        .then((response: any) => {
          setIsLoadingProducts(true);
          setOptionsProducts(response.data);
        })
        .finally(() => {
          setIsLoadingProducts(false);
        });
    }
  };

  const handleProductSelectChange = (selectedOption: OptionSelect) => {
    formik.setFieldValue("product_id", selectedOption.value);
    formik.setFieldValue("sale_price", selectedOption.precio_unitario);
    setFormData({
      ...formData,
      product_id: selectedOption.value,
      sale_price: selectedOption.precio_unitario,
    });
  };

  const handleClientSearchChange = (value: string) => {
    if (value.length >= 3) {
      props
        .clientSearch(value, "JURIDICA")
        .then((response: any) => {
          setIsLoadingClients(true);
          setOptionsClients(response.data);
        })
        .finally(() => {
          setIsLoadingClients(false);
        });
    }
  };

  const handleClientSelectChange = (selectedOption: OptionSelect) => {
    formik.setFieldValue("selectedClient", selectedOption);
    formik.setFieldValue("model_id", selectedOption.value);
    setFormData({
      ...formData,
      model_id: selectedOption.value,
    });
  };

  return (
    <form
      className="form form-horizontal form-scrollable"
      onSubmit={formik.handleSubmit}
    >
      <div className="modal-body">
        <div className="form-row row">
          {/*CLIENTE*/}
          {props.data.type === "store" && (
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="model_id" className="form-label">
                  Cliente<span className="text-danger">*</span>
                </label>
                <Select
                  isLoading={isLoadingClients}
                  placeholder={"Buscar cliente..."}
                  options={optionsClients}
                  onInputChange={handleClientSearchChange}
                  noOptionsMessage={() => "No se encontraron resultados"}
                  value={formik.values.selectedClient}
                  onChange={(selectedOption) =>
                    handleClientSelectChange(selectedOption)
                  }
                  className={
                    "" +
                    (formik.errors.model_id && formik.touched.model_id
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorValidate state={formik.errors.model_id} />
                <ErrorBackend
                  errorsBackend={props.errors}
                  name="tariff.model_id"
                />
              </div>
            </div>
          )}
          {/*PRODUCTO */}
          {props.data.type === "store" && (
            <div className="form-group col-md-12">
              <label htmlFor="product_id" className="form-label">
                Producto<span className="text-danger">*</span>
              </label>
              <Select
                isLoading={isLoadingProducts}
                placeholder={"Buscar producto..."}
                options={optionsProducts}
                onInputChange={handleProductSearchChange}
                noOptionsMessage={() => "No se encontraron resultados"}
                onChange={(selectedOption) =>
                  handleProductSelectChange(selectedOption)
                } // Handle Select change
                className={
                  "" +
                  (formik.errors.product_id && formik.touched.product_id
                    ? " is-invalid"
                    : "")
                }
                value={formik.values.selectedProduct}
              />
              <ErrorValidate state={formik.errors.product_id} />
              <ErrorBackend
                errorsBackend={props.errors}
                name="tariff.product_id"
              />
            </div>
          )}
          {/*PRECIO VENTA */}
          <div className="form-group col-md-12">
            <label htmlFor="sale_price" className="form-label">
              Precio de venta<span className="text-danger">*</span>
            </label>
            <input
              autoComplete="off"
              id="sale_price"
              name="sale_price"
              value={formik.values.sale_price}
              onChange={handleInputChange}
              type="number"
              className={
                "form-control form-control-sm" +
                (formik.errors.sale_price && formik.touched.sale_price
                  ? " is-invalid"
                  : "")
              }
            />
            <ErrorValidate state={formik.errors.sale_price} />
            <ErrorBackend
              errorsBackend={props.errors}
              name="tariff.sale_price"
            />
            <ErrorBackend errorsBackend={props.errors} name="tariff" />
          </div>
          <div className="col-md-12 mt-2" style={{ fontSize: "10px" }}>
            <span className="text-danger">*</span>
            <span>Campos obligatorios</span>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="submit" className="btn btn-sm btn-primary">
          {props.data.buttonSubmit}
        </button>
        <button
          type="button"
          onClick={() => props.data.onCloseModalForm()}
          className="btn btn-sm btn-secondary"
        >
          Cancelar
        </button>
      </div>
    </form>
  );
};

export default FormTariff;
