import { useEffect, useState } from "react";
/**Utilities */
import { ErrorBackendApi } from "../../../utilities";
/**Select */
import Select from "react-select";
/**DatePicker */
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/l10n/es";
/**Moment */
import moment from "moment";

interface Props {
  title: string;
  name: string;
  formik: any;
  clientName?: string;
  handleSelectChange: any;
  handleInputChange: any;
  getRequirements: any;
  clientSearch?: any;
  itsCliente: boolean;
  handleSelectChangeInHeaderSale: (value: string, fieldName: string) => void;
  handleSelectSupplier: (clienteId: string) => void;
  errors: any;
  type: string;
}

interface OptionSelect {
  label: string;
  value: string;
  personal_mail: string;
}

const HeaderPurchase = (props: Props) => {
  //Flatpickr
  const [selectedDate, setSelectedDate] = useState<Date | string>(
    props.formik.values.fechaEmision,
  );
  const [selectedDateVencimiento, setSelectedDateVencimiento] = useState<
    Date | string
  >(props.formik.values.fechaVencimiento);
  //ReactSelect - Clientes
  const [optionsClients, setOptionsClients] = useState<OptionSelect[]>([]);
  const [isLoadingClients, setIsLoadingClients] = useState(false);

  const handleDateChange = (selectedDates: Date[]) => {
    setSelectedDate(selectedDates[0]);
    const fecha = moment(selectedDates[0]).format("YYYY-MM-DD");
    props.handleSelectChangeInHeaderSale("fechaEmision", fecha);
  };

  const handleDateChangeVencimiento = (selectedDates: Date[]) => {
    setSelectedDateVencimiento(selectedDates[0]);
    const fecha = moment(selectedDates[0]).format("YYYY-MM-DD");
    props.handleSelectChangeInHeaderSale("fechaVencimiento", fecha);
  };

  const handleClientSearchChange = (value: string) => {
    let type = "";
    if (props.formik.values.type_document_id === "2") {
      type = "JURIDICA";
    } else {
      type = "";
    }
    if (value.length >= 3) {
      props
        .clientSearch(value, type)
        .then((response: any) => {
          setIsLoadingClients(true);
          setOptionsClients(response.data);
        })
        .finally(() => {
          setIsLoadingClients(false);
        });
    }
  };

  const handleClientSelectChange = (selectedOption: OptionSelect) => {
    props.formik.setFieldValue("selectedClient", selectedOption);
    props.handleSelectSupplier(selectedOption.value);
  };

  const handleCheckboxInventario = (event: any) => {
    props.handleSelectChangeInHeaderSale("inventario", event.target.checked);
  };

  const hasError = (propertyName: string) => {
    return props.errors && props.errors[propertyName];
  };

  useEffect(() => {
    setSelectedDate(
      moment(props.formik.values.fechaEmision).format("YYYY-MM-DD"),
    );
    setSelectedDateVencimiento(
      moment(props.formik.values.fechaVencimiento).format("YYYY-MM-DD"),
    );
  }, [props.formik.values.fechaEmision, props.formik.values.fechaVencimiento]);

  return (
    <div className="row">
      <div className="col-md-12">
        {props.type === "store" && (
          <h4 className="text-center">Generar nueva compra</h4>
        )}
        {props.type === "edit" && (
          <h4 className="text-center">Editar compra - {props.name}</h4>
        )}
      </div>
      {/* Tipo de nota de credito */}
      {props.title === "credit" && (
        <div className="form-group col-md-3">
          <label htmlFor="type_note_credit_id" className="form-label">
            Tipo Nota de Crédito
          </label>
          <select
            className={
              "form-control form-control-sm form-select" +
              (hasError("purchase.type_note_credit_id") &&
              props.formik.values.type_note_credit_id === ""
                ? " is-invalid"
                : "")
            }
            onChange={props.handleSelectChange}
            value={props.formik.values.type_note_credit_id}
            name="type_note_credit_id"
            id="type_note_credit_id"
          >
            <option disabled value="">
              Seleccionar
            </option>
            {props.getRequirements?.type_note_credits.map((item: any) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
          <ErrorBackendApi
            errorsBackend={props.errors}
            name="purchase.type_note_credit_id"
          />
        </div>
      )}

      {/* Tipo de operación*/}
      <div className="form-group col-md-2">
        <label htmlFor="operation_type_id" className="form-label">
          Tipo operación
        </label>
        <select
          className={
            "form-control form-control-sm form-select" +
            (hasError("purchase.operation_type_id") &&
            props.formik.values.operation_type_id === ""
              ? " is-invalid"
              : "")
          }
          onChange={props.handleSelectChange}
          value={props.formik.values.operation_type_id}
          name="operation_type_id"
          id="operation_type_id"
        >
          <option disabled value="">
            Seleccionar
          </option>
          {props.getRequirements?.operation_types.map((item: any) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
        <ErrorBackendApi
          errorsBackend={props.errors}
          name="purchase.operation_type_id"
        />
      </div>

      {/* Tipo documento*/}
      <div className="form-group col-md-2">
        <label htmlFor="type_document_id" className="form-label">
          Tipo documento
        </label>
        <select
          className={
            "form-control form-control-sm form-select" +
            (hasError("purchase.type_document_id") &&
            props.formik.values.type_document_id === ""
              ? " is-invalid"
              : "")
          }
          onChange={props.handleSelectChange}
          value={props.formik.values.type_document_id}
          name="type_document_id"
          id="type_document_id"
        >
          <option disabled value="">
            Seleccionar
          </option>
          {props.getRequirements?.type_documents.map((item: any) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
        <ErrorBackendApi
          errorsBackend={props.errors}
          name="purchase.type_document_id"
        />
      </div>

      {/* Serie*/}
      <div className="form-group col-md-2">
        <label htmlFor="serie" className="form-label">
          Serie
        </label>
        <input
          autoComplete="off"
          type="text"
          className={
            "todo-mayuscula form-control form-control-sm" +
            (hasError("purchase.serie") && props.formik.values.serie === ""
              ? " is-invalid"
              : "")
          }
          name="serie"
          id="serie"
          value={props.formik.values.serie}
          onChange={props.handleInputChange}
        />
        <ErrorBackendApi errorsBackend={props.errors} name="purchase.serie" />
      </div>

      {/* Correlativo*/}
      <div className="form-group col-md-2">
        <label htmlFor="correlativo" className="form-label">
          Correlativo
        </label>
        <input
          type="text"
          autoComplete="off"
          className={
            "todo-mayuscula form-control form-control-sm" +
            (hasError("purchase.correlativo") &&
            props.formik.values.correlativo === ""
              ? " is-invalid"
              : "")
          }
          name="correlativo"
          id="correlativo"
          value={props.formik.values.correlativo}
          onChange={props.handleInputChange}
        />
        <ErrorBackendApi
          errorsBackend={props.errors}
          name="purchase.correlativo"
        />
      </div>

      {/* Proveedor*/}
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="supplier_id" className="form-label">
            Proveedor
          </label>
          {props.itsCliente ? (
            <Select
              isDisabled={props.formik.values.type_document_id === ""}
              isLoading={isLoadingClients}
              placeholder={"Buscar proveedor..."}
              options={optionsClients}
              onInputChange={handleClientSearchChange}
              noOptionsMessage={() => "No se encontraron resultados"}
              value={props.formik.values.selectedClient}
              onChange={(selectedOption) =>
                handleClientSelectChange(selectedOption)
              }
            />
          ) : (
            <input
              disabled
              readOnly
              value={props.clientName}
              type="text"
              className="form-control form-control-sm"
            />
          )}
        </div>
      </div>
      {/*IGV*/}
      <div className="form-group col-md-2">
        <label htmlFor="igv" className="form-label">
          IGV %
        </label>
        <select
          className={
            "form-control form-control-sm form-select" +
            (hasError("purchase.igv") && props.formik.values.igv === ""
              ? " is-invalid"
              : "")
          }
          onChange={props.handleSelectChange}
          value={props.formik.values.igv}
          name="igv"
          id="igv"
        >
          <option value="0.18">18%</option>
          <option value="0.10">10% (Ley 31566)</option>
        </select>
      </div>
      {/* Fecha emisión*/}
      <div className="col-md-2">
        <div className="form-group">
          <label htmlFor="date_issue" className="form-label">
            Fecha emisión
          </label>
          <Flatpickr
            value={selectedDate}
            onChange={handleDateChange}
            className="form-control form-control-sm"
            options={{
              dateFormat: "Y-m-d",
              locale: "es",
            }}
          />
        </div>
      </div>
      {/* Fecha vencimiento*/}
      <div className="col-md-2">
        <div className="form-group">
          <label htmlFor="fechaVencimiento" className="form-label">
            Fecha vencimiento
          </label>
          <Flatpickr
            value={selectedDateVencimiento}
            onChange={handleDateChangeVencimiento}
            className="form-control form-control-sm"
            options={{
              dateFormat: "Y-m-d",
              locale: "es",
            }}
          />
        </div>
      </div>
      {/* Moneda*/}
      <div className="form-group col-md-2">
        <label htmlFor="currency_id" className="form-label">
          Moneda
        </label>
        <select
          className="form-control form-control-sm form-select"
          name="currency_id"
          id="currency_id"
        >
          <option value="PEN">SOLES</option>
        </select>
      </div>
      {/* Inventario*/}
      <div className="form-group col-md-2">
        <label htmlFor="square-switch1">¿Inventario?</label>
        <div className="col-md-1">
          <div className="square-switch">
            <input
              checked={props.formik.values.inventario}
              onChange={handleCheckboxInventario}
              type="checkbox"
              id={"square-inventario"}
            />
            <label
              style={{ marginBottom: 0 }}
              htmlFor={"square-inventario"}
              data-on-label="Si"
              data-off-label="No"
            ></label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderPurchase;
