/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChangeEvent, useEffect, useState } from "react";
/**Validations */
import { useFormik } from "formik";
import * as Yup from "yup";
/**DatePicker */
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/l10n/es";
/**Moment */
import moment from "moment";
/**Models */
import {
  InventorySettingItemRequest,
  InventorySettingRequest,
} from "../../../models/requests";
/**Components */
import ItemsHeaderComponent from "./components/items-header.component";
import ItemsBodyComponent from "./components/items-body.component";
/**Hooks */
import { useInventories } from "../../../hooks";
/**Utilities */
import { ErrorValidate, SweetAlert } from "../../../utilities";

export const InventorySettingsIndex = () => {
  const { productSearch, getRequirements, storeInventory } = useInventories();
  const [loaded, setLoaded] = useState(false);
  const [requirements, setRequirements] = useState<any>([]);
  const [products_not, setProductsNot] = useState<any>([]);
  const [backendErrors, setBackendErros] = useState(null);
  //DATA DEFAULT
  const defaultFormData: InventorySettingRequest = {
    date: moment().format("YYYY-MM-DD"),
    warehouse_id: "",
    items: [],
  };
  //FormData
  const [formData, setFormData] =
    useState<InventorySettingRequest>(defaultFormData);
  const validationSchema = Yup.object().shape({
    date: Yup.string().required("La fecha es requerida"),
    warehouse_id: Yup.string().required("El almacén es requerido"),
  });

  /*METODOS DEL COMPONENTE */
  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: async () => {
      console.log(formData);
      if (formData.items.length === 0) {
        SweetAlert.warning("Mensaje", "Agregue al menos un producto.");
        return;
      }

      storeInventory(formData)
        .then((res: any) => {
          SweetAlert.success(
            "Mensaje",
            "Ajuste de inventario generado correctamente.",
          );
          setFormData(defaultFormData);
          setBackendErros(null);
          formik.setValues(defaultFormData);
          setProductsNot([]);
        })
        .catch((error: any) => {
          setBackendErros(error.response.data.errors);
        });
    },
  });

  const onClickAddItem = () => {
    if (formik.values.warehouse_id === "") {
      SweetAlert.warning("Mensaje", "Seleccione un almacén");
      return;
    }
    const item: InventorySettingItemRequest = {
      warehouse_product_id: "",
      data: "",
      type_setting_id: "",
      quantity: "",
      stock: 0,
      final_quantity: 0,
      adjusted_total: 0,
      purchase_price: 0,
    };
    setFormData({ ...formData, items: [...formData.items, item] });
  };

  const onClickDeleteItem = (index: number) => {
    const updatedItems = formData.items.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      items: updatedItems,
    });
  };

  const handleDateChange = (selectedDates: Date[]) => {
    const fecha = moment(selectedDates[0]).format("YYYY-MM-DD");
    formik.setFieldValue("date", fecha);
    setFormData({ ...formData, date: fecha });
  };
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    formik.handleChange(event);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleInputChange = (index: number, field: string, value: string) => {
    const updatedItems = [...formData.items];
    updatedItems[index][field] = value;
    if (
      updatedItems[index].purchase_price >= 0 &&
      parseFloat(updatedItems[index].quantity.toString()) >= 0
    ) {
      switch (updatedItems[index].type_setting_id) {
        case "INVENTARIO_INICIAL":
          updatedItems[index].final_quantity = parseFloat(
            updatedItems[index].quantity.toString(),
          );
          break;
        case "INCREMENTO":
          updatedItems[index].final_quantity =
            updatedItems[index].stock +
            parseFloat(updatedItems[index].quantity.toString());
          break;
        case "DISMINUCION":
          updatedItems[index].final_quantity =
            updatedItems[index].stock -
            parseFloat(updatedItems[index].quantity.toString());
          break;
        default:
          break;
      }
      updatedItems[index].adjusted_total =
        updatedItems[index].final_quantity * updatedItems[index].purchase_price;
    }
    setFormData({ ...formData, items: updatedItems });
  };

  //DATA INICIAL
  useEffect(() => {
    const dataInicial = () => {
      getRequirements().then((res: any) => {
        setRequirements(res);
        setLoaded(true);
      });
    };

    if (!loaded) {
      dataInicial();
    }
  }, [loaded, getRequirements]);
  return (
    <div className="main-body p-0">
      <div className="inner-wrapper">
        <div className="inner-main">
          <div className="inner-main-body">
            <div className="card card-featured card-featured-primary mb-3 mt-3">
              <div className="card-body">
                <form className="form" onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="text-center">Ajuste de inventario</h4>
                    </div>
                    {/* Fecha ajuste*/}
                    <div className="col-md-2">
                      <div className="form-group">
                        <label htmlFor="date_issue" className="form-label">
                          Fecha ajuste
                        </label>
                        <Flatpickr
                          value={formik.values.date}
                          onChange={handleDateChange}
                          className="form-control form-control-sm"
                          options={{
                            dateFormat: "Y-m-d",
                            locale: "es",
                          }}
                        />
                      </div>
                    </div>
                    {/* Almacen*/}
                    <div className="form-group col-md-3">
                      <label htmlFor="warehouse_id" className="form-label">
                        Almacen
                      </label>
                      <select
                        className={
                          "form-control form-control-sm form-select" +
                          (formik.errors.warehouse_id &&
                          formik.touched.warehouse_id
                            ? " is-invalid"
                            : "")
                        }
                        onChange={handleSelectChange}
                        value={formik.values.warehouse_id}
                        name="warehouse_id"
                        id="warehouse_id"
                      >
                        <option disabled value="">
                          Seleccionar
                        </option>
                        {requirements?.warehouses?.map((item: any) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <ErrorValidate state={formik.errors.warehouse_id} />
                    </div>
                  </div>
                  {/* Items */}
                  <div className="row border-sales">
                    <div className="title-items">
                      Ítems de venta{" "}
                      <a
                        role="button"
                        onClick={() => onClickAddItem()}
                        className="add-item-sales"
                      >
                        <i className="ri-add-line"></i>
                      </a>
                    </div>
                    <div className="col-md-12">
                      <ItemsHeaderComponent />
                    </div>
                    {/* Array items*/}
                    <ItemsBodyComponent
                      items={formData.items}
                      productSearch={productSearch}
                      handleInputChange={handleInputChange}
                      requirements={requirements}
                      onClickDeleteItem={onClickDeleteItem}
                      warehouseId={formik.values.warehouse_id}
                      setProductsNot={setProductsNot}
                      products_not={products_not}
                      backendErrors={backendErrors}
                    />
                  </div>
                  <div className="row d-flex justify-content-center mt-5">
                    <div className="d-grid col-md-4">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-block"
                      >
                        Generar ajuste de inventario
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventorySettingsIndex;
