import { ChangeEvent } from 'react';

interface Props {
  onFilterChange: (filters: any) => void;
  onClearFilters: () => void;
  filterState: any;
}

export const Filter = (props: Props) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedFilters = { ...props.filterState, text: event.target.value };
    props.onFilterChange(updatedFilters);
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const updatedFilters = { ...props.filterState, [event.target.name]: event.target.value };
    props.onFilterChange(updatedFilters);
  };

  return (
    <div className="mb-3 row">
      <div className="col-md-3">
        <label htmlFor="type">Tipo de cliente</label>
        <select
          name="type"
          value={props.filterState.type}
          onChange={handleSelectChange}
          className="form-control form-control-sm form-select"
          id="type"
        >
          <option value="">Todos</option>
          <option value="NATURAL">NATURAL</option>
          <option value="JURIDICA">JURÍDICA</option>
        </select>
      </div>
      <div className="col-md-3">
        <label htmlFor="text">Cliente o número de documento</label>
        <div role="group" className="btn-group">
          <input
            autoComplete='off'
            name="text"
            value={props.filterState.text}
            onChange={handleInputChange}
            type="text"
            className="form-control form-control-sm"
            id="text"
          />
          <button onClick={() => props.onClearFilters()} className="btn btn-sm btn-primary">
            Limpiar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
