import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
/**Components */
import HeaderPage from "../../../components/page/header";
import BodyPage from "../../../components/page/body";
import ModalComponent from "../../../components/modal";
/**Hooks */
import { usePurchases } from "../../../hooks";
/** Componentes propios */
import Filter from "./Filter";
import InfoSunatModal from "./InfoSunatModal";
/**Utilities */
import { SweetAlert } from '../../../utilities';

/** Modal Resource */
interface DataModalState {
  size: string;
  type: string;
  buttonSubmit: string | null;
  row: any | null;
  title: string | null;
  requirements: any[];
  onCloseModalForm: () => void;
  errosBackend: any;
}

export const PurchasesHistory = () => {
  const navigate = useNavigate();
  const { fetchPurchases, getRequirements, deletePurchase } = usePurchases();
  const [filterState, setFilterState] = useState({
    page: 1,
    text: "",
    typeDocument: "",
    dateInitial: "",
    dateFinal: "",
    selectedDates: [],
  });
  //Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataModalResourceState, setDataModalResourceState] =
    useState<DataModalState>({
      size: "",
      type: "",
      buttonSubmit: null,
      row: null,
      title: null,
      requirements: [],
      onCloseModalForm: () => {},
      errosBackend: {},
    });
  const [stateModal, setStateModal] = useState<boolean>(false);
  const [componentModal, setComponentModal] = useState<any>(null);
  const [requirements, setRequirements] = useState<any | null>(null);
  const [loadedComponent, setLoadedComponent] = useState(false);

  //DATA TABLE
  const state = {
    page: {
      title: "Historial de compras",
      icon: "ri-shopping-cart-2-line",
      model: "purchases",
      header: {
        menu: ["Compras", "Historial"],
      },
      buttons: {
        create: false,
        edit: false,
        destroy: true,
        import: false,
        export: false,
      },
    },
    dataTable: {
      body: {
        widthAccion: "120px",
        cols: [
          {
            name: "supplier_document_number",
            alias: "Número documento",
            width: "170px",
          },
          {
            name: "supplier_names",
            alias: "Proveedor",
          },
          {
            name: "fecha_emision",
            alias: "Fecha emisión",
            width: "120px",
          },
          {
            name: "type_document_abbreviation",
            alias: "Documento",
            width: "150px",
          },
          {
            name: "document_complete",
            alias: "Número",
            width: "90px",
          },
          {
            name: "total",
            alias: "Total",
            width: "90px",
          },
        ],
        buttons: [
          {
            name: "print",
            tooltip: "Ver PDF",
            text: "",
            css: "me-3 text-danger",
            icon: "far fa-file-pdf",
            play: {
              type: "alls",
            },
          },
          {
            name: 'edit',
            tooltip: 'Editar',
            text: '',
            css: 'me-3 text-info',
            icon: 'fa-regular fa-pen-to-square',
            play: {
              type: 'alls',
              name: 'state',
            },
          },
        ],
      },
    },
  };

  const onClickAddResource = () => {};
  const onClickEditResource = () => {};
  const onClickDeleteResource = (row: any) => {
    SweetAlert.deleteConfirmation(
      () => handleDelete(row.id),
      handleCancelDelete,
      '¿Está seguro que desea eliminar la compra?',
      row.serie + '-' + row.numeracion,
    );
  };
  const handleDelete = (id: string) => {
    deletePurchase(id).then(() => {
      SweetAlert.success('Compra eliminada correctamente.');
    });
  };
  const handleCancelDelete = () => {};

  //Data inicial [GET]
  useEffect(() => {
    const dataInicial = () => {
      getRequirements("").then((response) => {
        setRequirements(response);
        setLoadedComponent(true);
      });
    };

    if (!loadedComponent) {
      dataInicial();
    }
  }, [getRequirements, loadedComponent]);
  useEffect(() => {
    fetchPurchases(1, "", "", "", "", true, "sales");
  }, [fetchPurchases]);
  /**Modal */
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const onCloseModalForm = () => {
    setStateModal(false);
  };
  const onClickButtonPersonalizado = (row: any, name: any) => {
    switch (name) {
      case "print":
        onClickPrint(row);
        break;
      case "info":
        onClickInfoSunat(row);
        break;
      case 'edit':
        navigate('/purchases-edit/' + row.uuid);
        break;
      default:
        break;
    }
  };

  const onClickPrint = (row: any) => {
    window.open(
      `${process.env.REACT_APP_LINK_BACKEND}/cpe/purchases/${row.uuid}.pdf`,
      "_blank",
    );
  };
  const onClickInfoSunat = (row: any) => {
    setDataModalResourceState({
      size: "modal-md",
      type: "",
      buttonSubmit: null,
      row: row,
      title: "Ver información de SUNAT",
      requirements: [],
      onCloseModalForm: onCloseModalForm,
      errosBackend: {},
    });
    setComponentModal(
      <InfoSunatModal onCloseModalForm={onCloseModalForm} data={row} />,
    );
    setStateModal(true);
    setIsModalOpen(true);
  };
  
  /** METODOS DEL FILTER */
  const onChangePage = (page: number, type: string) => {
    switch (type) {
      case "prev":
        setFilterState({ ...filterState, page: filterState.page - 1 });
        fetchPurchases(
          filterState.page - 1,
          filterState.text,
          filterState.typeDocument,
          filterState.dateInitial,
          filterState.dateFinal,
          true,
          "sales",
        );
        break;

      case "current":
        setFilterState({ ...filterState, page: page });
        fetchPurchases(
          page,
          filterState.text,
          filterState.typeDocument,
          filterState.dateInitial,
          filterState.dateFinal,
          true,
          "sales",
        );
        break;
      case "next":
        setFilterState({ ...filterState, page: filterState.page + 1 });
        fetchPurchases(
          filterState.page + 1,
          filterState.text,
          filterState.typeDocument,
          filterState.dateInitial,
          filterState.dateFinal,
          true,
          "sales",
        );
        break;
      default:
        break;
    }
  };
  const onClearFilters = () => {
    setFilterState({
      ...filterState,
      page: 1,
      text: "",
      typeDocument: "",
      dateInitial: "",
      dateFinal: "",
      selectedDates: [],
    });
    fetchPurchases(1, "", "", "", "", true, "sales");
  };

  const handleFilterChange = (newFilters: any, loading: boolean) => {
    setFilterState(newFilters);
    fetchPurchases(
      1,
      newFilters.text,
      newFilters.typeDocument,
      newFilters.dateInitial,
      newFilters.dateFinal,
      loading,
      "sales",
    );
  };

  return (
    <div className="main-body p-0">
      <div className="inner-wrapper">
        <div className="inner-main">
          <HeaderPage page={state.page} onModalResource={onClickAddResource} />
          <div className="inner-main-body">
            <div className="card card-featured card-featured-primary mb-3 mt-3">
              <div className="card-body">
                <Filter
                  requirements={requirements}
                  onClearFilters={onClearFilters}
                  filterState={filterState}
                  onFilterChange={handleFilterChange}
                />
                <BodyPage
                  onChangeDelete={onClickDeleteResource}
                  onClickButtonPersonalizado={onClickButtonPersonalizado}
                  onChangeEdit={onClickEditResource}
                  onChangePage={onChangePage}
                  page={state.page}
                  table={state.dataTable}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ModalComponent
          stateModal={stateModal}
          typeModal={"true"}
          size={dataModalResourceState.size}
          title={dataModalResourceState.title}
          content={componentModal}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default PurchasesHistory;
