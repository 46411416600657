/* eslint-disable jsx-a11y/anchor-is-valid */
import { Tooltip } from 'react-tooltip';

interface Props {
  onClickAddItem: () => void;
  onClickImportItems: () => void;
}

const ProductosHeader = (props: Props) => {
  return (
    <>
      <Tooltip id="tooltip-despatches-header" />
      <div className="title-items">
        Productos{' '}
        <a
          onClick={props.onClickAddItem}
          data-tooltip-id="tooltip-despatches-header"
          data-tooltip-content="Agregar un producto"
          className="add-item-sales"
        >
          <i className="ri-add-line"></i>
        </a>
        <a
          onClick={props.onClickImportItems}
          data-tooltip-id="tooltip-despatches-header"
          data-tooltip-content="Importar productos"
          className="import-items ms-2"
        >
          <i className="fa-solid fa-file-arrow-down"></i>
        </a>
      </div>
      <div className="col-md-12">
        <div className="row">
          {/*Producto*/}
          <div className="col-md-5">
            <label className="form-label">Producto</label>
          </div>
          {/*Detalle adicional*/}
          <div className="col-md-3">
            <label className="form-label">Detalle adicional</label>
          </div>
          {/*Cantidad*/}
          <div className="col-md-2">
            <label className="form-label">Cantidad</label>
          </div>
          {/*Accion*/}
          <div className="col-md-2">
            <label className="form-label">Acción</label>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductosHeader;
