import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
/**Services */
import * as clientsService from '../services/clients.service';
import * as despatchesService from '../services/despatches.service';
/**Redux */
import { setLoading } from '../redux/states/loading.slice';
import { DespatchRequest } from '../models/requests';
/**Models */
import { DataTable } from '../models';
/**Redux */
import { dataTable_setAlls, dataTable_clearAll } from '../redux/states/dataTable.slice';

export function useDespatches() {
  const dispatch = useDispatch();
  const [despatches, setDespatches] = useState<DataTable | null>(null);

  //GET

  const personSearch = async (document: string) => {
    dispatch(setLoading(true));
    try {
      const response = await clientsService.personSearch(document);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }

  const getRequirements = async () => {
    dispatch(setLoading(true));
    try {
      const response = await despatchesService.getRequirements();
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const fetchDespatches = useCallback(
    (page: number, text: string, loading: boolean) => {
      dispatch(setLoading(loading));
      despatchesService
        .getDespatches(page, text)
        .then((response) => {
          dispatch(dataTable_setAlls(response));
          setDespatches(response); // Actualiza el estado local si es necesario
        })
        .catch((error) => error)
        .finally(() => {
          dispatch(setLoading(false));
        });
    },
    [dispatch],
  );

  useEffect(() => {
    const resetData = () => {
      dispatch(dataTable_clearAll());
      setDespatches(null); // Borra el estado local si es necesario
    };

    return () => {
      resetData(); // Esto se ejecutará cuando el componente se desmonte
    };
  }, [dispatch]);

  const getUbigeos = async (text: string) => {
    dispatch(setLoading(false));
    try {
      const response = await despatchesService.getUbigeos(text);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getDocuments = async (text: string, clientId:string, type: string) => {
    dispatch(setLoading(false));
    try {
      const response = await despatchesService.getDocuments(text, clientId, type);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }

  const getPlacas = async (text: string) => {
    dispatch(setLoading(true));
    try {
      const response = await despatchesService.getPlacas(text);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }

  const getLicencias = async (text: string) => {
    dispatch(setLoading(true));
    try {
      const response = await despatchesService.getLicencias(text);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }

  //STORE
  const storeDespatch = async (despatch: DespatchRequest) => {
    dispatch(setLoading(true));
    try {
      const response = await despatchesService.storeDespatch(despatch);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  return {
    getRequirements,
    getUbigeos,
    getDocuments,
    getPlacas,
    getLicencias,
    storeDespatch,
    despatches,
    fetchDespatches,
    personSearch,
  };
}
