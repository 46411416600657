import { ChangeEvent } from 'react';
/**Utilities */
import { ErrorBackend } from '../../../utilities';

interface Props {
  datosChofer: any;
  handleSelectChangeValue: (key: any, fieldName: string, value: string) => void;
  handleInputChangeValue: (key: any, fieldName: string, value: string) => void;
  getLicencias: any;
  errors: any;
  handleSearchLicencia: (nombres: string, apellidos: string, numeroLicencia:string, validateLicencia: Boolean) => void;
}

const DatosConductor = (props: Props) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.handleInputChangeValue('datosChofer', event.target.name, event.target.value);
  };
  const onChangeSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    props.handleSelectChangeValue('datosChofer', event.target.name, event.target.value);
  };

  const onKeyUpSearchLicencia = () => {
    if (props.datosChofer.numeroDocumento.length === 8 && props.datosChofer.tipoDocumento === '1') {
      props.getLicencias(props.datosChofer.numeroDocumento).then((response: any) => {
        props.handleSearchLicencia(response.nombres, response.apellidos, response.numero_licencia, response.success);
      });
    }
  };

  const hasError = (propertyName: string) => {
    return props.errors && props.errors[propertyName];
  };

  return (
    <>
      <div className="row border-sales">
        <div className="title-items">Datos del Conductor Principal</div>
        {/*Tipo de documento*/}
        <div className="col-md-2">
          <div className="form-group">
            <label htmlFor="tipoDocumento" className="form-label">
              Tipo de documento<span className="text-danger">*</span>
            </label>
            <select
              onChange={onChangeSelect}
              value={props.datosChofer.tipoDocumento}
              className={`form-control form-control-sm form-select${
                hasError('despatch.datosChofer.tipoDocumento') ? ' is-invalid' : ''
              }`}
              name="tipoDocumento"
              id="tipoDocumento"
            >
              <option value="" disabled>
                Seleccionar
              </option>
              <option value="1">DNI</option>
              <option value="4">CARNET DE EXTRANJERÍA</option>
              <option value="7">PASAPORTE</option>
              <option value="A">CÉDULA DIPLOMÁTICA</option>
              <option value="B">DOC.IDENT.PAIS.RESIDENCIA-NO.D</option>
              <option value="C">TAX IDENTIFICATION NUMBER - TIN – DOC TRIB PP.NN</option>
              <option value="D">IDENTIFICATION NUMBER - IN – DOC TRIB PP. JJ</option>
              <option value="G">SALVOCONDUCTO</option>
            </select>
            <ErrorBackend errorsBackend={props.errors} name="despatch.datosChofer.tipoDocumento" />
          </div>
        </div>
        {/*Número de documento*/}
        <div className="col-md-2">
          <div className="form-group">
            <label htmlFor="numeroDocumento" className="form-label">
              Número de documento<span className="text-danger">*</span>
            </label>
            <input
              disabled={props.datosChofer.tipoDocumento=== ''}
              value={props.datosChofer.numeroDocumento}
              onChange={handleInputChange}
              onKeyUp={onKeyUpSearchLicencia}
              autoComplete="off"
              name="numeroDocumento"
              id="numeroDocumento"
              type="text"
              className={`todo-mayuscula form-control form-control-sm${
                hasError('despatch.datosChofer.numeroDocumento') ? ' is-invalid' : ''
              }`}
            />
            <ErrorBackend errorsBackend={props.errors} name="despatch.datosChofer.numeroDocumento" />
          </div>
        </div>
        {/*Nombre del documento*/}
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="nombres" className="form-label">
              Nombre del conductor<span className="text-danger">*</span>
            </label>
            <input
              value={props.datosChofer.nombres}
              onChange={handleInputChange}
              autoComplete="off"
              name="nombres"
              id="nombres"
              type="text"
              className={`todo-mayuscula form-control form-control-sm${
                hasError('despatch.datosChofer.nombres') ? ' is-invalid' : ''
              }`}
            />
            <ErrorBackend errorsBackend={props.errors} name="despatch.datosChofer.nombres" />
          </div>
        </div>
        {/*Apellidos del documento*/}
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="apellidos" className="form-label">
              Apellidos del conductor<span className="text-danger">*</span>
            </label>
            <input
              value={props.datosChofer.apellidos}
              onChange={handleInputChange}
              autoComplete="off"
              name="apellidos"
              id="apellidos"
              type="text"
              className={`todo-mayuscula form-control form-control-sm${
                hasError('despatch.datosChofer.apellidos') ? ' is-invalid' : ''
              }`}
            />
            <ErrorBackend errorsBackend={props.errors} name="despatch.datosChofer.apellidos" />
          </div>
        </div>
        {/*Número de licencia*/}
        <div className="col-md-2">
          <div className="form-group">
            <label htmlFor="numeroLicencia" className="form-label">
              Número de licencia<span className="text-danger">*</span>
            </label>
            <input
              value={props.datosChofer.numeroLicencia}
              onChange={handleInputChange}
              autoComplete="off"
              name="numeroLicencia"
              id="numeroLicencia"
              type="text"
              className={
                'todo-mayuscula form-control form-control-sm ' +
                (props.datosChofer.validateLicencia === true ? ' is-valid' : '') +
                (props.datosChofer.validateLicencia === false ? ' is-invalid' : '') +
                (hasError('despatch.datosChofer.numeroLicencia') ? ' is-invalid' : '')
              }
            />
            <ErrorBackend errorsBackend={props.errors} name="despatch.datosChofer.numeroLicencia" />
            <div className="valid-feedback">Número de licencia validada correctamente.</div>
            <div className="invalid-feedback">Número de licencia no existe.</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DatosConductor;
