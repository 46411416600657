import { useState } from 'react';
/**Select */
import Select from 'react-select';
/**DatePicker */
import Flatpickr from 'react-flatpickr';
/**Moment */
import moment from 'moment';
/**Utilities */
import { ErrorValidate } from '../../../utilities';

interface Props {
  formik: any;
  clientSearch: any;
  handleSelectClient: any;
  handleSelectChange: any;
  requirements: any;
}

interface OptionSelect {
  label: string;
  value: string;
  ubigeo_name: string;
  ubigeo: string;
  address_despatch: string;
}

const Header = (props: Props) => {
  const [optionsClients, setOptionsClients] = useState<OptionSelect[]>([]);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const minDate = moment().format('YYYY-MM-DD');

  const handleClientSearchChange = (value: string) => {
    if (value.length >= 3) {
      props
        .clientSearch(value, 'JURIDICA')
        .then((response: any) => {
          setIsLoadingClients(true);
          setOptionsClients(response.data);
        })
        .finally(() => {
          setIsLoadingClients(false);
        });
    }
  };

  const handleClientSelectChange = (selectedOption: OptionSelect) => {
    props.formik.setFieldValue('selectedClient', selectedOption);
    props.handleSelectClient(selectedOption);
  };

  return (
    <>
      {/* Cliente*/}
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="cliente_id" className="form-label">
            Cliente
          </label>
          <Select
            name="cliente_id"
            id="cliente_id"
            isLoading={isLoadingClients}
            placeholder={'Buscar cliente...'}
            options={optionsClients}
            onInputChange={handleClientSearchChange}
            noOptionsMessage={() => 'No se encontraron resultados'}
            value={props.formik.values.selectedClient}
            onChange={(selectedOption) => handleClientSelectChange(selectedOption)}
            className={props.formik.errors.cliente_id && props.formik.touched.cliente_id ? ' is-invalid' : ''}
          />
          <ErrorValidate state={props.formik.errors.cliente_id} />
        </div>
      </div>
      {/* Fecha emisión*/}
      <div className="col-md-2">
        <div className="form-group">
          <label htmlFor="fechaEmision" className="form-label">
            Fecha emisión
          </label>
          <Flatpickr
            name="fechaEmision"
            id="fechaEmision"
            className="form-control form-control-sm"
            value={props.formik.values.fechaEmision}
            options={{
              dateFormat: 'Y-m-d',
              locale: 'es',
              minDate: minDate,
              maxDate: minDate,
            }}
          />
        </div>
      </div>
      {/* Tipo de transporte*/}
      <div className="form-group col-md-3">
        <label htmlFor="tipoTransporte" className="form-label">
          Tipo de transporte
        </label>
        <select
          onChange={props.handleSelectChange}
          value={props.formik.values.tipoTransporte}
          className={
            'form-control form-control-sm form-select' +
            (props.formik.errors.tipoTransporte && props.formik.touched.tipoTransporte ? ' is-invalid' : '')
          }
          name="tipoTransporte"
          id="tipoTransporte"
        >
          <option value="" disabled>
            Seleccionar
          </option>
          {props.requirements?.tipo_transportes.map((item: any) => (
            <option key={item.code} value={item.code}>
              {item.name}
            </option>
          ))}
        </select>
        <ErrorValidate state={props.formik.errors.tipoTransporte} />
      </div>
      {/* Motivo de traslado*/}
      <div className="form-group col-md-3">
        <label htmlFor="motivoTraslado" className="form-label">
          Motivo de traslado
        </label>
        <select
          onChange={props.handleSelectChange}
          value={props.formik.values.motivoTraslado}
          className="form-control form-control-sm form-select"
          name="motivoTraslado"
          id="motivoTraslado"
        >
          {props.requirements?.motivo_traslados.map((item: any) => (
            <option key={item.code} value={item.code}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default Header;
