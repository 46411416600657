/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
/**Models */
import { DespatchItemRequest } from '../../../models/requests';
/**Select */
import Select from 'react-select';
/**Utilities */
import { ErrorBackend } from '../../../utilities';

interface Props {
  items: DespatchItemRequest[];
  productSearch: any;
  handleProductSelected: (index: number, fieldName: string, value: string) => void;
  onClickDeleteItem: (index: number) => void;
  errors: any;
}

interface OptionSelect {
  label: string;
  value: string;
  codigo: string;
  unidad_medida: string;
}

const ProductosBody = (props: Props) => {
  const [optionsProducts, setOptionsProducts] = useState<OptionSelect[]>([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);

  const handlSearchProduct = (value: string) => {
    if (value.length >= 3) {
      props
        .productSearch(value, "")
        .then((response: any) => {
          setIsLoadingProducts(true);
          setOptionsProducts(response.data);
        })
        .finally(() => {
          setIsLoadingProducts(false);
        });
    }
  };

  const handleProductSelectChange = (selectedOption: OptionSelect, index: number) => {
    const updatedItems = [...props.items];
    updatedItems[index].selectedProduct = selectedOption;
    props.handleProductSelected(index, 'product_id', selectedOption.value);
    props.handleProductSelected(index, 'codigo', selectedOption.codigo);
    props.handleProductSelected(index, 'descripcion', selectedOption.label);
    props.handleProductSelected(index, 'unidadMedida', selectedOption.unidad_medida);
    setOptionsProducts([]);
  };

  const hasError = (propertyName: string) => {
    return props.errors && props.errors[propertyName];
  };

  return (
    <>
      <Tooltip id="tooltip-products-body" place="right-start" />
      <div className="col-md-12">
        {props.items.map((item: DespatchItemRequest, index: number) => (
          <div key={index} className="row mt-2">
            {/*PRODUCTO*/}
            {item.view ? (
              <div className="col-md-5">
                <Select
                  isLoading={isLoadingProducts}
                  placeholder={'Buscar producto...'}
                  options={optionsProducts}
                  onInputChange={handlSearchProduct}
                  noOptionsMessage={() => 'No se encontraron resultados'}
                  onChange={(selectedOption) => handleProductSelectChange(selectedOption, index)}
                  value={item.selectedProduct}
                />
                <ErrorBackend errorsBackend={props.errors} name={`despatch.items.${index}.product_id`} />
              </div>
            ) : (
              <div className="col-md-5">
                <input type="text" disabled readOnly value={item.descripcion} className="form-control form-control-sm" />
              </div>
            )}
            {/*DETALLE ADICIONAL*/}
            <div className="col-md-3">
              <input
                value={item.adicional}
                type="text"
                className="form-control form-control-sm todo-mayuscula"
                onChange={(e) => props.handleProductSelected(index, 'adicional', e.target.value)}
              />
            </div>
            {/*CANTIDAD*/}
            <div className="col-md-2">
              <input
                value={item.cantidad}
                type="number"
                className={`form-control form-control-sm${
                  hasError(`despatch.items.${index}.cantidad`) ? ' is-invalid' : ''
                }`}
                onChange={(e) => props.handleProductSelected(index, 'cantidad', e.target.value)}
              />
              <ErrorBackend errorsBackend={props.errors} name={`despatch.items.${index}.cantidad`} />
            </div>
            {/*ACCIONES*/}
            <div className="col-md-1">
              <a
                data-tooltip-id="tooltip-products-body"
                data-tooltip-content="Eliminar producto"
                onClick={() => props.onClickDeleteItem(index)}
                className="text-danger"
                role="button"
              >
                <i className="ri-delete-bin-line font-size-18"></i>
              </a>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ProductosBody;
