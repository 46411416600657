import { createSlice } from '@reduxjs/toolkit';

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    isLoading: false,
    isStateToggle: localStorage.getItem('stateToggle') ?? 'false',
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setearIsStateToggle: (state, action) => {
      localStorage.setItem('stateToggle', action.payload);
      state.isStateToggle = action.payload;
    },
  },
});

export const { setLoading, setearIsStateToggle } = loadingSlice.actions;
export default loadingSlice.reducer;
