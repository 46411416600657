import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import ProtectedRoute from "./routes/ProtectedRoute";
/**Reduxs */
import store from "./redux/store";
/**Contexts */
import { AuthProvider } from "./contexts/auth.context";
/**Layouts */
import AuthLayout from "./layouts/auth.layout";
/**Pages */
import {
  LoginPage,
  ProductsCategories,
  ProductsBrands,
  ProductsPresentations,
  Products,
  Clients,
  SalesStore,
  SalesHistory,
  QuotesStore,
  QuotesHistory,
  QuotesEdit,
  QuotesChange,
  DespatchesStore,
  DespatchesHistory,
  SalesNote,
  SunatNotas,
  Suppliers,
  PurchasesStore,
  PurchasesHistory,
  BookPurchase,
  BookSale,
  Dashboard,
  PurchaseEdit,
  ProductTariffIndex,
  InventorySettingsIndex,
  InventoryKardexIndex,
  InventoryWarehouseIndex,
  UsersPage,
  RolesPage,
  ChangePasswordPage,
} from "./pages";

function App() {
  return (
    <AuthProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<AuthLayout />}>
              <Route path="" element={<Dashboard />}></Route>
              <Route path="quotes-store" element={<QuotesStore />}></Route>
              <Route path="quotes-edit/:uuid" element={<QuotesEdit />}></Route>
              <Route
                path="quotes-change/:uuid"
                element={<QuotesChange />}
              ></Route>
              <Route path="quotes-history" element={<QuotesHistory />}></Route>
              <Route path="sales-store" element={<SalesStore />}></Route>
              <Route path="sales-history" element={<SalesHistory />}></Route>
              <Route
                path="sales-note/:note/:uuid"
                element={<SalesNote />}
              ></Route>
              <Route
                path="despatches-store"
                element={<DespatchesStore />}
              ></Route>
              <Route
                path="despatches-history"
                element={<DespatchesHistory />}
              ></Route>
              <Route path="sunat-notes" element={<SunatNotas />}></Route>
              <Route path="clients" element={<Clients />}></Route>
              <Route
                path="products-tariffs"
                element={<ProductTariffIndex />}
              ></Route>
              <Route path="products" element={<Products />}></Route>
              <Route
                path="products-presentations"
                element={<ProductsPresentations />}
              ></Route>
              <Route
                path="products-brands"
                element={<ProductsBrands />}
              ></Route>
              <Route
                path="products-categories"
                element={<ProductsCategories />}
              ></Route>
              <Route path="suppliers" element={<Suppliers />}></Route>
              <Route
                path="purchases-store"
                element={<PurchasesStore />}
              ></Route>
              <Route
                path="purchases-edit/:uuid"
                element={<PurchaseEdit />}
              ></Route>
              <Route
                path="purchases-history"
                element={<PurchasesHistory />}
              ></Route>
              <Route
                path="electronic-books-purchases"
                element={<BookPurchase />}
              ></Route>
              <Route
                path="electronic-books-sales"
                element={<BookSale />}
              ></Route>
              <Route
                path="inventory-settings"
                element={<InventorySettingsIndex />}
              ></Route>
              <Route
                path="inventory-kardex"
                element={<InventoryKardexIndex />}
              ></Route>
              <Route
                path="inventory-warehouses"
                element={<InventoryWarehouseIndex />}
              ></Route>
              <Route
                element={<ProtectedRoute permission="access-users-index" />}
              >
                <Route path="access-users" element={<UsersPage />} />
              </Route>
              <Route
                element={<ProtectedRoute permission="access-roles-index" />}
              >
                <Route path="access-roles" element={<RolesPage />} />
              </Route>
              <Route
                element={<ProtectedRoute permission="change-password" />}
              >
                <Route path="change-password" element={<ChangePasswordPage />} />
                </Route>
            </Route>
            <Route path="login" element={<LoginPage />}></Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </AuthProvider>
  );
}

export default App;
