import { useState } from 'react';
/**Components */
import HeaderPage from '../../../components/page/header';
import BodyPage from '../../../components/page/body';
import ModalComponent from '../../../components/modal';
/** Componentes propios */
import Filter from './filter';
import FormPresentation from './form';
/**Hooks */
import { usePresentations } from '../../../hooks';
/**Models */
import { Presentation } from '../../../models';
/**Utilities */
import { SweetAlert } from '../../../utilities';

/** Modal Resource */
interface DataModalState {
  type: string;
  buttonSubmit: string | null;
  row: any | null;
  title: string | null;
  requirements: any[];
  onCloseModalForm: () => void;
  errosBackend: any;
}

export const PresentationIndex = () => {
  const { fetchPresentations, storePresentation, updatePresentation } = usePresentations();
  const [dataModalResourceState, setDataModalResourceState] = useState<DataModalState>({
    type: '',
    buttonSubmit: null,
    row: null,
    title: null,
    requirements: [],
    onCloseModalForm: () => {},
    errosBackend: {},
  });
  const [errors, setErrors] = useState({});
  const [filterState, setFilterState] = useState({
    page: 1,
    text: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stateModal, setStateModal] = useState<boolean>(false);

  //let errorsBakend = {};
  const state = {
    page: {
      title: 'Presentaciones',
      icon: 'ri-archive-2-line',
      model: 'products-presentations',
      header: {
        menu: ['Productos', 'Presentaciones'],
      },
      buttons: {
        create: true,
        edit: true,
        destroy: false,
        import: false,
        export: false,
      },
    },
    dataTable: {
      body: {
        cols: [
          {
            name: 'name',
            alias: 'Nombre',
          },
          {
            name: 'units',
            alias: 'Equivalencia a unidades',
          },
          {
            name: 'state',
            alias: 'Estado',
            play: {
              type: 'states',
              values: {
                0: 'bg-danger-subtle text-danger',
                1: 'bg-success-subtle text-success',
              },
              names: {
                0: 'Inactivo',
                1: 'Activo',
              },
            },
          },
        ],
      },
    },
  };

  /** METODOS DEL FILTER */
  const onChangePage = (page: number, type: string) => {
    switch (type) {
      case 'prev':
        setFilterState({ ...filterState, page: filterState.page - 1 });
        fetchPresentations(filterState.page - 1, filterState.text, true);
        break;

      case 'current':
        setFilterState({ ...filterState, page: page });
        fetchPresentations(page, filterState.text, true);
        break;
      case 'next':
        setFilterState({ ...filterState, page: filterState.page + 1 });
        fetchPresentations(filterState.page + 1, filterState.text, true);
        break;
      default:
        break;
    }
  };
  const onClearFilters = () => {
    setFilterState({ ...filterState, text: '' });
    fetchPresentations(1, '', true);
  }

  const handleFilterChange = (newFilters: any) => {
    setFilterState(newFilters);
    fetchPresentations(1, newFilters.text, false);
  };

  /** METODOS DE MODAL */
  const onCloseModalForm = () => {
    setStateModal(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setErrors({});
  };

  const onClickAddResource = (type: string) => {
    setDataModalResourceState({
      type: type,
      buttonSubmit: 'Registrar',
      row: null,
      title: 'Nueva presentación',
      requirements: [],
      onCloseModalForm: onCloseModalForm,
      errosBackend: {},
    });
    setStateModal(true);
    setIsModalOpen(true);
  };

  const onClickEditResource = (row: any) => {
    setDataModalResourceState({
      type: 'edit',
      buttonSubmit: 'Editar',
      row: row,
      title: 'Editar presentación',
      requirements: [],
      onCloseModalForm: onCloseModalForm,
      errosBackend: {},
    });
    setStateModal(true);
    setIsModalOpen(true);
  };

  /** METODOS DEL FORM RESOURCE */
  const onSubmitForm = async (presentation: Presentation, type: string) => {
    switch (type) {
      case 'store':
        storePresentation(presentation)
          .then(() => {
            onCloseModalForm();
            SweetAlert.success('Mensaje', 'Presentación registrada correctamente.');
          })
          .catch((error) => {
            setErrors(error.response.data.errors);
          });
        break;
      case 'edit':
        updatePresentation(presentation)
          .then(() => {
            onCloseModalForm();
            SweetAlert.success('Mensaje', 'Presentación actualizada correctamente.');
          })
          .catch((error) => {
            setErrors(error.response.data.errors);
          });
        break;
      default:
        break;
    }
  };

  const onClickButtonPersonalizado = () => {};
  const onClickDeleteResource = () => {};

  return (
    <div className="main-body p-0">
      <div className="inner-wrapper">
        <div className="inner-main">
          <HeaderPage page={state.page} onModalResource={onClickAddResource} />
          <div className="inner-main-body">
            <div className="card card-featured card-featured-primary mb-3 mt-3">
              <div className="card-body">
                <Filter onClearFilters={onClearFilters} filterState={filterState} onFilterChange={handleFilterChange} />
                <BodyPage onChangeDelete={onClickDeleteResource} onClickButtonPersonalizado={onClickButtonPersonalizado} onChangeEdit={onClickEditResource} onChangePage={onChangePage} page={state.page} table={state.dataTable} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Resource */}
      {isModalOpen && (
        <ModalComponent
          stateModal={stateModal}
          typeModal={'static'}
          title={dataModalResourceState.title}
          content={<FormPresentation errors={errors} data={dataModalResourceState} onSubmitForm={onSubmitForm} />}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default PresentationIndex;
