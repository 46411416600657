/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import BodyPage from "../../../components/page/body";
import HeaderPage from "../../../components/page/header";
import ModalComponent from "../../../components/modal";
import { useAccess } from "../../../hooks";
import FilterAccessComponent from "./components/filter-access.component";
import FormAccessComponent from "./components/form-access.component";
import { UserRequest } from "../../../models/requests";
import { SweetAlert } from "../../../utilities";

/** Modal Resource */
interface DataModalState {
  type: string;
  buttonSubmit: string | null;
  row: any | null;
  title: string | null;
  requirements: any[];
  onCloseModalForm: () => void;
  errosBackend: any;
}

export const UsersPage = () => {
  const [errors, setErrors] = useState({});
  const { getUsers, getFilters, storeUser, stateUser, resetPassword, personSearch } =
    useAccess();
  const [requirements, setRequirements] = useState<any[]>([]);
  const [loadedComponent, setLoadedComponent] = useState<boolean>(false);
  const [filterState, setFilterState] = useState({
    page: 1,
    text: "",
    limit: "",
    role_id: "",
  });
  /** Modal Form */
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stateModal, setStateModal] = useState<boolean>(false);
  const [dataModalResourceState, setDataModalResourceState] =
    useState<DataModalState>({
      type: "",
      buttonSubmit: null,
      row: null,
      title: null,
      requirements: [],
      onCloseModalForm: () => {},
      errosBackend: {},
    });

  //STATE

  const state = {
    page: {
      title: "Lista de usuarios",
      icon: "ri-group-line",
      model: "access-users",
      header: {
        menu: ["Gestión de accesos", "Usuarios"],
      },
      gridMenu: [],
      body: [],
      buttons: {
        create: true,
        create_header: false,
        edit: false,
        destroy: false,
        import: false,
        export: false,
      },
    },
    dataTable: {
      body: {
        widthAccion: "120px",
        cols: [
          {
            name: "document_type",
            alias: "Documento",
            roles: [],
          },
          {
            name: "names",
            alias: "Nombre",
            roles: [],
          },
          {
            name: "email",
            alias: "Correo corporativo",
            roles: [],
          },
          {
            name: "roles",
            alias: "Rol",
            roles: [],
          },
          {
            name: "state",
            alias: "Estado",
            width: "90px",
            roles: [],
            play: {
              type: "states",
              values: {
                0: "bg-danger-subtle text-danger",
                1: "bg-success-subtle text-success",
              },
              names: {
                0: "Desactivado",
                1: "Activo",
              },
            },
          },
        ],
        buttons: [
          {
            name: "status",
            tooltip: "Desactivar usuario",
            text: "",
            css: "me-3 text-danger",
            icon: "fa-solid fa-user-slash",
            play: {
              type: "states",
              name: "state",
              values: {
                "0": false,
                "1": true,
              },
            },
          },
          {
            name: "status_active",
            tooltip: "Activar usuario",
            text: "",
            css: "me-3 text-success",
            icon: "fa-solid fa-user-check",
            play: {
              type: "states",
              name: "state",
              values: {
                "0": true,
                "1": false,
              },
            },
          },
          {
            name: "reset_password",
            tooltip: "Resetear contraseña",
            text: "",
            css: "me-3 text-info",
            icon: "fa-solid fa-lock",
            play: {
              type: "alls",
              name: "state",
              values: {},
            },
          },
        ],
      },
    },
  };

  //METODOS DEL RECURSO
  const onClickAddResource = (type: string) => {
    setDataModalResourceState({
      type: type,
      buttonSubmit: "Registrar",
      row: null,
      title: "Nuevo usuario",
      requirements: requirements,
      onCloseModalForm: onCloseModalForm,
      errosBackend: {},
    });
    setStateModal(true);
    setIsModalOpen(true);
  };
  const onClickEditResource = () => {};
  const onClickDeleteResource = () => {};

  const onClickButtonPersonalizado = (row: any, name: any) => {
    switch (name) {
      case "status":
        onStatus(
          row,
          "¿Está seguro que desea desactivar al usuario?",
          "Usuario desactivado correctamente.",
        );
        break;
      case "status_active":
        onStatus(
          row,
          "¿Está seguro que desea activar al usuario?",
          "Usuario activado correctamente.",
        );
        break;
      case "reset_password":
        SweetAlert.onConfirmation(
          () => handleResetPassword(row.id),
          handleCancelDelete,
          "¿Está seguro que desea resetear la contraseña del usuario?",
          row.names,
        );

        break;
      default:
        break;
    }
  };

  const onStatus = (row: any, text: string, message: string) => {
    SweetAlert.onConfirmation(
      () => handleDelete(row.id, message),
      handleCancelDelete,
      text,
      row.names,
    );
  };
  const handleDelete = (id: any, text: string) => {
    stateUser(id).then(() => {
      SweetAlert.success(text);
    });
  };
  const handleCancelDelete = () => {};

  const handleResetPassword = (id: any) => {
    resetPassword(id).then(() => {
      SweetAlert.success("Contraseña reseteada correctamente.");
    });
  };

  //METODOS DE LA PAGINACION
  const onChangePage = (page: number, type: string) => {
    switch (type) {
      case "prev":
        setFilterState({ ...filterState, page: filterState.page - 1 });
        getUsers(
          filterState.page - 1,
          filterState.text,
          filterState.limit,
          filterState.role_id,
          true,
        );
        break;

      case "current":
        setFilterState({ ...filterState, page: page });
        getUsers(
          page,
          filterState.text,
          filterState.limit,
          filterState.role_id,
          true,
        );
        break;
      case "next":
        setFilterState({ ...filterState, page: filterState.page + 1 });
        getUsers(
          filterState.page + 1,
          filterState.text,
          filterState.limit,
          filterState.role_id,
          true,
        );
        break;
      default:
        break;
    }
  };

  //METODO FILTER
  const handleFilterChange = (newFilters: any, filter: boolean) => {
    setFilterState(newFilters);
    if (filter) {
      getUsers(1, "", "", newFilters.role_id, true);
    }
  };

  const onClearFilters = () => {
    setFilterState({
      ...filterState,
      page: 1,
      text: "",
      limit: "",
      role_id: "",
    });
  };

  const handleFilterSearch = (newFilters: any) => {
    setFilterState(newFilters);
    getUsers(1, newFilters.text, filterState.limit, newFilters.role_id, false);
  };

  /**METODOS DEL MODAL FORM */
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setErrors({});
  };

  const onCloseModalForm = () => {
    setStateModal(false);
  };

  /** METODOS DE FORM */

  const onSubmitForm = async (user: UserRequest, type: string) => {
    switch (type) {
      case "store":
        storeUser(user)
          .then(() => {
            onCloseModalForm();
            SweetAlert.success("Mensaje", "Usuario registrado correctamente.");
          })
          .catch((error) => {
            setErrors(error.response.data.errors);
          });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const dataInicial = () => {
      getFilters(false).then((response: any) => {
        setRequirements(response);
        //setLoadedComponent(true);
      });
      getUsers(1, "", "", "", true).then(() => {});
      setLoadedComponent(true);
    };
    if (!loadedComponent) {
      dataInicial();
    }
  }, [getFilters, getUsers, loadedComponent]);

  return (
    <div className="main-body p-0">
      <div className="inner-wrapper">
        <div className="inner-main">
          <HeaderPage page={state.page} onModalResource={onClickAddResource} />
          <div className="inner-main-body">
            <div className="row">
              <div className="col-sm-12 col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <FilterAccessComponent
                      filterState={filterState}
                      onFilterChange={handleFilterChange}
                      onClearFilters={onClearFilters}
                      handleFilterSearch={handleFilterSearch}
                      requirements={requirements}
                    />
                    <BodyPage
                      onChangeDelete={onClickDeleteResource}
                      onClickButtonPersonalizado={onClickButtonPersonalizado}
                      onChangeEdit={onClickEditResource}
                      onChangePage={onChangePage}
                      page={state.page}
                      table={state.dataTable}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal Resource */}
      {isModalOpen && (
        <ModalComponent
          stateModal={stateModal}
          typeModal={"static"}
          size={"modal-md"}
          title={dataModalResourceState.title}
          content={
            <FormAccessComponent
              errors={errors}
              data={dataModalResourceState}
              onSubmitForm={onSubmitForm}
              personSearch={personSearch}
            />
          }
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default UsersPage;
