/* eslint-disable @typescript-eslint/no-explicit-any */

import { ChangeEvent } from "react";

interface Props {
  onFilterChange: (filters: any, filter: boolean) => void;
  onClearFilters: () => void;
  handleFilterSearch: (filters: any) => void;
  filterState: any;
  requirements: any;
}

export const FilterAccessComponent = (props: Props) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedFilters = { ...props.filterState, text: event.target.value };
    props.handleFilterSearch(updatedFilters);
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const updatedFilters = {
      ...props.filterState,
      text: "",
      [event.target.name]: event.target.value,
    };
    props.onFilterChange(updatedFilters, true);
  };

  return (
    <div className="mb-3 row">
      <div className="col-md-3">
        <label htmlFor="role_id">Rol</label>
        <select
          className="form-control form-control-sm form-select"
          name="role_id"
          id="role_id"
          onChange={handleSelectChange}
          value={props.filterState.role_id}
        >
          <option value="">Todos</option>
          {props.requirements?.roles?.map((role: any) => (
            <option key={role.id} value={role.id}>
              {role.name}
            </option>
          ))}
        </select>
      </div>
      <div className="col-md-3">
        <label htmlFor="text">Buscar</label>
        <div role="group" className="btn-group">
          <input
            autoComplete="off"
            name="text"
            value={props.filterState.text}
            onChange={handleInputChange}
            type="text"
            className="todo-mayuscula form-control form-control-sm"
            id="text"
          />
          <button
            onClick={() => props.onClearFilters()}
            className="btn btn-sm btn-primary"
          >
            Limpiar
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterAccessComponent;
