/* eslint-disable jsx-a11y/anchor-is-valid */
import { useAuth } from '../contexts/auth.context';
/**Hooks */
import { useLogin } from '../hooks';

interface Props {
  onValidateCollapse: () => void;
}

export const Header = (props: Props) => {
  const { user } = useAuth();
  const { handleLogout } = useLogin();

  const logout = () => {
    handleLogout();
  };

  return (
    <div className="main-header">
      <a onClick={() => props.onValidateCollapse()} className="nav-link nav-link-faded rounded-circle nav-icon" data-toggle="sidebar">
        <i className="fa-solid fa-bars"></i>
      </a>
      <div className="ms-auto">
        <b>ERP - FACTURALO.PE</b>
      </div>
      <div className="ms-auto">
        <div id="userbox" className="userbox">
          <a role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <figure className="profile-picture">
              <img alt="user" src="/images/users/user-default.png" className="rounded-circle" />
            </figure>
            <div className="profile-info">
              <span className="name">{user?.names}</span>
              <span className="role">{user?.roles[0].name}</span>
            </div>
            <i className="fa custom-caret"></i>
          </a>

          <div className="dropdown-menu">
            <ul className="list-unstyled mb-2">
              <li className="divider"></li>
              <li>
                <a onClick={() => logout()} role="menuitem">
                  <i className="ri-shut-down-line"></i> Salir
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
