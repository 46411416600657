/* eslint-disable jsx-a11y/anchor-is-valid */
/**Resources */
import CanCheck from '../../resources/can';

interface Props {
  page: any;
  onModalResource: (type: string) => void;
}

export const HeaderPage = (props: Props) => {
  return (
    <div className="inner-main-header">
      <h5 className="me-3">{props.page.title}</h5>
      {props.page.buttons?.create && CanCheck(props.page.model + '-store') && (
        <button onClick={() => props.onModalResource('store')} className="btn btn-primary btn-sm">
          <i className="ri-add-line align-middle me-2"></i> Nuevo
        </button>
      )}
      <div className="ms-auto">
        <ol className="breadcrumbs me-3">
          <li>
            <a>
              <i className={props.page.icon}></i>
            </a>
          </li>
          {props.page.header.menu.map((item: string, index: number) => (
            <li className="breadcrumb-item" key={index}>
              <a>{item}</a>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};
export default HeaderPage;
