// message.ts
const messages = {
    NAME_APP: "INSUMED PERU",
    NOT_CONECTION_CODE: "Problemas de conexión",
    NOT_CONECTION_MESSAGE: "No se pudo conectar con el servidor, vuelva a intentarlo nuevamente.",
    TOKEN_EXPIRED_CODE: "000",
    TOKEN_EXPIRED_MESSAGE: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
  };
  
  export default messages;
  