import { configureStore } from '@reduxjs/toolkit';
import { loadingSlice } from './states/loading.slice';
import { userSlice } from './states/user.slice';
import { dataTableSlice } from './states/dataTable.slice';

export interface AppStore {
  loading: any;
  user: any;
  dataTable: any;
}

export default configureStore<AppStore>({
  reducer: {
    loading: loadingSlice.reducer,
    user: userSlice.reducer,
    dataTable: dataTableSlice.reducer,
  },
});
