import { useState } from "react";
/**Select */
import Select from "react-select";
/**DatePicker */
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/l10n/es";
/**Moment */
import moment from "moment";

interface Props {
  formik: any;
  handleSelectChange: any;
  clientSearch: any;
  handleSelectChangeInHeaderSale: (value: string, fieldName: string) => void;
}

interface OptionSelect {
  label: string;
  value: string;
}

const HeaderQuote = (props: Props) => {
  //Flatpickr
  const minDate = moment().subtract(0, "days").format("YYYY-MM-DD");
  const [selectedDate, setSelectedDate] = useState<Date | string>(
    props.formik.values.fechaVencimiento,
  );
  //ReactSelect - Clientes
  const [optionsClients, setOptionsClients] = useState<OptionSelect[]>([]);
  const [isLoadingClients, setIsLoadingClients] = useState(false);

  const handleDateChange = (selectedDates: Date[]) => {
    setSelectedDate(selectedDates[0]);
    const fecha = moment(selectedDates[0]).format("YYYY-MM-DD");
    props.handleSelectChangeInHeaderSale("fechaVencimiento", fecha);
  };

  const handleClientSearchChange = (value: string) => {
    let type = "";
    if (props.formik.values.type_document_id === "2") {
      type = "JURIDICA";
    } else {
      type = "";
    }
    if (value.length >= 3) {
      props
        .clientSearch(value, type)
        .then((response: any) => {
          setIsLoadingClients(true);
          setOptionsClients(response.data);
        })
        .finally(() => {
          setIsLoadingClients(false);
        });
    }
  };

  const handleClientSelectChange = (selectedOption: OptionSelect) => {
    props.formik.setFieldValue("selectedClient", selectedOption);
    props.handleSelectChangeInHeaderSale("client_id", selectedOption.value);
    setOptionsClients([]);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <h4 className="text-center">Generar nueva cotización</h4>
      </div>
      {/* Cliente*/}
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="client_id" className="form-label">
            Cliente
          </label>
          <Select
            isLoading={isLoadingClients}
            placeholder={"Buscar cliente..."}
            options={optionsClients}
            onInputChange={handleClientSearchChange}
            noOptionsMessage={() => "No se encontraron resultados"}
            value={props.formik.values.selectedClient}
            onChange={(selectedOption) =>
              handleClientSelectChange(selectedOption)
            }
          />
        </div>
      </div>
      {/* Fecha vencimiento*/}
      <div className="col-md-2">
        <div className="form-group">
          <label htmlFor="date_issue" className="form-label">
            Fecha vencimiento
          </label>
          <Flatpickr
            value={selectedDate}
            onChange={handleDateChange}
            className="form-control form-control-sm"
            options={{
              dateFormat: "Y-m-d",
              locale: "es",
              minDate: minDate,
            }}
          />
        </div>
      </div>
      {/* Moneda*/}
      <div className="form-group col-md-2">
        <label htmlFor="currency_id" className="form-label">
          Moneda
        </label>
        <select
          className="form-control form-control-sm form-select"
          name="currency_id"
          id="currency_id"
          onChange={props.handleSelectChange}
          value={props.formik.values.currency_id}
        >
          <option value="PEN">SOLES</option>
          <option value="USD">DOLARES</option>
        </select>
      </div>
    </div>
  );
};

export default HeaderQuote;
