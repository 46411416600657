/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch } from "react-redux";
/**Models */
/**Services */
import * as accessService from "../services/access.service";
import * as clientsService from "../services/clients.service";
/**Redux */
import { setLoading } from "../redux/states/loading.slice";
import {
  dataTable_addResource,
  dataTable_clearAll,
  dataTable_setAlls,
  dataTable_updateResource,
} from "../redux/states/dataTable.slice";
import { useEffect, useState } from "react";
import { DataTable } from "../models";
import { UserRequest } from "../models/requests";

export function useAccess() {
  const dispatch = useDispatch();
  const [access, setAccess] = useState<DataTable | null>(null);

  const getFilters = async (loading: boolean) => {
    dispatch(setLoading(loading));
    try {
      const response = await accessService.getFilters();
      return response;
    } finally {
      dispatch(setLoading(false));
    }
  };

  //USERS
  const getUsers = async (
    page: number,
    text: string,
    limit: string,
    role_id: string,
    loading: boolean,
  ) => {
    dispatch(setLoading(loading));
    try {
      const response = await accessService.getUsers(page, text, limit, role_id);
      dispatch(dataTable_setAlls(response));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    const resetData = () => {
      dispatch(dataTable_clearAll());
      setAccess(null); // Borra el estado local si es necesario
    };

    return () => {
      resetData(); // Esto se ejecutará cuando el componente se desmonte
    };
  }, [dispatch]);

  //STORE USER
  const storeUser = async (user: UserRequest) => {
    dispatch(setLoading(true));
    try {
      const response = await accessService.storeUser(user);
      dispatch(dataTable_addResource(response.user));
      return response;
    } finally {
      dispatch(setLoading(false));
    }
  };

  //STATE USER
  const stateUser = async (user_id: string) => {
    dispatch(setLoading(true));
    try {
      const response = await accessService.stateUser(user_id);
      dispatch(dataTable_updateResource(response.user));
      return response;
    } finally {
      dispatch(setLoading(false));
    }
  };

  //RESET PASSWORD
  const resetPassword = async (user_id: string) => {
    dispatch(setLoading(true));
    try {
      const response = await accessService.resetPassword(user_id);
      dispatch(dataTable_updateResource(response.user));
      return response;
    } finally {
      dispatch(setLoading(false));
    }
  };

  //CHANGE PASSWORD
  const changePassword = async (formData: any) => {
    dispatch(setLoading(true));
    try {
      const response = await accessService.changePassword(formData);
      return response;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const personSearch = async (document: string) => {
    dispatch(setLoading(true));
    try {
      const response = await clientsService.personSearch(document);
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  }

  return {
    getUsers,
    getFilters,
    access,
    storeUser,
    stateUser,
    resetPassword,
    changePassword,
    personSearch
  };
}
