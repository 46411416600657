import { useEffect, useState } from "react";
/**DatePicker */
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/l10n/es";
/**Moment */
import moment from "moment";
import { QuoteRequest } from "../../../models/requests";

interface Props {
  formData: QuoteRequest;
  fechaVencimiento: any;
  handleSelectChangeInHeaderSale: (value: string, fieldName: string) => void;
  handleSelectChange: any;
}

const HeaderQuote = (props: Props) => {
  //Flatpickr
  const [selectedDate, setSelectedDate] = useState<Date | string>(
    props.fechaVencimiento,
  );

  const handleDateChange = (selectedDates: Date[]) => {
    setSelectedDate(selectedDates[0]);
    const fecha = moment(selectedDates[0]).format("YYYY-MM-DD");
    props.handleSelectChangeInHeaderSale("fechaVencimiento", fecha);
  };

  useEffect(() => {
    setSelectedDate(props.fechaVencimiento);
  }, [props.fechaVencimiento]);

  return (
    <div className="row">
      <div className="col-md-12">
        <h4 className="text-center">
          Editar cotización - {props.formData.serie}-{props.formData.numeracion}
        </h4>
      </div>
      {/* Cliente*/}
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="client_id" className="form-label">
            Cliente
          </label>
          <input
            disabled
            readOnly
            value={props.formData.client_name}
            type="text"
            className="form-control form-control-sm"
          />
        </div>
      </div>
      {/* Fecha vencimiento*/}
      <div className="col-md-2">
        <div className="form-group">
          <label htmlFor="date_issue" className="form-label">
            Fecha vencimiento
          </label>
          <Flatpickr
            value={selectedDate}
            onChange={handleDateChange}
            className="form-control form-control-sm"
            options={{
              dateFormat: "Y-m-d",
              locale: "es",
            }}
          />
        </div>
      </div>
      {/* Moneda*/}
      <div className="form-group col-md-2">
        <label htmlFor="currency_id" className="form-label">
          Moneda
        </label>
        <select
          className="form-control form-control-sm form-select"
          name="currency_id"
          id="currency_id"
          onChange={props.handleSelectChange}
          value={props.formData.currency_id}
        >
          <option value="" disabled>Seleccionar</option>
          <option value="PEN">SOLES</option>
          <option value="USD">DOLARES</option>
        </select>
      </div>
    </div>
  );
};

export default HeaderQuote;
